import { getBackendHost } from "../../utils/apiconnection";
import APIFetch from "../../utils/APIFetch";

export interface TeacherType {
  id: string;
  username: string;
  fullname: string;
  phone?: string;
  admin: boolean;
  school_id: string;
  verification_code: string | null;
}

export interface BusinessTeacherType {
  id: string;
  fullname: string;
  username: string;
  phone?: string;
  admin: boolean;
  verification_code: string | null;
  school: {
    id: string;
    name: string;
    user_license: {
      license: {
        name: string;
      };
    }[];
  };
  back_office_logins: {
    id: string;
    timestamp: string;
  }[];
  classes: {
    id: string;
    name: string;
    students: {
      id: string;
      _count: {
        scenario_progress: number;
      };
    }[];
  }[];
}

export interface LoginsType {
  id: string;
  timestamp: string;
  teacher: {
    id: string;
    fullname: string;
    username: string;
  };
}

export type TeacherTutorialType =
  | "1_1"
  | "1_2"
  | "2_1"
  | "2_2"
  | "4"
  | "update"
  | "update_2"
  | null;

export const GetMyTeacherInfoAPI = async (): Promise<TeacherType> => {
  var data = APIFetch(getBackendHost() + "/teacher/me/", {
    method: "GET",
  });
  return data;
};

export const GetAuthorsAPI = async (): Promise<TeacherType[]> => {
  var data = APIFetch(getBackendHost() + "/teacher/authors", {
    method: "GET",
  });
  return data;
};

export const GetAllTeachersAPI = async (): Promise<BusinessTeacherType[]> => {
  var data = APIFetch(getBackendHost() + "/teacher/all", {
    method: "GET",
  });
  return data;
};

export const GetAllLoginsAPI = async (): Promise<LoginsType[]> => {
  var data = APIFetch(getBackendHost() + "/teacher/logins", {
    method: "GET",
  });
  return data;
};

export const GetTeacherTutorialAPI = async (): Promise<TeacherTutorialType> => {
  var data = await APIFetch(getBackendHost() + "/teacher/tutorial", {
    method: "GET",
  });
  return data.tutorial_part;
};

export const FinishTutorialPartAPI = async (part: string): Promise<void> => {
  await APIFetch(getBackendHost() + "/teacher/tutorial", {
    method: "POST",
    body: JSON.stringify({ tutorial_part: part }),
  });
};
