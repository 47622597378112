import { useAppSelector } from "../../../hooks/hooks";
import UserMenu from "../UserMenu/UserMenu";
import Marketplace from "./Marketplace";
import MarketplaceSelectorMenu from "./MarketplaceSelectorMenu";
import MyScenarios from "./MyScenarios";
import ScenarioMenu from "./ScenarioMenu";
import ScenarioEditMenu from "./ScenarioEditMenu";

const MarketPlaceMenus = ({ ...props }) => {
  const professorState = useAppSelector((state) => state.teacher);
  const submenu = professorState.submenu;
  const scenarioMenuParent = professorState.scenarioMenuParent;

  return (
    <div className="marketplace_menus">
      {submenu === "marketplace" && <Marketplace />}
      {submenu === "marketplace_selector" && <MarketplaceSelectorMenu />}
      {submenu === "scenario" && (
        // <OldScenarioMenu origin={scenarioMenuParent} />
        <ScenarioMenu />
      )}
      {/* {submenu === "scenario_edit" && <OldScenarioEditMenu />} */}
      {submenu === "scenario_edit" && <ScenarioEditMenu />}
      {submenu === "my_scenarios" && <MyScenarios />}
      <UserMenu color={"black"} />
    </div>
  );
};

export default MarketPlaceMenus;
