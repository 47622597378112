import { useTranslation } from "react-i18next";
import "./TeacherTutorialScenarioUpdate.css";
import { Button } from "@chakra-ui/react";
import { LazyMotion, m } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks.ts";
import { setTeacherTutorialStep } from "../../../reducers/teacherSlice.ts";
import { FinishTutorialPartAPI } from "../../../_newapios/user/teacher.ts";
import { renderProgressBar } from "./TeacherTutorial1.tsx";

const TeacherTutorialScenarioUpdate = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);
  const teacherState = useAppSelector((state) => state.teacher);
  const tutorialStep = teacherState.teacherTutorialStep;
  const tutorialPart = teacherState.teacherTutorial;
  const licenseName = teacherState.userLicense?.license.name;
  const isAdmin = teacherState.isAdmin;

  const handleNext = async () => {
    if (tutorialStep >= 2) {
      dispatch(setTeacherTutorialStep(0));
      await FinishTutorialPartAPI("update");
    }
    dispatch(setTeacherTutorialStep(tutorialStep + 1));
  };

  const renderTutorialWelcome = () => {
    return (
      <div className="teacher_tutorial_1_welcome_background">
        <div className="center_container">
          <LazyMotion features={loadFeatures}>
            <div className="teacher_tutorial_1_welcome_container">
              <div className="text_black_medium_bold">
                {t("tutorial_update_scenario_1")}
              </div>
              <Button
                className="teacher_tutorial_1_welcome_button"
                as={m.button}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "0px 0px 8px 0px #6ee6a7",
                }}
                _hover={{ backgroundColor: "#6ee6a7" }}
                backgroundColor={"#6ee6a7"}
                borderRadius={25}
                size={"lg"}
                onClick={() => handleNext()}
              >
                <div className="text_white_small">{t("lets_see")}</div>
              </Button>
            </div>
          </LazyMotion>
        </div>
      </div>
    );
  };

  const renderButton = () => {
    const text = tutorialStep === 2 ? t("understood") : t("next");
    return (
      <LazyMotion features={loadFeatures}>
        <div className="teacher_tutorial_1_container_bottom">
          <div className="text_black_very_small">{tutorialStep + "/2"}</div>
          <Button
            className="teacher_tutorial_1_welcome_button"
            as={m.button}
            whileHover={{
              scale: 1.01,
              boxShadow: "0px 0px 8px 0px #5090F0",
            }}
            _hover={{ backgroundColor: "#5090F0" }}
            backgroundColor={"#5090F0"}
            borderRadius={25}
            size={"lg"}
            onClick={() => handleNext()}
          >
            <div className="text_white_small">{text}</div>
          </Button>
        </div>
      </LazyMotion>
    );
  };

  const renderTutorialText = () => {
    switch (tutorialStep) {
      case 1:
        return (
          <div className="text_black_small">
            <b>{t("tutorial_2_2_step_2")}</b>
          </div>
        );
      case 2:
        return (
          <div className="text_black_small">
            <b>{t("tutorial_2_2_step_3")}</b>
          </div>
        );
      default:
        return null;
    }
  };

  const renderTutorialUpdate = () => {
    return (
      <div className="teacher_tutorial_1_background">
        {(tutorialStep === 1 || tutorialStep === 2) && (
          <>
            <div
              className={
                tutorialStep === 1
                  ? "teacher_tutorial_2_2_background_black_bot"
                  : "teacher_tutorial_2_3_background_black_bot"
              }
            />
            <div
              className={
                tutorialStep === 1
                  ? "teacher_tutorial_2_2_background_black_top"
                  : "teacher_tutorial_2_3_background_black_top"
              }
            />
            <div
              className={
                tutorialStep === 1
                  ? "teacher_tutorial_2_2_background_black_variable_left"
                  : "teacher_tutorial_2_3_background_black_variable_left"
              }
              style={tutorialStep === 1 ? { width: "40%" } : { width: "91.5%" }}
            />
            <div
              className={
                tutorialStep === 1
                  ? "teacher_tutorial_2_2_background_black_variable_right"
                  : "teacher_tutorial_2_3_background_black_variable_right"
              }
              style={tutorialStep === 1 ? { width: "25%" } : { width: "5%" }}
            />
          </>
        )}
        <div className="center_container">
          <div
            className={
              tutorialStep === 2
                ? "teacher_tutorial_2_3_container"
                : "teacher_tutorial_2_2_container"
            }
          >
            <div className="teacher_tutorial_1_container_text">
              {renderTutorialText()}
              {renderButton()}
            </div>
            {renderProgressBar(tutorialStep, 2)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {tutorialStep === 0 &&
        teacherState.submenu === "scenario" &&
        teacherState.selectedBundle === null &&
        renderTutorialWelcome()}
      {(tutorialStep === 1 || tutorialStep === 2) &&
        teacherState.submenu === "scenario" &&
        teacherState.selectedBundle === null &&
        renderTutorialUpdate()}
    </>
  );
};

export default TeacherTutorialScenarioUpdate;
