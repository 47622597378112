import "./FillInTheGaps.css";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import {
  Arrows,
  Background,
  ConfirmButton,
  ExerciseName,
  HelpMenu,
  MainTextBox,
  Professor,
  TextBox,
} from "./_components";
import { Idirection, IscreenSize } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { Input } from "@chakra-ui/react";
import { PlayFunction } from "use-sound/dist/types";
import { useAppSelector } from "../../../hooks/hooks";

const FillInTheGaps = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  research: boolean;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const isTeacher = useAppSelector((state) => state.login).type === "teacher";

  const text = props.exercise.text;
  const helpText = props.exercise.help_text || "";
  const mainText = props.exercise.main_text as string;
  const options = props.exercise.options as string[];

  const [answers, setAnswers] = useState<string[]>([]);
  const [maxError] = useState(props.exercise.max_error as number[]);
  const [selectedExercise, setSelectedExercise] = useState(props.exerciseIndex);
  const [maxExercise, setMaxExercise] = useState(props.maxExercise);

  const [research, setResearch] = useState(props.research);
  const [incorrectFeedback, setIncorrectFeedback] = useState(false);
  const [incorrectIndexes, setIncorrectIndexes] = useState<number[]>([]);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    orderOptions();
  }, [selectedExercise, maxExercise]);

  useEffect(() => {
    setSelectedExercise(props.exerciseIndex);
    setMaxExercise(props.maxExercise);
  }, [props.exerciseIndex]);

  const orderOptions = () => {
    if (research) {
      if (selectedExercise < maxExercise) {
        setAnswers(options);
      } else {
        setAnswers(_.fill(Array(options.length), ""));
      }
    } else {
      if (isTeacher) {
        setAnswers(options);
      } else {
        setAnswers(_.fill(Array(options.length), ""));
      }
    }
  };

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const confirmHandler = () => {
    var correct = true;
    // var myAnswers: any = document.getElementsByClassName(
    //   "fill_in_the_gaps_write_box_input"
    // );
    let wrongIndexes = [];

    for (var i = 0; i < options.length; i++) {
      var ans: number | string = parseFloat(answers[i]);
      var sol: number | string = parseFloat(options[i]);

      if (!isNaN(sol) && !isNaN(ans)) {
        //numeric answer AND solution
        if (Math.abs(ans - sol) > maxError[i] / 100) {
          correct = false;
        }
      } else {
        //something isn't numeric
        ans = answers[i]
          .toString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s/g, "") // remove spaces
          .toLowerCase();
        sol = options[i]
          .toString()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/\s/g, "") // remove spaces
          .toLowerCase();

        if (ans != sol) {
          correct = false;
          wrongIndexes.push(i);
        }
      }
    }
    if (research) {
      if (correct) {
        props.updateLevel("right", answers, true);
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        props.wrongAnswerSound();
        setIncorrectFeedback(true);
        setIncorrectIndexes(wrongIndexes);
      }
    } else {
      if (correct) {
        props.updateLevel("right", answers, true);
      } else {
        props.updateLevel("right", answers, false);
      }
    }
  };

  const renderWriteBoxes = () => {
    return (
      <div
        className="fill_in_the_gaps_write_boxes"
        style={
          answers.length < 5
            ? {
                gridTemplateColumns:
                  "repeat(" +
                  answers.length +
                  ", " +
                  100 / answers.length +
                  "%)",
                height: "7%",
              }
            : {
                gridTemplateColumns: "repeat(4, 25%)",
                gridTemplateRows: "repeat(2, 50%)",
                height: "15%",
              }
        }
      >
        {answers.map((answer, answerIndex) => {
          return (
            <motion.div
              key={answerIndex}
              className={
                incorrectFeedback === true
                  ? incorrectIndexes.includes(answerIndex)
                    ? "fill_in_the_gaps_write_box_wrong"
                    : "fill_in_the_gaps_write_box"
                  : "fill_in_the_gaps_write_box"
              }
              style={answerIndex > 3 ? { marginTop: "5%" } : undefined}
              whileHover={{
                scale: 1.02,
                boxShadow: "0px 0px 10px 1px #5090F0",
                borderRadius: "5px",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <div className="center_container">
                <div
                  className={
                    isTeacher ? "text_black_small" : "text_black_medium"
                  }
                >
                  {answerIndex + 1} =
                </div>
              </div>
              <Input
                variant="unstyled"
                style={{
                  fontFamily: "Causten",
                }}
                value={answer}
                onChange={(e) => {
                  var newAnswers = [...answers];
                  newAnswers[answerIndex] = e.target.value;
                  setAnswers(newAnswers);
                }}
              />
            </motion.div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="fill_in_the_gaps">
      <Background
        incorrectFeedback={incorrectFeedback}
        setIncorrectFeedback={setIncorrectFeedback}
      />
      <Professor width={screenSize.dynamicWidth} />
      <TextBox text={text} contractTextBox={true} />
      <Arrows
        selectedExercise={selectedExercise}
        maxExercise={maxExercise}
        updateLevel={props.updateLevel}
        research={isTeacher ? true : research}
      />
      <ConfirmButton confirmHandler={confirmHandler} />
      <MainTextBox mainText={mainText} />
      <HelpMenu helpText={helpText} tipHandler={props.tipHandler} />
      <ExerciseName
        type={props.exercise.type}
        smallMargin={true}
        imageExists={true}
      />
      {renderWriteBoxes()}
    </div>
  );
};

export default FillInTheGaps;
