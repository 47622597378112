import "./WriteAnswersEdit.css";
import help from "../../../../assets/exercises/help.svg";
import plus from "../../../../assets/exercises/plus_grey.svg";
import bin from "../../../../assets/professor/bin.svg";
import ImageSelector from "./ImageSelector/ImageSelector";
import { useState, useEffect } from "react";
import {
  NumberInput,
  Textarea,
  Input,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import axios from "axios";
import { useAppSelector } from "../../../../hooks/hooks";
import { ExerciseType } from "../../../../_newapios/content/unit";
import _ from "lodash";

const pako = require("pako");

const WriteAnswersEdit = (props: {
  type: string;
  x: number;
  exercise: ExerciseType;
  selectedExercise: number;
  updateWriteAnswers: (newWriteAnswers: ExerciseType) => void;
}) => {
  const { t, i18n } = useTranslation();
  const animations = useAppSelector((state) => state.main).animations;

  const [helpOpen, setHelpOpen] = useState(false);
  const [text, setText] = useState(props.exercise.text as string);
  const [helpText, setHelpText] = useState(props.exercise.help_text as string);
  const image = props.exercise.image?.url;
  const animationUrl = props.exercise.animation?.url;
  const [labels, setLabels] = useState(props.exercise.labels as string[]);
  const [options, setOptions] = useState(props.exercise.options as string[]);
  const [maxError, setMaxError] = useState(
    props.exercise.max_error as number[]
  );
  const [randomLims, setRandomLims] = useState(
    props.exercise.random_lims as number[]
  );
  const [saveToDB, setSaveToDB] = useState(false);
  const [animation, setAnimation] = useState<string>("");
  useEffect(() => {
    if (saveToDB) {
      var exercise = { ...props.exercise };
      exercise.text = text;
      exercise.help_text = helpText;
      exercise.labels = labels;
      exercise.options = options;
      exercise.max_error = maxError;
      exercise.random_lims = randomLims;
      props.updateWriteAnswers(exercise);
      setSaveToDB(false);
    }
  }, [saveToDB]);

  useEffect(() => {
    setText(props.exercise.text as string);
    setHelpText(props.exercise.help_text as string);
    setLabels(props.exercise.labels as string[]);
    setMaxError(props.exercise.max_error as number[]);
    setRandomLims(props.exercise.random_lims as number[]);
    setOptions(props.exercise.options as string[]);
  }, [props.exercise.text, props.selectedExercise]);

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
  };

  const textHandler = (newText: string) => {
    setText(newText);
  };

  const helpTextHandler = (newHelpText: string) => {
    setHelpText(newHelpText);
  };

  const imageHandler = (newExercise: ExerciseType) => {
    props.updateWriteAnswers(newExercise);
  };

  const addTextBoxHandler = () => {
    var updatedLabels = [...labels];
    var updatedOptions = [...options];
    var updatedErrors = [...maxError];
    updatedLabels.push("");
    updatedOptions.push("");
    updatedErrors.push(1);
    setLabels(updatedLabels);
    setOptions(updatedOptions);
    setMaxError(updatedErrors);
  };

  const editSolutionTextHandler = (
    updatedOption: string,
    optionIndex: number
  ) => {
    var currentOptions = [...options];
    currentOptions[optionIndex] = updatedOption;
    setOptions(currentOptions);
  };

  const editLabelTextHandler = (updatedLabel: string, labelIndex: number) => {
    var currentLabels = [...labels];
    currentLabels[labelIndex] = updatedLabel;
    setLabels(currentLabels);
  };

  const deleteTextBoxHandler = (index: number) => {
    var currentOptions = [...options];
    var currentLabels = [...labels];
    var currentErrors = [...maxError];
    currentOptions.splice(index, 1);
    currentLabels.splice(index, 1);
    currentErrors.splice(index, 1);
    setOptions(currentOptions);
    setLabels(currentLabels);
    setMaxError(currentErrors);
    setSaveToDB(true);
  };

  const randomLimsHandler = (newRandomLim: string, position: number) => {
    var currentRandomLims = [...randomLims];
    var newNumberRandomLim = parseInt(newRandomLim);

    if (isNaN(newNumberRandomLim)) {
      newNumberRandomLim = 0;
    }

    currentRandomLims[position] = newNumberRandomLim;

    setRandomLims(currentRandomLims);
    setSaveToDB(true);
  };

  const renderProfessor = () => {
    return (
      <div className="edit_write_answers_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            style={{ maxHeight: "35vh" }}
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <motion.div
        className="edit_write_answers_text_box"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        <div
          className={
            text.length < 200 ? "text_black_medium" : "text_black_small"
          }
        >
          <Textarea
            placeholder={
              props.type == "experiment"
                ? t("click_here_to_edit_random")
                : t("click_here_to_edit")
            }
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              textAlign: "justify",
              fontFamily: "Causten",
              fontSize: "1em",
              textJustify: "inter-word",
            }}
            value={text}
            resize={"none"}
            onChange={(e) => textHandler(e.target.value)}
            onBlur={() => setSaveToDB(true)}
          />
        </div>
      </motion.div>
    );
  };

  const renderHelpButton = () => {
    return (
      <div className="edit_write_answers_help_button">
        <div className="center_container">
          <motion.div
            className="write_help_text_button"
            onClick={() => helpButtonHandler()}
            whileHover={{
              scale: 1.02,
              boxShadow: "0px 0px 10px 1px #5090F0",
              borderRadius: "5 px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          >
            <div className="center_container" style={{ fontFamily: "Causten" }}>
              {t("write_your_clue")}
            </div>
          </motion.div>
        </div>
        <div
          className="center_container"
          onClick={() => helpButtonHandler()}
          style={{ cursor: "pointer" }}
        >
          <img
            className="icon"
            style={{ maxHeight: "7vh" }}
            src={help}
            alt="help"
          />
        </div>
      </div>
    );
  };

  const renderImage = () => {
    return (
      <div className="edit_write_answers_image">
        <div className="center_container">
          <ImageSelector
            imageHandler={(exercise: ExerciseType) => imageHandler(exercise)}
            animation={animationUrl}
            image={image}
            exerciseId={props.exercise.id}
          />
        </div>
      </div>
    );
  };

  const renderAddButton = (currentLabels: string[]) => {
    if (currentLabels.length < 3) {
      return (
        <motion.div
          className="edit_write_answers_add_option"
          onClick={() => addTextBoxHandler()}
          layout
          whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <div className="center_container">
            <img src={plus} style={{ maxHeight: "4vh" }} />
          </div>
        </motion.div>
      );
    }
  };

  const renderWriteBoxes = () => {
    //const x = Math.round((this.props.randomLims[0] + (this.props.randomLims[1]-this.props.randomLims[0])*this.props.x)*5)/5
    return (
      <motion.div
        layoutRoot
        className="edit_write_answers_write_boxes"
        style={{
          gridTemplateColumns:
            labels.length < 3
              ? "repeat(" + (labels.length + 1) + ", 1fr)"
              : "repeat(" + labels.length + ", 1fr)",
        }}
      >
        {labels.map((lb, labelIndex) => {
          var label = lb;
          if (lb == null) {
            label = "";
          }

          //if (this.props.solution[labelIndex]!="") {
          //let sol = ""
          //try {
          //  sol = eval(this.props.solution[labelIndex])
          //} catch {
          //  sol = this.props.solution[labelIndex]
          //}
          return (
            <div
              className="edit_write_answers_outer_container"
              key={labelIndex}
            >
              <motion.div
                key={labelIndex}
                className={"edit_write_answers_write_box"}
                style={{ gridTemplateColumns: "3fr 1.2fr" }}
                layout
              >
                <Input
                  placeholder="Solución"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    textAlign: "center",
                    fontFamily: "Causten",
                    fontSize: "1em",
                  }}
                  value={options[labelIndex]}
                  size={"lg"}
                  borderRight={"solid 2px #5090F0"}
                  onChange={(e) =>
                    editSolutionTextHandler(e.target.value, labelIndex)
                  }
                  onBlur={() => setSaveToDB(true)}
                  as={motion.input}
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "0px 0px 10px 1px #5090F0",
                  }}
                />
                <Input
                  placeholder="Unidades"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    textAlign: "center",
                    fontFamily: "Causten",
                    fontSize: "1em",
                  }}
                  value={label}
                  size={"lg"}
                  borderLeft={"solid 2px #5090F0"}
                  onChange={(e) =>
                    editLabelTextHandler(e.target.value, labelIndex)
                  }
                  onBlur={() => setSaveToDB(true)}
                  as={motion.input}
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "0px 0px 10px 1px #5090F0",
                  }}
                />
              </motion.div>
              <div className="top_center_container">
                <motion.div
                  className="edit_write_answers_delete"
                  onClick={() => deleteTextBoxHandler(labelIndex)}
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "0px 0px 10px 1px #F0684F",
                  }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                >
                  <div className="center_container">
                    <img src={bin} style={{ height: "4vh" }} />
                  </div>
                </motion.div>
              </div>
            </div>
          );
          //}
        })}
        {renderAddButton(labels)}
      </motion.div>
    );
  };

  const renderRandomLims = () => {
    if (randomLims === null) {
      setRandomLims([1, 10]);
    }
    return (
      <div className="edit_write_answers_random_container">
        <div className="right_container">
          <div className="text_white_small_bold" style={{ marginRight: "2%" }}>
            $x es un número aleatorio entre
          </div>
        </div>
        <div className="center_container">
          <NumberInput
            style={{
              background: "none",
              color: "white",
              fontFamily: "Causten",
              fontSize: "1.2em",
              border: "solid 2px #DFAA00",
              boxShadow: "none",
            }}
            size={"sm"}
            value={randomLims[0] != null ? randomLims[0] : 0}
            onChange={(stringValue, value) => {
              randomLimsHandler(stringValue, 0);
            }}
            onBlur={() => setSaveToDB(true)}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </div>
        <div className="center_container">
          <div className="text_white_small_bold">y</div>
        </div>
        <div className="center_container">
          <NumberInput
            style={{
              background: "none",
              color: "white",
              fontFamily: "Causten",
              fontSize: "1.2em",
              border: "solid 2px #DFAA00",
              boxShadow: "none",
            }}
            size={"sm"}
            value={randomLims[1] != null ? randomLims[1] : 0}
            onChange={(stringValue, value) => {
              randomLimsHandler(stringValue, 1);
            }}
            onBlur={() => setSaveToDB(true)}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </div>
      </div>
    );
  };

  const renderHelpMenu = () => {
    return (
      <motion.div
        className="edit_write_answers_help_menu"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        <div
          className={
            helpText.length < 265 ? "text_black_medium" : "text_black_small"
          }
        >
          <Textarea
            placeholder={t("click_here_to_edit")}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              textAlign: "center",
              fontFamily: "Causten",
              fontSize: "1em",
            }}
            value={helpText}
            resize={"none"}
            onChange={(e) => helpTextHandler(e.target.value)}
            onBlur={() => setSaveToDB(true)}
          />
        </div>
      </motion.div>
    );
  };
  return (
    <div className="edit_write_answers">
      {renderProfessor()}
      {props.x != null && renderTextBox()}
      {renderHelpButton()}
      {helpOpen && renderHelpMenu()}
      {renderImage()}
      {renderWriteBoxes()}
      {props.type == "experiment" && renderRandomLims()}
    </div>
  );
};

export default WriteAnswersEdit;
