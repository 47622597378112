import { getBackendHost } from "../../utils/apiconnection";
import APIFetch from "../../utils/APIFetch";
import { StudentType } from "./student";

export interface GroupType {
    id: string;
    name: string;
    students: StudentType[];
    class_id: string;
}

export const GetGroupAPI = async (id: string): Promise<GroupType | null> => {
    var req = await fetch(getBackendHost() + "/group/" + id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    if (req.status === 409) {
        return null;
    }
    var data = await req.json();

    data = data || null
    return data;
}

export const CreateGroupAPI = async (name: string, class_id: string, students: string[]): Promise<GroupType | null> => {
    // var req = await fetch(getBackendHost() + "/group/", {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "POST",
    //     "body": JSON.stringify({
    //         name,
    //         class_id,
    //         students,
    //     }),
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();
    var data = APIFetch(getBackendHost() + "/group/", {
        method: "POST",
        body: JSON.stringify({
            name,
            class_id,
            students,
        }),
    });

    data = data || null
    return data;
}

export const UpdateGroupAPI = async (id: string, class_id: string, name: string, students: string[]): Promise<GroupType | null> => {
    // var req = await fetch(getBackendHost() + "/group/" + id, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "POST",
    //     "body": JSON.stringify({
    //         name,
    //         class_id,
    //         students,
    //     }),
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();
    var data = APIFetch(getBackendHost() + "/group/" + id, {
        method: "POST",
        body: JSON.stringify({
            name,
            class_id,
            students,
        }),
    });

    data = data || null
    return data;
}

export const DeleteGroupAPI = async (id: string): Promise<boolean> => {
    var req = await fetch(getBackendHost() + "/group/" + id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "DELETE",
        "mode": "cors",
        "credentials": "include"
    });
    return req.status === 200;
}

export const GetAllGroupsAPI = async (class_id: string): Promise<GroupType[] | null> => {
    var req = await fetch(getBackendHost() + "/group/all/" + class_id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        "method": "GET",
        "mode": "cors",
        "credentials": "include"
    });
    if (req.status === 409) {
        return null;
    }
    var data = await req.json();

    data = data || null
    return data;
}