import { getBackendHost } from "../../utils/apiconnection";
import APIFetch from "../../utils/APIFetch";

export interface StudentCoursePoints {
  [key: string]: number;
}
export interface StudentScenarioPoints {
  [key: string]: StudentCoursePoints;
}

export const GetStudentCoursePointsAPI = async (
  id: string
): Promise<StudentCoursePoints> => {
  // var req = await fetch(
  //   getBackendHost() + "/student/" + id + "/course_points/",
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     method: "GET",
  //     mode: "cors",
  //     credentials: "include",
  //   }
  // );
  // var data = await req.json();

  var data = APIFetch(getBackendHost() + "/student/" + id + "/course_points/", {
    method: "GET",
  });

  data = data || null;
  return data;
};

export const GetStudentScenarioPointsAPI = async (
  id: string,
  scenario_id: string
): Promise<number | null> => {
  // var req = await fetch(
  //   getBackendHost() + "/student/" + id + "/scenario_points/" + scenario_id,
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     method: "GET",
  //     mode: "cors",
  //     credentials: "include",
  //   }
  // );
  // var data = await req.json();

  var data = APIFetch(
    getBackendHost() + "/student/" + id + "/scenario_points/" + scenario_id,
    {
      method: "GET",
    }
  );
  var data_str = await data;

  data = JSON.parse(data_str) === 0 ? 0 : JSON.parse(data_str) || null;
  return data;
};
