import { getBackendHost } from "../../utils/apiconnection";
import APIFetch from "../../utils/APIFetch";
import { GlobalCompetenceType } from "./unit";

export const GetAllGlobalCompetencesAPI = async (): Promise<GlobalCompetenceType[]> => {
    // var req = await fetch(getBackendHost() + "/global_competence/all", {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();

    var data = APIFetch(getBackendHost() + "/global_competence/all", {
        method: "GET"
    });

    data = data || []
    return data;
}