import "./ScenarioEditMenu.css";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks.ts";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useMemo } from "react";
import { LazyMotion, m } from "framer-motion";
import BackCross from "../../../assets/professor/close.tsx";
import bin from "../../../assets/professor/bin.svg";
import { toast } from "../../../index.tsx";
import {
  Button,
  Input,
  Textarea,
  Wrap,
  WrapItem,
  Tooltip,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { motion } from "framer-motion";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
//import ScenarioEditMenu from "./ScenarioEditMenu.tsx";
import {
  EvaluationCriterionType,
  SpecificCompetenceType,
} from "../../../_newapios/content/unit.ts";
import {
  UpdateScenarioAPI,
  DeleteScenarioAPI,
  ModifiedScenarioType,
  GetScenarioAPI,
  PermanentlyDeleteScenarioAPI,
} from "../../../_newapios/content/scenario.ts";
import {
  GetCourseEvaluationCriteriaAPI,
  GetCourseSpecificCompetencesAPI,
} from "../../../_newapios/content/course.ts";
import {
  prettifyCourseAcronyms,
  specificCompetencesBooleanArray,
  updateGlobalCompetences,
} from "../../../utils/competences.ts";
import { GetResearchAPI } from "../../../_newapios/content/research.ts";
import { GetExperimentAPI } from "../../../_newapios/content/experiment.ts";
import close from "../../../assets/student/close_user_menu.svg";
import _ from "lodash";
import ScenarioImageSelector from "./ScenarioImageSelector/ScenarioImageSelector.tsx";
import { simpleGlobalCompetences } from "../../../constants.ts";
import {
  setDeleteOnExit,
  submenu,
  updateSelectedScenario,
} from "../../../reducers/teacherSlice.ts";
import ExerciseEditor from "./Exercises/ExercisesEdit/ExerciseEditor.tsx";
import { setExperiment, setResearch } from "../../../reducers/mainSlice.ts";
import TeacherTutorialScenarioUpdate2 from "../TeacherTutorials/TeacherTutorialScenarioUpdate2.tsx";

const ScenarioEditMenu = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const saveScenario = async (
    new_scenario: ModifiedScenarioType | null = null
  ) => {
    if (new_scenario === null) {
      new_scenario = scenario;
    }

    if (new_scenario === null) {
      return;
    }

    const updatedScenario = await UpdateScenarioAPI(
      new_scenario.id,
      new_scenario
    );
    dispatch(updateSelectedScenario(updatedScenario));
  };
  const { t } = useTranslation(["common", "evaluation"]);

  const allStudents = [{ value: 0, label: t("all_students") }];
  registerLocale("es", es);

  const weightingOptions = [
    { value: 0, label: "No evaluada - 0 puntos por situación" },
    { value: 50, label: "Baja - 50 puntos por situación" },
    { value: 100, label: "Estándar - 100 puntos por situación" },
    { value: 150, label: "Alta - 150 puntos por situación" },
    { value: 200, label: "Muy Alta - 200 puntos por situación" },
  ];

  const languages = [
    { value: "ES", label: t("spanish") },
    { value: "EN", label: t("english") },
    { value: "VAL", label: t("valencian") },
    { value: "CAT", label: t("catalonian") },
    { value: "GAL", label: t("galician") },
    { value: "EUS", label: t("basque") },
    { value: "DE", label: t("deutsch") },
    { value: "FR", label: t("french") },
  ];

  const visibilityOptions = [
    { value: 0, label: t("visibility_text_0") },
    { value: 1, label: t("visibility_text_1") },
    { value: 2, label: t("visibility_text_2") },
  ];
  const mainState = useAppSelector((state) => state.main);
  const teacherState = useAppSelector((state) => state.teacher);

  const courses = mainState.courses;
  const regulations = mainState.regulations;
  const globalCompetences = mainState.globalCompetences;
  const userLicense = teacherState.userLicense;

  const scenario = teacherState.selectedScenario;
  const [selectedRegulationId, setSelectedRegulationId] = useState(
    scenario?.course.regulation_id
  );
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  const [evaluationCriteria, setEvaluationCriteria] = useState<
    EvaluationCriterionType[]
  >([]);

  const [specificCompetences, setSpecificCompetences] = useState<
    SpecificCompetenceType[]
  >([]);

  const [selectedGlobalCompetences, setSelectedGlobalCompetences] = useState<
    boolean[]
  >(_.fill(Array(simpleGlobalCompetences.length), false));

  const [deleteMenu, setDeleteMenu] = useState<Boolean>(false);
  const [backMenu, setBackMenu] = useState<Boolean>(false);
  const [visibility, setVisibility] = useState<Number>(
    scenario?.visibility ?? 0
  );
  const [showHoverText, setShowHoverText] = useState<string>("");
  const [showHoverIcon, setShowHoverIcon] = useState<boolean>(false);
  const deleteOnExit = teacherState.deleteOnExit;

  const [alert, setAlert] = useState<string | null>(null);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const loadScenario = async () => {
    if (scenario === null) {
      return;
    }
    const newScenario = await GetScenarioAPI(scenario.id);
    dispatch(updateSelectedScenario(newScenario));
  };

  useEffect(() => {
    loadEvaluationCriteria();
    loadSpecificCompetences();
  }, [scenario?.course]);

  useEffect(() => {
    setSelectedGlobalCompetences(
      updateGlobalCompetences(
        scenario?.evaluation_criteria_ids ?? [],
        specificCompetences,
        evaluationCriteria,
        globalCompetences
      )
    );
  }, [
    evaluationCriteria,
    specificCompetences,
    scenario?.evaluation_criteria_ids,
  ]);

  useEffect(() => {
    reloadExercises();
  }, [
    teacherState.selectedScenario?.research.id,
    teacherState.selectedScenario?.experiment.id,
  ]);

  const reloadExercises = async () => {
    if (teacherState.selectedScenario === null) {
      return;
    }
    const newResearch = await GetResearchAPI(
      teacherState.selectedScenario.research.id
    );
    const newExperiment = await GetExperimentAPI(
      teacherState.selectedScenario.experiment.id
    );
    dispatch(setResearch(newResearch));
    dispatch(setExperiment(newExperiment));
  };

  const loadEvaluationCriteria = async () => {
    if (scenario === null) {
      return;
    }
    setEvaluationCriteria(
      await GetCourseEvaluationCriteriaAPI(scenario.course.id)
    );
  };
  const loadSpecificCompetences = async () => {
    if (scenario === null) {
      return;
    }
    setSpecificCompetences(
      await GetCourseSpecificCompetencesAPI(scenario.course.id)
    );
  };

  if (scenario === null) {
    return;
  }

  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const scenarioMainKnowHowHandler = (value: string) => {
    const newscenario = { ...scenario };
    newscenario.main_know_how = value;
    dispatch(updateSelectedScenario(newscenario));
  };

  const setScenarioName = (name: string) => {
    const newScenario = { ...scenario };
    newScenario.name = name;
    dispatch(updateSelectedScenario(newScenario));
  };

  const setScenarioDescription = (description: string) => {
    const newScenario = { ...scenario };
    newScenario.description = description;
    dispatch(updateSelectedScenario(newScenario));
  };

  const setScenarioImage = (image: string) => {
    const newScenario = { ...scenario };
    newScenario.image.url = image;
    dispatch(updateSelectedScenario(newScenario));
  };

  const selectScenarioEvalCriteria = (evalCriterionId: string) => {
    const newScenario = { ...scenario };
    const newEvalCriteriaIds = [...newScenario.evaluation_criteria_ids];
    const index = newEvalCriteriaIds.indexOf(evalCriterionId);
    if (index !== -1) {
      newEvalCriteriaIds.splice(index, 1);
    } else {
      newEvalCriteriaIds.push(evalCriterionId);
    }
    newScenario.evaluation_criteria_ids = newEvalCriteriaIds;

    dispatch(updateSelectedScenario(newScenario));
  };

  const scenarioKnowHowsHandler = (value: any, i: number) => {
    const newScenario = { ...scenario };
    const newKnowHows = [...newScenario.other_know_hows];

    if (value === "") {
      newKnowHows.splice(i, 1);
    } else {
      newKnowHows[i] = value;
    }
    newScenario.other_know_hows = newKnowHows;
    dispatch(updateSelectedScenario(newScenario));
  };

  const addScenarioKnowHow = () => {
    const newScenario = { ...scenario };
    const newKnowHows = [...newScenario.other_know_hows, ""];
    newScenario.other_know_hows = newKnowHows;

    dispatch(updateSelectedScenario(newScenario));
  };

  const scenarioCourseHandler = (value: any) => {
    const newScenario = { ...scenario };

    if (value.value !== newScenario.course.id) {
      const newCourse = courses.find((course) => course.id === value.value);
      if (newCourse === undefined) {
        return;
      }
      newScenario.course = newCourse;
      newScenario.evaluation_criteria_ids = [];
    }
    dispatch(updateSelectedScenario(newScenario));
  };

  const scenarioRegulationHandler = (value: any) => {
    const newScenario = { ...scenario };
    if (value.value !== newScenario.course.regulation_id) {
      const newCourse = courses.find(
        (course) => course.regulation_id === value.value
      );

      if (newCourse === undefined) {
        return;
      }

      newScenario.course = newCourse;
      newScenario.evaluation_criteria_ids = [];
    }
    dispatch(updateSelectedScenario(newScenario));
    setSelectedRegulationId(value.value);
  };

  const scenarioLanguageHandler = (value: any) => {
    const newscenario = { ...scenario };
    newscenario.language = value.value;
    dispatch(updateSelectedScenario(newscenario));
  };

  const deleteScenarioHandler = async () => {
    await DeleteScenarioAPI(scenario.id);
    dispatch(submenu("marketplace"));
  };

  const permanentlyDeleteScenarioHandler = async () => {
    await PermanentlyDeleteScenarioAPI(scenario.id);
    dispatch(submenu("marketplace_selector"));
  };

  const deleteMenuHandler = () => {
    setDeleteMenu(true);
  };
  const visibililtyHandler = (value: any) => {
    const newscenario = { ...scenario };
    newscenario.visibility = value.value;
    dispatch(updateSelectedScenario(newscenario));
    setVisibility(newscenario.visibility);
  };

  const scenarioBackHandler = async () => {
    if (deleteOnExit) {
      await permanentlyDeleteScenarioHandler();
      dispatch(submenu("marketplace_selector"));
      dispatch(setDeleteOnExit(false));
      return;
    } else {
      loadScenario();
    }
    dispatch(submenu("scenario"));
  };

  const scenarioSaveHandler = async () => {
    if (
      scenario.course.regulation_id !== undefined &&
      scenario.course.regulation_id !== null &&
      scenario.course.regulation_id !== "" &&
      scenario.course.id !== undefined &&
      scenario.course.id !== null &&
      scenario.course.id !== ""
    ) {
      await saveScenario();
      dispatch(setDeleteOnExit(false));
      dispatch(submenu("scenario"));
      toast({
        title: t("save_changes_toast"),
        status: "success",
        duration: 3000,
        variant: "subtle",
        isClosable: true,
      });
    } else {
      setAlert("scenario_reg_course_error");
    }
  };

  const imageHandler = (newScenario: ModifiedScenarioType) => {
    loadScenario();
  };

  const alertHandler = () => {
    setAlert(null);
  };

  const renderDeleteMenu = () => {
    return (
      <div className="professor_sidebar_delete_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="center_container">
            <div className="professor_sidebar_delete_menu_container">
              <div className="professor_sidebar_delete_menu">
                <div className="professor_sidebar_delete_menu_text">
                  <div className="center_container">
                    <div className="text_black_medium">
                      {t("delete_scenario_text")}
                      <b>{scenario.main_know_how}</b>?
                    </div>
                  </div>
                </div>
                <div
                  className="professor_sidebar_delete_menu_close"
                  onClick={() => setDeleteMenu(false)}
                >
                  <m.img
                    className="icon"
                    src={close}
                    alt="close"
                    whileHover={{ scale: 1.1, rotate: 180 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  />
                </div>

                <div className="professor_sidebar_delete_menu_buttons">
                  <m.div
                    className="professor_sidebar_delete_menu_button_yes"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => deleteScenarioHandler()}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("yes")}</div>
                    </div>
                  </m.div>

                  <m.div
                    className="professor_sidebar_delete_menu_button_no"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => setDeleteMenu(false)}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("no")}</div>
                    </div>
                  </m.div>
                </div>
              </div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  const renderBackMenu = () => {
    return (
      <div className="professor_sidebar_delete_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="center_container">
            <div className="professor_scenario_edit_back_menu_container">
              <div
                className="professor_sidebar_delete_menu_close"
                onClick={() => setBackMenu(false)}
              >
                <m.img
                  className="icon"
                  src={close}
                  alt="close"
                  whileHover={{ scale: 1.1, rotate: 180 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                />
              </div>
              <div className="professor_scenario_edit_back_menu">
                <div className="professor_sidebar_delete_menu_text">
                  <div className="center_container">
                    <div className="text_black_medium">
                      {t("back_scenario_text") +
                        (deleteOnExit ? t("back_scenario_text_delete") : "")}
                    </div>
                  </div>
                </div>

                <div className="professor_sidebar_delete_menu_buttons">
                  <m.div
                    className="professor_sidebar_delete_menu_button_yes"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => scenarioBackHandler()}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("yes")}</div>
                    </div>
                  </m.div>

                  <m.div
                    className="professor_sidebar_delete_menu_button_no"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => setBackMenu(false)}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("no")}</div>
                    </div>
                  </m.div>
                </div>
              </div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  const renderSaveButton = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <div className="professor_scenario_edit_menu_buttons_container">
          <m.div
            className="professor_edit_scenario_menu_button"
            whileHover={{ scale: 1.1 }}
            onClick={() => scenarioSaveHandler()}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <div className="center_container">
              <div className="text_white_small_bold">{t("save")}</div>
            </div>
          </m.div>
        </div>
      </LazyMotion>
    );
  };

  const renderButton = (previous: boolean) => {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          className="professor_scenario_edit_menu_button"
          onClick={() => {
            if (previous) {
              if (page > 0) {
                setPage(page - 1);
              }
            } else {
              if (page < 2) {
                setPage(page + 1);
              } else if (page === 2) {
                scenarioSaveHandler();
              }
            }
          }}
          style={{
            backgroundColor: previous
              ? page === 0
                ? "#C8C8C8"
                : "#5090F0"
              : page === 2
              ? "#6ee6a7"
              : "#5090F0",
            boxShadow: !previous
              ? page === 2
                ? "0px 0px 10px 0px #6ee6a7"
                : "0px 0px 10px 0px #5090F0"
              : "none",
            cursor:
              page === 0 && previous
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
          whileHover={{
            scale: page === 0 && previous ? 1 : 1.1,
          }}
          transition={{ type: "spring", ease: "easeInOut", duration: 0.2 }}
        >
          <div className="center_container">
            <div className="text_white_small_bold">
              {previous ? t("previous") : page === 2 ? t("save") : t("next")}
            </div>
          </div>
        </m.div>
      </LazyMotion>
    );
  };

  const renderBreadcrumbs = () => {
    const breadcrumbTooltips = [
      t("SDA_top_title_1"),
      t("SDA_edit_top_title_2"),
      t("SDA_edit_top_title_3"),
    ];
    return (
      <div className="professor_scenario_edit_menu_breadcrumbs">
        {breadcrumbTooltips.map((tooltipText, index) => {
          return (
            <Tooltip
              key={index}
              label={tooltipText}
              bg={"#7D7D7D"}
              placement="top"
              sx={{
                fontFamily: "Causten",
                fontSize: "calc(5px + 0.5vw)",
                borderRadius: "5px",
              }}
              hasArrow
            >
              <motion.div
                key={index}
                className={
                  index === page
                    ? "professor_scenario_edit_menu_breadcrumb_active"
                    : "professor_scenario_edit_menu_breadcrumb_inactive"
                }
                onClick={() => setPage(index)}
                initial={{
                  scale: index === page ? 0 : 1,
                  backgroundColor: "#FFFFFF",
                  border: index === page ? "none" : "3px solid #EEEEEE",
                }}
                animate={{
                  scale: 1,
                  backgroundColor: index === page ? "#5090F0" : "#FFFFFF",
                  border: index === page ? "none" : "3px solid #EEEEEE",
                }}
                transition={{ duration: index === page ? 1.2 : 0.1 }}
              ></motion.div>
            </Tooltip>
          );
        })}
      </div>
    );
  };

  const renderDeleteButton = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          className="professor_scenario_edit_menu_top_delete"
          onClick={(e) => {
            e.stopPropagation();
            deleteMenuHandler();
          }}
          whileHover={{
            scale: 1.1,
            boxShadow: "0px 0px 10px 1px #F0684F",
          }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <div className="center_container">
            <img
              src={bin}
              style={{ height: "80%", position: "relative" }}
              alt="bin"
            />
          </div>
        </m.div>
      </LazyMotion>
    );
  };

  const renderTop = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <div className="professor_scenario_edit_menu_top">
          <m.div
            className="professor_scenario_edit_menu_top_title"
            whileHover={{
              scale: 1.02,
              textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
            onClick={() => setBackMenu(true)}
          >
            <BackCross size={"4.5vh"} />
            <div className="professor_scenario_edit_menu_top_title_pages">
              <div className="text_black_small_bold">{page + 1 + "/3"}</div>
            </div>
            <div className="text_black_small_bold">
              {page === 0
                ? t("SDA_top_title_1")
                : page === 1
                ? t("SDA_edit_top_title_2")
                : t("SDA_edit_top_title_3")}
            </div>
          </m.div>
          {renderDeleteButton()}
        </div>
      </LazyMotion>
    );
  };

  const renderSDA = () => {
    return (
      <div className="professor_scenario_edit_menu_main_page_1_bottom_grid">
        <div className="text_black_small_bold">{t("sda") + ":"}</div>
        <div className="text_black_small_bold">
          {t("basic_knowledge") + ":"}
        </div>
        <div className="professor_scenario_edit_menu_main_page_1_bottom_left">
          <div
            className="image-container"
            onMouseEnter={() => setShowHoverIcon(true)}
            onMouseLeave={() => setShowHoverIcon(false)}
          >
            {!showHoverIcon && (
              <img
                className="image"
                src={
                  _.isUndefined(scenario.image?.url)
                    ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/ES/Tutorial/Tutorial+-+ICON.jpg"
                    : scenario.image?.url
                }
                alt="scenario_image"
              />
            )}
            {showHoverIcon && (
              <ScenarioImageSelector
                imageHandler={(scenario: ModifiedScenarioType) =>
                  imageHandler(scenario)
                }
                image={scenario.image}
                scenarioId={scenario.id}
              />
            )}
          </div>
          <div className="professor_scenario_edit_menu_main_page_1_bottom_left_info">
            <Input
              placeholder={t("scenario_name")}
              value={scenario?.name}
              onChange={(e) => setScenarioName(e.target.value)}
              style={{
                fontFamily: "Causten",
                fontWeight: "bold",
                background: "#EEEEEE",
                fontSize: "1.2em",
                borderRadius: "2.5px",
                color: "black",
                width: "100%",
              }}
              as={motion.input}
              size={"sm"}
              whileHover={{
                scale: 1.01,
                boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
              }}
            />
            <div className="professor_scenario_edit_menu_main_page_1_bottom_left_info_desc">
              <Textarea
                placeholder={t("scenario_description")}
                value={scenario.description}
                resize={"none"}
                onChange={(e) => setScenarioDescription(e.target.value)}
                style={{
                  fontFamily: "Causten",
                  background: "#EEEEEE",
                  overflowY: "scroll",
                  fontSize: "1.2em",
                  borderRadius: "2.5px",
                  marginTop: "0.5vh",
                  color: "black",
                  width: "100%",
                }}
                as={motion.textarea}
                size={"sm"}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
                }}
              />
            </div>
          </div>
        </div>
        <div className="professor_scenario_edit_menu_main_page_1_bottom_right">
          <Wrap
            justify="space-between"
            align="center"
            style={{ width: "100%", height: "100%" }}
          >
            <WrapItem w="49%">
              <Input
                placeholder={t("scenario_main_know_how")}
                value={scenario.main_know_how}
                isReadOnly={true}
                autoFocus={false}
                style={{
                  fontFamily: "Causten",
                  fontWeight: "bold",
                  background: "#EEEEEE",
                  fontSize: "1em",
                  borderRadius: "2.5px",
                  color: "#6B6B6B",
                  width: "100%",
                }}
                size={"xs"}
              />
            </WrapItem>
            {scenario.other_know_hows.map((knowHow: any, i: number) => {
              return (
                <WrapItem w="49%">
                  <Input
                    placeholder={t("scenario_know_hows_placeholder")}
                    value={knowHow}
                    isReadOnly={false}
                    onChange={(e) => scenarioKnowHowsHandler(e.target.value, i)}
                    style={{
                      fontFamily: "Causten",
                      fontWeight: "bold",
                      background: "#EEEEEE",
                      fontSize: "1em",
                      borderRadius: "2.5px",
                      color: "black",
                      width: "100%",
                    }}
                    as={motion.input}
                    size={"xs"}
                    whileHover={{
                      scale: 1.01,
                      boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
                    }}
                  />
                </WrapItem>
              );
            })}
            <WrapItem w="49%">
              <Button
                onClick={() => {
                  addScenarioKnowHow();
                }}
                style={{
                  fontFamily: "Causten",
                  fontWeight: "bold",
                  background: "#EEEEEE",
                  fontSize: "1em",
                  borderRadius: "2.5px",
                  color: "black",
                  width: "100%",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                as={motion.button}
                size={"xs"}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
                }}
              >
                {t("add_know_how")}
              </Button>
            </WrapItem>
          </Wrap>
        </div>
      </div>
    );
  };

  const renderDropdowns = () => {
    return (
      <div className="professor_scenario_edit_menu_main_page_1_top_badges">
        <Select
          name="regulations"
          options={regulations.map((reg) => {
            return {
              value: reg.id,
              label: t(reg.name, { ns: "evaluation" }),
            };
          })}
          value={
            regulations
              .filter((reg) => reg.id === selectedRegulationId)
              .map((reg) => {
                return {
                  value: reg.id,
                  label: t(reg.name, { ns: "evaluation" }),
                };
              })[0]
          }
          useBasicStyles
          placeholder={t("regulation_placeholder")}
          closeMenuOnSelect={true}
          key={1}
          onChange={scenarioRegulationHandler}
          chakraStyles={{
            control: (provided, state) => ({
              ...provided,
              width: "10vw",
              fontFamily: "Causten",
              fontWeight: "bold",
              fontSize: "0.8em",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              background: regulations.find(
                (reg) => reg.id === selectedRegulationId
              )?.color,
              color: "white",
              borderRadius: "50px",
              marginRight: "1vw",
            }),
            menuList: (provided) => ({
              ...provided,
              fontFamily: "Causten",
            }),
            option: (provided, state) => ({
              ...provided,
              color: regulations.find((reg) => reg.id === state.data.value)
                ?.color,
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              "&:hover": {
                background: "#5090F0",
                color: "white",
              },
            }),
            dropdownIndicator: (provided, { selectProps, hasValue }) => ({
              ...provided,
              color: hasValue ? "white" : "black",
              "> svg": {
                transitionDuration: "normal",
                transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
              },
            }),
            // To remove space between text and dropdown
            valueContainer: (provided) => ({
              ...provided,
              // width: "fit-content",
              WebkitPaddingEnd: "0px",
            }),
            singleValue: (provided) => ({
              ...provided,
              width: "fit-content",
            }),
          }}
        />
        <Select
          className="professor_edit_scenario_menu_top_left_course_select"
          placeholder={t("subjects_placeholder")}
          options={courses
            .filter((course) => {
              return (
                course.regulation_id === selectedRegulationId &&
                course.acronym !== "CM_EP"
              );
            })
            .map((course) => {
              return {
                value: course.id,
                label: t(course.acronym + "_name", { ns: "evaluation" }),
              };
            })}
          value={
            courses
              .filter((course) => {
                return course.id === scenario.course.id;
              })
              .map((course) => {
                return {
                  value: course.id,
                  label: t(course.acronym + "_name", { ns: "evaluation" }),
                };
              })[0] || null // JANK - quite janky indeed
          }
          key={scenario?.course.id}
          onChange={scenarioCourseHandler}
          size={"md"}
          useBasicStyles
          closeMenuOnSelect={true}
          focusBorderColor={
            courses.find((course) => course.id === scenario.course.id)?.color ||
            "black"
          }
          chakraStyles={{
            control: (provided, state) => ({
              ...provided,
              width: "12vw",
              fontFamily: "Causten",
              fontWeight: "bold",
              fontSize: "0.8em",
              border: state.hasValue ? "none" : undefined,
              boxShadow: state.isFocused
                ? "0px 0px 10px 2px " +
                  courses.find((course) => course.id === scenario.course.id)
                    ?.color
                : "none",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              background: state.hasValue
                ? courses.find((course) => course.id === scenario.course.id)
                    ?.color
                : "white",
              color: "white",
              borderRadius: "50px",
            }),
            menuList: (provided) => ({
              ...provided,
              fontFamily: "Causten",
            }),
            dropdownIndicator: (provided, { selectProps, hasValue }) => ({
              ...provided,
              color: hasValue ? "white" : "black",
              "> svg": {
                transitionDuration: "normal",
                transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
              },
            }),
            valueContainer: (provided) => ({
              ...provided,
              // width: "fit-content",
              WebkitPaddingEnd: "0px",
            }),
            singleValue: (provided) => ({
              ...provided,
              width: "fit-content",
              // textOverflow: "none",
            }),
          }}
        />
        <Select
          name="languages"
          useBasicStyles
          options={languages}
          closeMenuOnSelect={true}
          placeholder={t("languages_placeholder")}
          onChange={scenarioLanguageHandler}
          value={languages.find((lang) => lang.value === scenario.language)}
          key={scenario.language}
          chakraStyles={{
            control: (provided, state) => ({
              ...provided,
              // width: "fit-content",
              width: "8vw",
              fontFamily: "Causten",
              left: "1vw",
              fontWeight: "bold",
              fontSize: "0.8em",
              boxShadow: state.isFocused ? "0px 0px 10px 2px #292F4C" : "none",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              background: scenario.language ? "#292F4C" : "none",
              color: "white",
              borderRadius: "50px",
              marginRight: "1vw",
            }),
            menuList: (provided) => ({
              ...provided,
              fontFamily: "Causten",
            }),
            dropdownIndicator: (provided, { selectProps }) => ({
              ...provided,
              color: scenario.language ? "white" : "black",
              "> svg": {
                transitionDuration: "normal",
                transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
              },
            }),
            valueContainer: (provided) => ({
              ...provided,
              // width: "fit-content",
              WebkitPaddingEnd: "0px",
            }),
            singleValue: (provided) => ({
              ...provided,
              width: "fit-content",
            }),
          }}
        />
        {!_.isNull(userLicense) && userLicense.license.allow_private && (
          <Select
            name="visibility"
            useBasicStyles
            options={visibilityOptions}
            closeMenuOnSelect={true}
            // placeholder={t("languages_placeholder")}
            onChange={visibililtyHandler}
            value={visibilityOptions.find(
              (vis) => vis.value === scenario.visibility
            )}
            key={scenario.visibility}
            chakraStyles={{
              control: (provided, state) => ({
                ...provided,
                width: "9vw",
                left: "1vw",
                fontFamily: "Causten",
                fontWeight: "bold",
                fontSize: "0.8em",
                boxShadow: state.isFocused
                  ? "0px 0px 10px 2px #292F4C"
                  : "none",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                background: "#6EE6A7",
                color: "white",
                borderRadius: "50px",
              }),
              menuList: (provided) => ({
                ...provided,
                fontFamily: "Causten",
                left: "1vw",
              }),
              dropdownIndicator: (provided, { selectProps }) => ({
                ...provided,
                color: scenario.visibility ? "white" : "black",
                "> svg": {
                  transitionDuration: "normal",
                  transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
                },
              }),
              valueContainer: (provided) => ({
                ...provided,
                // width: "fit-content",
                WebkitPaddingEnd: "0px",
              }),
              singleValue: (provided) => ({
                ...provided,
                width: "fit-content",
              }),
            }}
          />
        )}
      </div>
    );
  };

  const renderPage1 = () => {
    return (
      <div
        className="professor_scenario_edit_menu_main_page_1"
        style={{ top: "10%", height: "80%" }}
      >
        <div className="professor_scenario_edit_menu_main_page_1_top">
          <div
            className="professor_scenario_edit_menu_main_page_1_top_banner"
            style={{
              backgroundImage: `linear-gradient(to left, rgba(128, 128, 128, 0.9), rgba(128, 128, 128, 0) 70%), url(${scenario.banner.url})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="text_white_very_small">
              {" "}
              {t("created_by") + " "}
              <b>{scenario.owner?.fullname}</b>
            </div>
          </div>
        </div>
        <div className="professor_scenario_edit_menu_main_page_1_middle">
          <Input
            placeholder={t("scenario_main_know_how")}
            value={scenario.main_know_how}
            onChange={(e) => scenarioMainKnowHowHandler(e.target.value)}
            style={{
              fontFamily: "Causten",
              fontWeight: "bold",
              width: "45%",
              fontSize: "1.5em",
              borderRadius: "2.5px",
              color: "black",
              background: "#EEEEEE",
              boxShadow: "none",
            }}
            as={motion.input}
            size={"lg"}
            whileHover={{
              scale: 1.01,
              boxShadow: "rgba(80, 144, 240, 0.50) 0px 0px 10px 2px",
            }}
          />
          {renderDropdowns()}
        </div>

        <div className="professor_scenario_edit_menu_main_page_1_bottom">
          {renderSDA()}
        </div>
      </div>
    );
  };

  const renderPage2 = () => {
    return (
      <ExerciseEditor
        language={scenario.language}
        reloadExercises={reloadExercises}
      />
    );
  };

  const renderPage3 = () => {
    const selectedSpecificCompetences = specificCompetencesBooleanArray(
      scenario.specific_competences_ids,
      specificCompetences
    );

    return (
      <div className="professor_scenario_edit_menu_main_page_3">
        <div className="professor_scenario_edit_menu_main_page_3_top">
          {evaluationCriteria.map((evaluationCriterion) => {
            const isSelected = scenario.evaluation_criteria_ids.includes(
              evaluationCriterion.id
            );

            const specificCompetence = specificCompetences.find(
              (speComp) =>
                speComp.id === evaluationCriterion.specific_competence_id
            );

            return (
              <div
                className="professor_scenario_edit_menu_main_page_3_top_badge"
                style={{
                  backgroundColor: isSelected ? "#8DBCFF" : "#D9D9D980",
                }}
                onClick={() =>
                  selectScenarioEvalCriteria(evaluationCriterion.id)
                }
              >
                <div
                  key={evaluationCriterion.id}
                  className={
                    isSelected
                      ? "text_black_super_small_bold"
                      : "text_black_super_small"
                  }
                >
                  <b>
                    {prettifyCourseAcronyms(scenario.course.acronym) ||
                      "" + specificCompetence?.number ||
                      0 + "." + evaluationCriterion?.number ||
                      0 + ": "}
                  </b>
                  {t(
                    (scenario.course.acronym || "") +
                      (specificCompetence?.number || 0) +
                      "." +
                      (evaluationCriterion?.number || 0) +
                      "_desc",
                    { ns: "evaluation" }
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="professor_scenario_edit_menu_main_page_3_bottom">
          <div className="professor_scenario_edit_menu_main_page_3_bottom_container">
            <div className="text_black_small_bold">
              {t("specific_competences") + ":"}
            </div>
            <div
              className="professor_scenario_edit_menu_main_page_3_bottom_spec_comp"
              style={{
                gridTemplateColumns:
                  "repeat(" + selectedSpecificCompetences.length + ", 1fr)",
              }}
            >
              {specificCompetences.map((competence, index) => {
                const active: boolean = scenario.evaluation_criteria_ids
                  .map(
                    (id) =>
                      evaluationCriteria.find((ev) => ev.id === id)
                        ?.specific_competence_id
                  )
                  .includes(competence.id);
                return (
                  <div
                    style={{
                      fontSize: "0.65em",
                      fontFamily: "Causten",
                      backgroundColor: active
                        ? "rgba(41, 47, 76, 1)"
                        : "rgba(41, 47, 76, 0.25)",
                      width: "100%",
                      borderRadius: "100px",
                      maxHeight: "1.3em",
                      color: "white",
                      padding: "0.1vh 0.5vw 0.1vh 0.5vw",
                      textAlign: "center",
                      marginRight: "0.5vw",
                    }}
                  >
                    {prettifyCourseAcronyms(scenario.course.acronym) +
                      (index + 1).toString()}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="professor_scenario_edit_menu_main_page_3_bottom_container">
            <div className="text_black_small_bold">
              {t("key_competences") + ":"}
            </div>
            <div
              className="professor_scenario_edit_menu_main_page_3_bottom_key_comp"
              style={{
                gridTemplateColumns:
                  "repeat(" + simpleGlobalCompetences.length + ", 1fr)",
              }}
            >
              {simpleGlobalCompetences.map((competence, index: number) => {
                const nonSelectedColor =
                  competence[1].substr(0, competence[1].length - 2) + "0.25)";
                return (
                  <div
                    style={{
                      fontSize: "0.65em",
                      fontFamily: "Causten",
                      backgroundColor: selectedGlobalCompetences[index]
                        ? competence[1]
                        : nonSelectedColor,
                      width: "100%",
                      borderRadius: "100px",
                      maxHeight: "1.3em",
                      color: "white",
                      padding: "0.1vh 0.5vw 0.1vh 0.5vw",
                      textAlign: "center",
                      marginRight: "0.5vw",
                    }}
                  >
                    {competence[0]}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="professor_scenario_edit_menu_main">
        {page === 0 && renderPage1()}
        {page === 1 && renderPage2()}
        {page === 2 && renderPage3()}
      </div>
    );
  };
  const renderBottom = () => {
    return (
      <div className="professor_scenario_edit_menu_bottom">
        {renderButton(true)}
        {renderBreadcrumbs()}
        {renderButton(false)}
      </div>
    );
  };

  return (
    <div
      className="professor_scenario_edit_menu"
      style={{
        gridTemplateRows: "10% 80% 10%",
      }}
    >
      {renderTop()}
      {renderMain()}
      {renderBottom()}
      {deleteMenu && renderDeleteMenu()}
      {backMenu && renderBackMenu()}
      {teacherState.teacherTutorial === "update_2" && page === 1 && (
        <TeacherTutorialScenarioUpdate2 />
      )}
    </div>
  );
};

export default ScenarioEditMenu;
