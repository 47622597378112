import "./TheoryEdit.css";
import { useState, useEffect } from "react";
import { Textarea } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { ExerciseType } from "../../../../_newapios/content/unit";
import _ from "lodash";
import { useTranslation } from "react-i18next";
const TheoryEdit = (props: {
  exercise: ExerciseType;
  selectedExercise: number;
  updateTheory: (newTheory: ExerciseType) => void;
}) => {
  const [text, setText] = useState(props.exercise.text);
  const [saveToDB, setSaveToDB] = useState(false);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (saveToDB) {
      var exercise = { ...props.exercise };
      exercise.text = text;
      props.updateTheory(exercise);
      setSaveToDB(false);
    }
  }, [saveToDB]);
  useEffect(() => {
    setText(props.exercise.text);
  }, [props.exercise.text, props.selectedExercise]);

  const textHandler = (newText: string) => {
    setText(newText);
  };

  const renderProfessor = () => {
    return (
      <div className="edit_theory_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              window.innerWidth > 991
                ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_big.svg"
                : "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_cropped.svg"
            }
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <motion.div
        className="edit_theory_text_box"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        {!_.isUndefined(text) && (
          <div
            className={
              text.length < 265 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={text}
              resize={"none"}
              onChange={(e) => textHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  return (
    <div className="edit_theory">
      {renderProfessor()}
      {renderTextBox()}
    </div>
  );
};

export default TheoryEdit;
