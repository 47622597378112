import { getBackendHost } from "../../utils/apiconnection";
import APIFetch from "../../utils/APIFetch.ts";
import { BundleType } from "../user/class.ts";
import { ScenarioType } from "./unit.ts";

export type ModifiedScenarioType = ScenarioType & {
  specific_competences_ids: string[];
  global_competences_ids: string[];
};

export type ModifiedScenarioBundleType = ModifiedScenarioType & {
  bundles: BundleType[];
};


export const GetAllScenariosAPI = async (): Promise<ModifiedScenarioType[]> => {
  // var req = await fetch(getBackendHost() + "/scenario/all", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "GET",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = req.json();
  var data = APIFetch(getBackendHost() + "/scenario/all", {
    method: "GET",
  });
  return data;
};

export const AllBundlesofScenarioAPI = async (
  scenario_id: string,
  class_id: string
): Promise<BundleType[] | null> => {
  // const req = await fetch(
  //   getBackendHost() + "/scenario/" + scenario_id + "/bundles/" + class_id,
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     method: "GET",
  //     mode: "cors",
  //     credentials: "include",
  //   }
  // );

  // var data = await req.json();
  var data = APIFetch(getBackendHost() + "/scenario/" + scenario_id + "/bundles/" + class_id, {
    method: "GET",
  });

  data = data || null;
  return data;
};

export const UpdateScenarioAPI = async (
  id: string,
  scenario: ScenarioType
): Promise<ModifiedScenarioType> => {
  // var req = await fetch(getBackendHost() + "/scenario/" + id, {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "POST",
  //   body: JSON.stringify(scenario),
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var status = req.status;
  // TODO - handle errors??
  // var data = req.json();
  var data = APIFetch(getBackendHost() + "/scenario/" + id, {
    method: "POST",
    body: JSON.stringify(scenario),
  });
  return data;
};

export const DeleteScenarioAPI = async (id: string): Promise<void> => {
  var req = await fetch(getBackendHost() + "/scenario/" + id, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });
  var data = req.status;
};

export const PermanentlyDeleteScenarioAPI = async (
  id: string
): Promise<void> => {
  // var req = await fetch(getBackendHost() + "/scenario/permanent/" + id, {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "DELETE",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = req.status;
  var data = APIFetch(getBackendHost() + "/scenario/permanent/" + id, {
    method: "DELETE",
  });
};

export const CreateScenarioAPI = async (
  regulation_id: string
): Promise<ModifiedScenarioType> => {
  // var req = await fetch(getBackendHost() + "/scenario/", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "POST",
  //   body: JSON.stringify({ regulation_id }),
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = req.json();
  var data = APIFetch(getBackendHost() + "/scenario/", {
    method: "POST",
    body: JSON.stringify({ regulation_id }),
  });
  return data;
};

export const GetScenarioAPI = async (
  scenario_id: string
): Promise<ModifiedScenarioType> => {
  var req = await fetch(getBackendHost() + "/scenario/" + scenario_id, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
    mode: "cors",
    credentials: "include",
  });
  var data = await req.json();

  if (
    data.specfic_competences_ids === undefined &&
    data.global_competences_ids === undefined
  ) {
    data.specific_competences_ids = [];
    data.global_competences_ids = [];
  }

  return data;
};

export const CloneScenarioAPI = async (
  existing_scenario_id: string
): Promise<ModifiedScenarioType | null> => {
  // var req = await fetch(
  //   getBackendHost() + "/scenario/" + existing_scenario_id + "/clone",
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     method: "POST",
  //     mode: "cors",
  //     credentials: "include",
  //   }
  // );

  // var data = await req.json();
  var data = APIFetch(getBackendHost() + "/scenario/" + existing_scenario_id + "/clone", {
    method: "POST",
  });

  data = data || null;

  return data;
};

export const GetTeacherResourcesAPI = async (): Promise<ModifiedScenarioBundleType[] | null> => {
  // var req = await fetch(
  //   getBackendHost() + "/me/scenarios_bundles/", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "GET",
  //   mode: "cors",
  //   credentials: "include",
  // });

  // var data = await req.json();

  var data = APIFetch(getBackendHost() + "/me/scenarios_bundles/", {
    method: "GET",
  });

  data = data || null;

  return data;

};
