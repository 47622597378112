import { useTranslation } from "react-i18next";
import "./ScenarioMenu.css";
import { useState, useEffect } from "react";
import { LazyMotion, m, motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks.ts";
import {
  menu,
  setDeleteOnExit,
  submenu,
  updateSelectedBundle,
  updateSelectedScenario,
} from "../../../reducers/teacherSlice.ts";
import BackArrow from "../../../assets/exercises/back_arrow.tsx";
import GroupAddButton from "../../../assets/professor/studentAddGroup.tsx";
import OnePersonIcon from "../../../assets/professor/one_person_icon.svg";
import tick from "../../../assets/professor/ok.svg";
import BackCross from "../../../assets/professor/close.tsx";
import {
  CourseType,
  GetCourseEvaluationCriteriaAPI,
  GetCourseSpecificCompetencesAPI,
} from "../../../_newapios/content/course.ts";
import {
  AllBundlesofScenarioAPI,
  CloneScenarioAPI,
  ModifiedScenarioType,
} from "../../../_newapios/content/scenario.ts";
import { RegulationType } from "../../../_newapios/content/regulation.ts";
import {
  chooseLanguageFromDB,
  isExerciseComplete,
} from "../../../utils/content.ts";
import {
  GetStudentScenarioPointsAPI,
  StudentCoursePoints,
} from "../../../_newapios/progress/student_course_points.ts";
import { Box, Divider, Spinner, Switch } from "@chakra-ui/react";
import { BundleType } from "../../../_newapios/user/class.ts";
import editIcon from "../../../assets/professor/edit.svg";
import {
  EvaluationCriterionType,
  SpecificCompetenceType,
} from "../../../_newapios/content/unit.ts";
import {
  CreateBundleAPI,
  DeleteBundleAPI,
  UpdateBundleAPI,
} from "../../../_newapios/content/bundle.ts";
import {
  globalCompetencesBooleanArray,
  prettifyCourseAcronyms,
  specificCompetencesBooleanArray,
} from "../../../utils/competences.ts";
import { simpleGlobalCompetences } from "../../../constants.ts";
import { GetResearchAPI } from "../../../_newapios/content/research.ts";
import { GetExperimentAPI } from "../../../_newapios/content/experiment.ts";
import {
  changeX,
  setExperiment,
  setResearch,
  setSelectedCourseId,
} from "../../../reducers/mainSlice.ts";
import ExerciseVisualizer from "./Exercises/ExerciseVisualizer/ExerciseVisualizer.tsx";
import { setGroupsFunc, studentsAreGroup } from "../../../utils/group.ts";
import { GetAllGroupsAPI, GroupType } from "../../../_newapios/user/group.ts";

import {
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Checkbox,
  MenuGroup,
  Tooltip,
} from "@chakra-ui/react";
import { ChevronDownIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import { StudentType } from "../../../_newapios/user/student.ts";
import close from "../../../assets/student/close_user_menu.svg";
import { toast } from "../../../index.tsx";
import _ from "lodash";
import Alert from "../../alerts/Alert.tsx";
import Loading from "../../loading_screen/LoadingDots.tsx";

import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const ScenarioMenu = () => {
  const { t, i18n } = useTranslation(["common", "evaluation"]);
  const loginState = useAppSelector((state) => state.login);
  const dispatch = useAppDispatch();

  const mainState = useAppSelector((state) => state.main);
  const teacherState = useAppSelector((state) => state.teacher);

  const scenario = teacherState.selectedScenario;
  const students = mainState.students;
  const bundle = teacherState.selectedBundle;
  const regulations = mainState.regulations;
  const globalCompetences = mainState.globalCompetences;
  const scenarioMenuParent = teacherState.scenarioMenuParent;
  const research = mainState.researchExercises;
  const experiment = mainState.experimentExercises;

  const [loading, setLoading] = useState(true);
  const [loadingBundles, setLoadingBundles] = useState<boolean>(true);
  const [loadingScenario, setLoadingScenario] = useState<boolean>(true);

  const [scenarioSubject, setScenarioSubject] = useState<CourseType>();
  const [scenarioRegulation, setScenarioRegulation] =
    useState<RegulationType>();
  const [scenarioPoints, setScenarioPoints] = useState<StudentCoursePoints>({});
  const [allBundlesofScenario, setAllBundlesofScenario] = useState<
    BundleType[] | null
  >([]);
  const [groups, setGroups] = useState<GroupType[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<GroupType | null>(null);
  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000)
  );
  const [evaluationCriteria, setEvaluationCriteria] = useState<
    EvaluationCriterionType[]
  >([]);

  const [specificCompetences, setSpecificCompetences] = useState<
    SpecificCompetenceType[]
  >([]);
  const [popUpAddBundle, setPopUpAddBundle] = useState<boolean>(false);
  const [deleteMenu, setDeleteMenu] = useState<BundleType | null>(null);

  const [dateError, setDateError] = useState<string | null>(null);
  const [alertChooseGroup, setAlertChooseGroup] = useState(false);
  const isToastDisabled = bundle && bundle?.teacher_id !== loginState.id;
  const [changeBundle, setChangeBundle] = useState<BundleType | null>(null);
  const [duplicateMenu, setDuplicateMenu] = useState<string | null>(null);
  const [alertScenarioAlreadyCopied, setAlertScenarioAlreadyCopied] =
    useState(false);

  const [page, setPage] = useState(0);
  const [showActivityProgress, setShowActivityProgress] = useState(false);
  const [disableCheckboxes, setDisableCheckboxes] = useState<boolean>(false);

  const [isHidden, setIsHidden] = useState<boolean>(false);

  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const setScenarioStuff = (tempscenario: ModifiedScenarioType | null) => {
    if (tempscenario === null) {
      return;
    }
    setScenarioSubject(tempscenario.course);

    const tempRegulation = regulations.filter((regulation) => {
      return regulation.id === tempscenario.course.regulation_id;
    })[0];
    setScenarioRegulation(tempRegulation);
  };

  const loadEvaluationCriteria = async () => {
    setEvaluationCriteria(
      await GetCourseEvaluationCriteriaAPI(scenario?.course_id || "")
    );
  };
  const loadSpecificCompetences = async () => {
    setSpecificCompetences(
      await GetCourseSpecificCompetencesAPI(scenario?.course_id || "")
    );
  };

  const loadScenarioExercises = async (
    scenario: ModifiedScenarioType | null
  ) => {
    if (scenario === null) {
      return;
    }
    const researchId = scenario.research.id;
    const experimentId = scenario.experiment.id;
    const research = await GetResearchAPI(researchId);
    const experiment = await GetExperimentAPI(experimentId);
    dispatch(changeX(Math.random()));
    dispatch(setResearch(research));
    dispatch(setSelectedCourseId(scenario.course_id));
    dispatch(setExperiment(experiment));
  };

  const fetchBundles = async () => {
    if (scenario === null) {
      return;
    }
    if (!scenario.id || !teacherState.selectedClass?.id) {
      return;
    }
    setLoadingBundles(true);
    const bundles = await AllBundlesofScenarioAPI(
      scenario.id,
      teacherState.selectedClass?.id
    );

    setAllBundlesofScenario(bundles);
    setLoadingBundles(false);
  };

  useEffect(() => {
    setLoading(true);
    var promises = [
      setScenarioStuff(scenario),
      loadEvaluationCriteria(),
      loadSpecificCompetences(),
      loadScenarioExercises(scenario),
      fetchBundles(),
      GetAllGroupsAPI(teacherState.selectedClass?.id || "").then((data) =>
        setGroupsFunc(
          students,
          teacherState.selectedClass?.id || "",
          data,
          setGroups
        )
      ),
    ];
    Promise.all(promises).then(() => {
      console.log("loading done");
      setLoading(false);
    });
  }, [scenario, teacherState.selectedClass]);

  useEffect(() => {
    setBundleStuff();
  }, [bundle, allBundlesofScenario]);

  const setBundleStuff = () => {
    if (bundle !== undefined && !_.isEmpty(bundle)) {
      setStartDate(new Date(bundle.start_date));
      setEndDate(new Date(bundle.end_date));
      if (bundle.students !== undefined) {
        setSelectedStudents(bundle.students.map((student) => student.id));
      }

      setIsHidden(bundle.hide);
    } else {
      setStartDate(new Date());
      setEndDate(new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000));
      const allStudentIds: string[] = students.map(
        (student: StudentType) => student.id
      );
      const bundleWithAllStudents = allBundlesofScenario?.find((bun) => {
        const bundleStudentIds = bun.students?.map((student) => student.id);
        if (!bundleStudentIds) return false;
        return arraysEqual(bundleStudentIds, allStudentIds);
      });

      if (bundleWithAllStudents !== undefined) {
        setSelectedStudents([]);
      } else {
        setSelectedStudents(students.map((student: StudentType) => student.id));
      }
    }
  };

  useEffect(() => {
    const newGroup = studentsAreGroup(groups, selectedStudents);
    setSelectedGroup(newGroup);
  }, [selectedStudents, groups]);

  const loadScenarioPoints = async (temp_scenario: ModifiedScenarioType) => {
    let newScenarioPoints = { ...scenarioPoints };
    for (let i = 0; i < mainState.students.length; i++) {
      const studentId = mainState.students[i].id;
      const scenarioId = temp_scenario.id;
      const points = await GetStudentScenarioPointsAPI(studentId, scenarioId);
      if (points !== null) {
        if (newScenarioPoints[studentId] === undefined) {
          newScenarioPoints[studentId] = 0;
        }
        newScenarioPoints[studentId] = points;
      }
    }
    setScenarioPoints(newScenarioPoints);
  };

  useEffect(() => {
    // This one is separate because it takes a long time and we don't need to wait for it
    const fetchScenarioPoints = async () => {
      if (scenario === null) {
        return;
      }
      if (!scenario.id || !teacherState.selectedClass?.id) {
        return;
      }
      setLoadingScenario(true);
      await loadScenarioPoints(scenario);

      console.log("loading scenario points done");
      setLoadingScenario(false);
    };

    fetchScenarioPoints();
  }, [scenario, teacherState.selectedClass]);

  if (scenario === null) {
    return <></>;
  }

  const saveBundleHandler = () => {
    if (teacherState.selectedClass === null) {
      return;
    }
    if (startDate.getTime() > endDate.getTime()) {
      setDateError("end_date_before_start_date");
      confirmChangesHandler();
      return;
    }
    if (endDate.getTime() < new Date().getTime()) {
      setDateError("end_date_before_today");
      confirmChangesHandler();
      return;
    }

    if (
      selectedGroup === null ||
      (selectedStudents.length === 0 && students.length !== 0)
    ) {
      alertChooseGroupHandler();
    }

    const bundleStudents = students.filter((student) =>
      selectedStudents.includes(student.id)
    );

    const newBundle: BundleType = {
      id: "",
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
      weighing: 1,
      hide: isHidden,
      scenario_id: scenario.id,
      class_id: teacherState.selectedClass.id,
      students: bundleStudents,
      teacher_id: loginState.id,
    };

    if (bundle === null) {
      CreateBundleAPI(newBundle).then((result) => {
        dispatch(menu("menus"));
        dispatch(submenu("activities"));
      });
      return;
    }
    newBundle.id = bundle.id;
    UpdateBundleAPI(newBundle).then((result) => {
      dispatch(menu("menus"));
      dispatch(submenu("activities"));
    });

    toast({
      title: t("save_changes_toast"),
      status: "success",
      duration: 3000,
      variant: "subtle",
      isClosable: true,
    });
  };

  const dateErrorHandler = () => {
    setDateError(null);
  };

  const deleteMenuHandler = (bundle: BundleType | null = null) => {
    if (isToastDisabled) {
      toast({
        title: t("access_denied"),
        description: t("not_authorized_delete"),
        status: "error",
        variant: "subtle",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setDeleteMenu(bundle);
  };

  const deleteBundleHandler = async () => {
    var newBundle = bundle;
    if (newBundle === null) return;
    await DeleteBundleAPI(newBundle.id).then((result) => {
      dispatch(menu("menus"));
      dispatch(submenu("activities"));
    });
  };

  const alertScenarioCopiedHandler = () => {
    setAlertScenarioAlreadyCopied(!alertScenarioAlreadyCopied);
  };

  const alertChooseGroupHandler = () => {
    setAlertChooseGroup(!alertChooseGroup);
  };

  const cloneScenarioHandler = async (scenario_id: string) => {
    const newScenario = await CloneScenarioAPI(scenario_id);

    if (newScenario === null) {
      alertScenarioCopiedHandler();
      return;
    }

    dispatch(updateSelectedScenario(newScenario));
    dispatch(updateSelectedBundle(null));
    dispatch(setDeleteOnExit(true));
    setDuplicateMenu(null);
    dispatch(submenu("scenario_edit"));
  };

  const arraysEqual = (a: any[], b: any[]) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    const sortedA = [...a].sort();
    const sortedB = [...b].sort();

    for (var i = 0; i < sortedA.length; ++i) {
      if (sortedA[i] !== sortedB[i]) return false;
    }
    return true;
  };

  const editScenarioHandler = () => {
    if (scenario?.owner_id === loginState.id) {
      dispatch(submenu("scenario_edit"));
    } else {
      setDuplicateMenu(scenario.id);
    }
  };

  const groupHandler = (event: any, group: GroupType) => {
    event.preventDefault();
    if (selectedGroup === group) {
      setSelectedGroup(null);
      setSelectedStudents([]);
      setDisableCheckboxes(false);
      return;
    }
    setSelectedGroup(group);
    setSelectedStudents(group.students.map((student) => student.id));
  };

  const studentHandler = (event: any, student: StudentType) => {
    event.preventDefault();

    if (selectedStudents.includes(student.id)) {
      const newSelectedStudents = selectedStudents.filter(
        (s) => s !== student.id
      );
      const bundleWithSameStudents = allBundlesofScenario?.find((bun) => {
        const bundleStudentIds = bun.students?.map((s) => s.id);
        if (!bundleStudentIds) return false;
        return arraysEqual(bundleStudentIds, newSelectedStudents);
      });
      if (bundleWithSameStudents !== undefined) {
        setSelectedStudents([...selectedStudents]);
      } else {
        setSelectedStudents(newSelectedStudents);
      }
    } else {
      const newSelectedStudents = [...selectedStudents, student.id];
      const bundleWithSameStudents = allBundlesofScenario?.find((bun) => {
        const bundleStudentIds = bun.students?.map((s) => s.id);
        if (!bundleStudentIds) return false;
        return arraysEqual(bundleStudentIds, newSelectedStudents);
      });
      if (bundleWithSameStudents !== undefined) {
        setSelectedStudents([...selectedStudents]);
      } else {
        setSelectedStudents(newSelectedStudents);
      }
    }
    if (selectedGroup !== null) {
      setSelectedGroup(null);
    }
  };

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
  };

  const popUpAddBundleHandler = () => {
    setPopUpAddBundle(!popUpAddBundle);
  };

  const confirmChangesHandler = (bundle: BundleType | null = null) => {
    setPopUpAddBundle(false);
    if (isToastDisabled) {
      toast({
        title: t("access_denied"),
        description: t("not_authorized_change"),
        status: "error",
        variant: "subtle",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setChangeBundle(bundle);
  };

  const renderPopUpAddBundle = () => {
    let menuButtonText: string | undefined;
    if (selectedGroup !== null) {
      menuButtonText = t(selectedGroup.name);
    } else if (selectedStudents.length === 1) {
      menuButtonText = students.find(
        (student) => student.id === selectedStudents[0]
      )?.username;
    } else if (selectedStudents.length > 1) {
      menuButtonText = `${selectedStudents.length} ${t(
        "students"
      ).toLowerCase()}`;
    }
    return (
      <div className="professor_sidebar_delete_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="center_container">
            <div className="scenario_back_pop_up_container">
              <div className="professor_sidebar_delete_menu">
                <div className="scenario_pop_up_main_container_grid">
                  <div className="scenario_pop_up_main_content">
                    <div className="left_container">
                      <div className="text_black_medium_bold">
                        {t("submit_situation")}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateRows: "30% 70%",
                        gap: "1vh",
                        height: "100%",
                        width: "70%",
                      }}
                    >
                      <div className="left_container">
                        <div className="text_black_very_small">{t("for")}</div>
                      </div>
                      <Menu closeOnSelect={false}>
                        <MenuButton
                          color="grey"
                          style={{
                            backgroundColor: "#E8E8E8",
                            boxSizing: "border-box",
                            paddingLeft: "5%",
                            borderRadius: "5px",
                            position: "relative",
                            height: "80%",
                            marginTop: "1%",
                            cursor:
                              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                          }}
                        >
                          <div className="left_container">
                            <div className="text_black_really_small">
                              {menuButtonText
                                ? menuButtonText
                                : t("select_group_student")}
                            </div>
                          </div>
                          <ChevronDownIcon
                            boxSize="2vh"
                            style={{
                              position: "absolute",
                              top: "25%",
                              right: "2%",
                              color: "#B8B8B8",
                            }}
                          />
                        </MenuButton>
                        <MenuList
                          style={{ maxHeight: "40vh", overflowY: "scroll" }}
                        >
                          <MenuGroup
                            title={t("groups")}
                            style={{ fontFamily: "Causten" }}
                          >
                            {groups.map((group) => {
                              const groupStudentIds = group.students.map(
                                (student) => student.id
                              );

                              let bundleWithSameStudents:
                                | BundleType
                                | undefined = undefined;
                              if (allBundlesofScenario !== null) {
                                bundleWithSameStudents =
                                  allBundlesofScenario.find((bun) => {
                                    if (bun.id === bundle?.id) {
                                      return false;
                                    }
                                    const bundleStudentIds = bun.students?.map(
                                      (student) => student.id
                                    );
                                    if (!bundleStudentIds) return false;
                                    return arraysEqual(
                                      bundleStudentIds,
                                      groupStudentIds
                                    );
                                  });
                              }

                              return (
                                <MenuItem
                                  value={group.id}
                                  as={m.div}
                                  key={group.id}
                                  style={{
                                    cursor:
                                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                                  }}
                                  onClick={
                                    bundleWithSameStudents !== undefined
                                      ? () => {}
                                      : (e) => groupHandler(e, group)
                                  }
                                  className="professor_activities_scenario_menu_mid_group_item"
                                  whileHover={{
                                    boxShadow: "0px 0px 10px 1px #BCD1EF",
                                    backgroundColor: "#BCD1EF50",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      gap: "1vh",
                                      fontFamily: "Causten",
                                    }}
                                  >
                                    <GroupAddButton
                                      color={
                                        group.id === "group1"
                                          ? "#6EE6A7"
                                          : "#5090F0"
                                      }
                                      size={28}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        gap: "1vh",
                                      }}
                                    >
                                      {t(group.name)}
                                      <Tooltip
                                        hasArrow
                                        label={
                                          bundleWithSameStudents !== undefined
                                            ? t("group_assigned")
                                            : undefined
                                        }
                                        bg={"#7D7D7D"}
                                        placement="top"
                                        sx={{
                                          fontFamily: "Causten",
                                          fontSize: "calc(5px + 0.5vw)",
                                          borderRadius: "5px",
                                        }}
                                        shouldWrapChildren
                                      >
                                        <Checkbox
                                          isChecked={selectedGroup === group}
                                          isDisabled={
                                            bundleWithSameStudents !== undefined
                                          }
                                          size="lg"
                                          style={{
                                            cursor:
                                              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                                          }}
                                          onChange={
                                            bundleWithSameStudents !== undefined
                                              ? () => {}
                                              : (e) => groupHandler(e, group)
                                          }
                                        />
                                      </Tooltip>
                                    </div>
                                  </div>
                                </MenuItem>
                              );
                            })}
                          </MenuGroup>
                          <MenuDivider />
                          <MenuGroup
                            title={t("students")}
                            style={{ fontFamily: "Causten" }}
                          >
                            {students.map((student) => {
                              let bundleWithSameStudents:
                                | BundleType
                                | undefined = undefined;

                              if (allBundlesofScenario !== null) {
                                bundleWithSameStudents =
                                  allBundlesofScenario.find((bun) => {
                                    if (bun.id === bundle?.id) {
                                      return false;
                                    }
                                    const bundleStudentIds = bun.students?.map(
                                      (student) => student.id
                                    );
                                    if (!bundleStudentIds) return false;
                                    return arraysEqual(
                                      bundleStudentIds,
                                      selectedStudents
                                    );
                                  });
                              }
                              return (
                                <MenuItem
                                  value={student.id}
                                  as={m.div}
                                  key={student.id}
                                  style={{
                                    cursor:
                                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                                  }}
                                  onClick={
                                    bundleWithSameStudents !== undefined
                                      ? () => {}
                                      : (e) => studentHandler(e, student)
                                  }
                                  className="professor_activities_scenario_menu_mid_group_item"
                                  whileHover={{
                                    boxShadow: "0px 0px 10px 1px #BCD1EF",
                                    backgroundColor: "#BCD1EF50",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      gap: "1vh",
                                      fontFamily: "Causten",
                                    }}
                                  >
                                    <img
                                      src={OnePersonIcon}
                                      alt="person"
                                      style={{ height: "28px" }}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        gap: "1vh",
                                      }}
                                    >
                                      {student.username}
                                      <Checkbox
                                        onChange={
                                          bundleWithSameStudents !== undefined
                                            ? () => {}
                                            : (e) => studentHandler(e, student)
                                        }
                                        isChecked={selectedStudents.includes(
                                          student.id
                                        )}
                                        isDisabled={
                                          bundleWithSameStudents !== undefined
                                        }
                                        size="lg"
                                        style={{
                                          cursor:
                                            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                                        }}
                                      />
                                    </div>
                                  </div>
                                </MenuItem>
                              );
                            })}
                          </MenuGroup>
                        </MenuList>
                      </Menu>
                    </div>

                    <Flex
                      width="100%"
                      height="100%"
                      position={"relative"}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="text_black_very_small">{t("from")}</div>
                      <DatePicker
                        className="scenario_menu_date_picker"
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={(date: Date) => handleStartDateChange(date)}
                        shouldCloseOnSelect
                        useWeekdaysShort
                        locale={i18n.language}
                      />
                      <div className="text_black_very_small">{t("to")}</div>
                      <DatePicker
                        className="scenario_menu_date_picker"
                        dateFormat="dd/MM/yyyy"
                        selected={endDate}
                        onChange={(date: Date) => handleEndDateChange(date)}
                        shouldCloseOnSelect
                        useWeekdaysShort
                        locale={i18n.language}
                      />
                    </Flex>

                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ gap: "2vh" }}
                    >
                      <div className="text_black_very_small">
                        {t("hide_bundle_to_students")}
                      </div>
                      <Tooltip
                        hasArrow
                        label={t("switch_hide_bundle_tooltip")}
                        bg={"#7D7D7D"}
                        placement="top"
                        sx={{
                          fontFamily: "Causten",
                          fontSize: "calc(5px + 0.5vw)",
                          borderRadius: "5px",
                        }}
                        aria-label="switch Tooltip"
                      >
                        <InfoOutlineIcon w={"3vh"} color={"#B8B8B8"} />
                      </Tooltip>
                      <Switch
                        id="visibility-switch"
                        size="lg"
                        sx={{
                          cursor:
                            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                          ".chakra-switch__thumb": {
                            backgroundColor: isHidden ? "#5090F0" : "#B8B8B8",
                            cursor:
                              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                          },
                          ".chakra-switch__track": {
                            backgroundColor: isHidden ? "#E1EDFF" : "#EEEEEE",
                            cursor:
                              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                          },
                          "span > span:first-child": {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          },
                          "span > span:first-child::after": {
                            content: '""',
                            position: "absolute",
                            width: "2vh",
                            height: "2vh",
                            backgroundImage: `url(${
                              isHidden
                                ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/PruebaIcons/ViewIconOff.png"
                                : "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/PruebaIcons/ViewIcon.png"
                            })`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                          },
                        }}
                        isChecked={isHidden}
                        onChange={(e) => setIsHidden(e.target.checked)}
                        colorScheme={isHidden ? "blue" : "gray"}
                      />
                    </Box>
                  </div>
                </div>
                <div
                  className="professor_sidebar_delete_menu_close"
                  onClick={() => popUpAddBundleHandler()}
                >
                  <m.img
                    className="icon"
                    src={close}
                    alt="close"
                    whileHover={{ scale: 1.1, rotate: 180 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    style={{ maxHeight: "6vh" }}
                  />
                </div>

                <div className="center_container">
                  <m.div
                    className="scenario_pop_up_button"
                    whileHover={{ scale: 1.1 }}
                    onClick={
                      bundle === null
                        ? () => saveBundleHandler()
                        : () => confirmChangesHandler(bundle)
                    }
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_very_small_bold">
                        {t("assign_situation")}
                      </div>
                    </div>
                  </m.div>
                </div>
              </div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  const renderConfirmChanges = (bundle: BundleType) => {
    return (
      <div className="professor_sidebar_delete_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="center_container">
            <div className="professor_sidebar_delete_menu_container">
              <div className="professor_sidebar_delete_menu">
                <div className="professor_sidebar_delete_menu_text">
                  <div className="center_container">
                    <div className="text_black_medium">
                      {t("modify_bundle_confirm")}
                    </div>
                  </div>
                </div>
                <div
                  className="professor_sidebar_delete_menu_close"
                  onClick={() => confirmChangesHandler()}
                >
                  <m.img
                    className="icon"
                    src={close}
                    alt="close"
                    whileHover={{ scale: 1.1, rotate: 180 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  />
                </div>

                <div className="professor_sidebar_delete_menu_buttons">
                  <m.div
                    className="professor_sidebar_delete_menu_button_yes"
                    whileHover={{ scale: 1.05 }}
                    onClick={() => saveBundleHandler()}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("yes")}</div>
                    </div>
                  </m.div>

                  <m.div
                    className="professor_sidebar_delete_menu_button_no"
                    whileHover={{ scale: 1.05 }}
                    onClick={() => confirmChangesHandler()}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("no")}</div>
                    </div>
                  </m.div>
                </div>
              </div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  const renderDeleteMenu = (bundle: BundleType) => {
    return (
      <div className="professor_sidebar_delete_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="center_container">
            <div className="professor_sidebar_delete_menu_container">
              <div className="professor_sidebar_delete_menu">
                <div className="professor_sidebar_delete_menu_text">
                  <div className="center_container">
                    <div className="text_black_medium">
                      {t("delete_bundle_confirm")}
                    </div>
                  </div>
                </div>
                <div
                  className="professor_sidebar_delete_menu_close"
                  onClick={() => deleteMenuHandler()}
                >
                  <m.img
                    className="icon"
                    src={close}
                    alt="close"
                    whileHover={{ scale: 1.1, rotate: 180 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  />
                </div>

                <div className="professor_sidebar_delete_menu_buttons">
                  <m.div
                    className="professor_sidebar_delete_menu_button_yes"
                    whileHover={{ scale: 1.05 }}
                    onClick={() => deleteBundleHandler()}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("yes")}</div>
                    </div>
                  </m.div>

                  <m.div
                    className="professor_sidebar_delete_menu_button_no"
                    whileHover={{ scale: 1.05 }}
                    onClick={() => deleteMenuHandler()}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <div className="text_white_small_bold">{t("no")}</div>
                    </div>
                  </m.div>
                </div>
              </div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  const renderDuplicateScenario = () => {
    return (
      <div className="professor_sidebar_delete_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="center_container">
            <div className="scenario_back_duplicate_menu_container">
              <div className="professor_sidebar_delete_menu">
                <div className="professor_sidebar_delete_menu_text">
                  <div className="scenario_duplicate_menu_main_text">
                    <div className="text_black_small_bold">
                      {t("duplicate_to_edit")}
                    </div>

                    <div className="text_black_small">
                      {t("duplicate_to_edit_subtext")}
                    </div>
                  </div>
                </div>
                <div
                  className="professor_sidebar_delete_menu_close"
                  onClick={() => setDuplicateMenu(null)}
                >
                  <m.img
                    className="icon"
                    src={close}
                    alt="close"
                    whileHover={{ scale: 1.1, rotate: 180 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    style={{ maxHeight: "6vh" }}
                  />
                </div>

                <div className="scenario_duplicate_menu_buttons">
                  <m.div
                    className="scenario_duplicate_menu_button_cancel"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => setDuplicateMenu(null)}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <BackCross color={"#FFFFFF"} size={"2.5vh"} />
                      <div className="text_white_very_small_bold">
                        {t("cancel")}
                      </div>
                    </div>
                  </m.div>
                  <m.div
                    className="scenario_duplicate_menu_button_duplicate"
                    whileHover={{ scale: 1.1 }}
                    onClick={() => cloneScenarioHandler(scenario.id)}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  >
                    <div className="center_container">
                      <img
                        className="image"
                        src={tick}
                        alt="tick"
                        style={{
                          width: "1.5em",
                          height: "1.5em",
                        }}
                      />
                      <div className="text_white_very_small_bold">
                        {t("duplicate")}
                      </div>
                    </div>
                  </m.div>
                </div>
              </div>
            </div>
          </div>
        </LazyMotion>
      </div>
    );
  };

  const isSDAComplete = (showToast: boolean = false) => {
    if (research === null || experiment === null) {
      return false;
    }
    const hasCompleteResearchExercise = research.exercises.length > 0; // Goofy ahh but talk to Joan if disagree

    const hasAtLeastFourExercises = experiment.exercises.length >= 4;

    const hasCompleteExperimentExercise = experiment.exercises.every(
      (exercise) => isExerciseComplete(exercise) === "true"
    );
    if (!hasCompleteResearchExercise && showToast) {
      toast({
        title: t("atention"),
        description: t("empty_research_toast"),
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }

    if (!hasAtLeastFourExercises && showToast) {
      toast({
        title: t("atention"),
        description: t("empty_experiment_toast"),
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }

    if (!hasCompleteExperimentExercise && showToast) {
      toast({
        title: t("atention"),
        description: t("incomplete_experiment_toast"),
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }

    return (
      hasCompleteResearchExercise &&
      hasAtLeastFourExercises &&
      hasCompleteExperimentExercise
    );
  };

  const renderButton = (previous: boolean) => {
    const isBundle = bundle?.students !== undefined;
    const maxPage = isBundle ? 3 : 2;

    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          className="professor_scenario_menu_button"
          onClick={() => {
            if (previous) {
              if (page > 0) {
                setPage(page - 1);
              }
            } else {
              if (page < maxPage) {
                setPage(page + 1);
              } else if (page === maxPage) {
                if (bundle !== null) {
                  return;
                }
                isSDAComplete(true) && popUpAddBundleHandler();
              }
            }
          }}
          style={{
            backgroundColor: previous
              ? page === 0
                ? "#C8C8C8"
                : "#5090F0"
              : page === maxPage
              ? bundle !== null
                ? "#C8C8C8"
                : isSDAComplete()
                ? "#6ee6a7"
                : "#C8C8C8"
              : "#5090F0",
            boxShadow: !previous
              ? page === maxPage
                ? bundle !== null
                  ? "none"
                  : isSDAComplete()
                  ? "0px 0px 10px 0px #6ee6a7"
                  : "none"
                : "0px 0px 10px 0px #5090F0"
              : "none",
            cursor:
              (page === 0 && previous) ||
              (page === maxPage && !isSDAComplete()) ||
              (page === maxPage && bundle !== null)
                ? 'url("http://api.elemer.es/cursorscaler/?size=24&type=normal"), auto'
                : 'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
          whileHover={{
            scale:
              (page === 0 && previous) ||
              (page === maxPage && !isSDAComplete()) ||
              (page === maxPage && bundle !== null)
                ? 1
                : 1.1,
          }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <div className="center_container">
            <div className="text_white_small_bold">
              {previous
                ? t("previous")
                : page === maxPage
                ? t("program")
                : t("next")}
            </div>
          </div>
        </m.div>
      </LazyMotion>
    );
  };

  const renderBreadcrumbs = () => {
    const isBundle = bundle?.students !== undefined;

    const breadcrumbTooltips = isBundle
      ? [
          t("SDA_bundle_title"),
          t("SDA_top_title_1"),
          t("SDA_top_title_2"),
          t("SDA_top_title_3"),
        ]
      : [t("SDA_top_title_1"), t("SDA_top_title_2"), t("SDA_top_title_3")];

    return (
      <div className="professor_scenario_menu_breadcrumbs">
        {breadcrumbTooltips.map((tooltipText, index) => {
          return (
            <Tooltip
              key={index}
              label={tooltipText}
              placement="top"
              bg={"#7D7D7D"}
              sx={{
                fontFamily: "Causten",
                fontSize: "calc(5px + 0.5vw)",
                borderRadius: "5px",
              }}
              hasArrow
            >
              <motion.div
                key={index}
                className={
                  index === page
                    ? "professor_scenario_menu_breadcrumb_active"
                    : "professor_scenario_menu_breadcrumb_inactive"
                }
                onClick={() => setPage(index)}
                initial={{
                  scale: index === page ? 0 : 1,
                  backgroundColor: "#FFFFFF",
                  border: index === page ? "none" : "3px solid #EEEEEE",
                }}
                animate={{
                  scale: 1,
                  backgroundColor: index === page ? "#5090F0" : "#FFFFFF",
                  border: index === page ? "none" : "3px solid #EEEEEE",
                }}
                transition={{ duration: index === page ? 1.2 : 0.1 }}
              ></motion.div>
            </Tooltip>
          );
        })}
      </div>
    );
  };

  const renderEditButton = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          className="professor_scenario_menu_edit_button"
          onClick={() => editScenarioHandler()}
          whileHover={{
            scale: 1.1,
          }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <img
            className="image"
            src={editIcon}
            alt="edit_icon"
            style={{
              borderRadius: "10px",
              maxHeight: "80px",
            }}
          />
        </m.div>
      </LazyMotion>
    );
  };

  const renderTop = () => {
    const isBundle = bundle?.students !== undefined;
    const maxPage = isBundle ? 3 : 2;
    return (
      <LazyMotion features={loadFeatures}>
        <div className="professor_scenario_menu_top">
          <m.div
            className="professor_scenario_menu_top_title"
            whileHover={{
              scale: 1.02,
              textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
            onClick={() => {
              dispatch(updateSelectedBundle(null));

              switch (scenarioMenuParent) {
                case "marketplace":
                  dispatch(submenu("marketplace"));
                  break;
                case "my_scenarios":
                  dispatch(submenu("my_scenarios"));
                  break;
                case "activities":
                  dispatch(menu("menus"));
                  dispatch(submenu("activities"));
                  break;
              }
            }}
          >
            {<BackArrow color={"#000000"} size={"6vh"} />}
            <div className="professor_scenario_menu_top_title_pages">
              <div className="text_black_medium_bold">
                {page + 1 + "/" + (maxPage + 1)}
              </div>
            </div>
            <div className="text_black_medium_bold">
              {isBundle && page === 0
                ? t("SDA_bundle_title")
                : page === (isBundle ? 1 : 0)
                ? t("SDA_top_title_1")
                : page === (isBundle ? 2 : 1)
                ? t("SDA_top_title_2")
                : t("SDA_top_title_3")}
            </div>
          </m.div>
          {bundle?.students === undefined && renderEditButton()}
        </div>
      </LazyMotion>
    );
  };

  const renderSDA = () => {
    return (
      <div className="professor_scenario_menu_main_page_1_bottom_grid">
        <div className="text_black_small_bold">{t("sda") + ":"}</div>
        <div className="text_black_small_bold">
          {t("basic_knowledge") + ":"}
        </div>
        <div className="professor_scenario_menu_main_page_1_bottom_left">
          <img
            className="image"
            src={scenario.image.url}
            alt="scenario_image"
            style={{
              borderRadius: "10px",
              maxHeight: "14vh",
            }}
          />
          <div className="professor_scenario_menu_main_page_1_bottom_left_info">
            <div className="text_black_very_small">
              <b>{scenario.name}</b>
            </div>
            <div className="professor_scenario_menu_main_page_1_bottom_left_info_desc">
              <div
                className="text_black_very_small"
                style={{ marginTop: "0.5em" }}
              >
                {scenario.description}
              </div>
            </div>
          </div>
        </div>
        <div className="professor_scenario_menu_main_page_1_bottom_right">
          {[scenario.main_know_how, ...scenario.other_know_hows].map(
            (knowHow, index) => (
              <div
                key={index}
                className="professor_scenario_menu_main_page_1_know_how_badge"
              >
                <div className="text_black_super_small">{knowHow}</div>
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const renderPage1 = () => {
    return (
      <div
        className="professor_scenario_menu_main_page_1"
        style={{ top: "10%", height: "80%" }}
      >
        <div className="professor_scenario_menu_main_page_1_top">
          <div
            className="professor_scenario_menu_main_page_1_top_banner"
            style={{
              backgroundImage: `linear-gradient(to left, rgba(128, 128, 128, 0.9), rgba(128, 128, 128, 0) 70%), url(${scenario.banner.url})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="text_white_very_small">
              {" "}
              {t("created_by") + " "}
              <b>{scenario.owner?.fullname}</b>
            </div>
          </div>
        </div>

        <div className="professor_scenario_menu_main_page_1_middle">
          <div className="text_black_medium_bold">{scenario.main_know_how}</div>

          <div className="professor_scenario_menu_main_page_1_top_badges">
            <div
              className="professor_scenario_menu_main_page_1_top_course"
              style={{ background: scenarioSubject?.color }}
            >
              <div className="center_container">
                <div className="text_white_very_small">
                  {t(scenarioSubject?.acronym + "_short", { ns: "evaluation" })}
                </div>
              </div>
            </div>

            <div className="professor_scenario_menu_main_page_1_top_regulation">
              <div className="center_container">
                <div className="text_white_very_small">
                  {t(scenarioRegulation?.name || "", { ns: "evaluation" })}
                </div>
              </div>
            </div>

            <div className="professor_scenario_menu_main_page_1_top_lang">
              <div className="center_container">
                <div className="text_white_very_small">
                  {t(chooseLanguageFromDB(scenario.language))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="professor_scenario_menu_main_page_1_bottom">
          {renderSDA()}
        </div>
      </div>
    );
  };

  const renderPage2 = () => {
    return <ExerciseVisualizer />;
  };

  const renderPage3 = () => {
    const selectedSpecificCompetences = specificCompetencesBooleanArray(
      scenario.specific_competences_ids,
      specificCompetences
    );

    const selectedKeyCompetences = globalCompetencesBooleanArray(
      scenario.global_competences_ids,
      globalCompetences
    );
    return (
      <div className="professor_scenario_menu_main_page_3">
        <div className="professor_scenario_menu_main_page_3_top">
          {evaluationCriteria.map((evaluationCriterion) => {
            const isSelected = scenario.evaluation_criteria_ids.includes(
              evaluationCriterion.id
            );

            const specificCompetence = specificCompetences.find(
              (speComp) =>
                speComp.id === evaluationCriterion.specific_competence_id
            );

            return (
              <div
                className="professor_scenario_menu_main_page_3_top_badge"
                style={{
                  backgroundColor: isSelected ? "#8DBCFF" : "#D9D9D980",
                }}
              >
                <div
                  key={evaluationCriterion.id}
                  className={
                    isSelected
                      ? "text_black_super_small_bold"
                      : "text_black_super_small"
                  }
                >
                  <b>
                    {prettifyCourseAcronyms(scenarioSubject?.acronym) ||
                      "" + specificCompetence?.number ||
                      0 + "." + evaluationCriterion?.number ||
                      0 + ": "}
                  </b>
                  {t(
                    (scenarioSubject?.acronym || "") +
                      (specificCompetence?.number || 0) +
                      "." +
                      (evaluationCriterion?.number || 0) +
                      "_desc",
                    { ns: "evaluation" }
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="professor_scenario_menu_main_page_3_bottom">
          <div className="professor_scenario_menu_main_page_3_bottom_container">
            <div className="text_black_small_bold">
              {t("specific_competences") + ":"}
            </div>
            <div
              className="professor_scenario_menu_main_page_3_bottom_spec_comp"
              style={{
                gridTemplateColumns:
                  "repeat(" + selectedSpecificCompetences.length + ", 1fr)",
              }}
            >
              {selectedSpecificCompetences.map(
                (competence: boolean, index: number) => {
                  return (
                    <div
                      className="text_white_super_small"
                      style={{
                        backgroundColor: competence
                          ? "rgba(41, 47, 76, 1)"
                          : "rgba(41, 47, 76, 0.25)",
                        borderRadius: "100px",
                        maxHeight: "1.3rem",
                        color: "white",
                        padding: "0.1rem 0.5rem 0rem 0.5rem",
                        textAlign: "center",
                        marginRight: "0.5rem",
                      }}
                    >
                      {prettifyCourseAcronyms(scenarioSubject?.acronym) +
                        (index + 1).toString()}
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className="professor_scenario_menu_main_page_3_bottom_container">
            <div className="text_black_small_bold">
              {t("key_competences") + ":"}
            </div>
            <div
              className="professor_scenario_menu_main_page_3_bottom_key_comp"
              style={{
                gridTemplateColumns:
                  "repeat(" + simpleGlobalCompetences.length + ", 1fr)",
              }}
            >
              {simpleGlobalCompetences.map((competence, index) => {
                const nonSelectedColor =
                  competence[1].substr(0, competence[1].length - 2) + "0.25)";
                return (
                  <div
                    className="text_white_super_small"
                    style={{
                      backgroundColor: selectedKeyCompetences[index]
                        ? competence[1]
                        : nonSelectedColor,
                      borderRadius: "100px",
                      maxHeight: "1.3rem",
                      color: "white",
                      padding: "0.1rem 0.5rem 0rem 0.5rem",
                      textAlign: "center",
                      marginRight: "0.5rem",
                    }}
                  >
                    {competence[0]}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderProgramPage = () => {
    return (
      <div className="professor_scenario_menu_main_page_program">
        <div className="professor_scenario_menu_main_page_program_left">
          <div>
            <div className="text_black_medium_bold">
              {scenario.main_know_how}
            </div>
            <Divider />
          </div>
          <div>
            <div className="text_black_small_bold">{t("program_dates")}</div>
            <Flex
              width="100%"
              position={"relative"}
              alignItems={"center"}
              gap={"2%"}
              marginTop={"1vh"}
            >
              <div className="text_black_very_small">{t("from")}</div>
              <Spacer />
              <DatePicker
                className="scenario_menu_date_picker"
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date: Date) => handleStartDateChange(date)}
                shouldCloseOnSelect
                useWeekdaysShort
                locale={i18n.language}
              />
              <Spacer />
              <div className="text_black_very_small">{t("to")}</div>
              <Spacer />
              <DatePicker
                className="scenario_menu_date_picker"
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(date: Date) => handleEndDateChange(date)}
                shouldCloseOnSelect
                useWeekdaysShort
                locale={i18n.language}
              />
            </Flex>
          </div>
          <div className="professor_scenario_menu_main_page_program_left_hide_bundle">
            <div className="text_black_small_bold"> {t("hide_bundle")} </div>
            <Box display="flex" alignItems="center">
              <Switch
                id="visibility-switch"
                size="lg"
                sx={{
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  ".chakra-switch__thumb": {
                    backgroundColor: isHidden ? "#5090F0" : "#B8B8B8",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  },
                  ".chakra-switch__track": {
                    backgroundColor: isHidden ? "#E1EDFF" : "#EEEEEE",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  },
                  "span > span:first-child": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                  "span > span:first-child::after": {
                    content: '""',
                    position: "absolute",
                    width: "2vh",
                    height: "2vh",
                    backgroundImage: `url(${
                      isHidden
                        ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/PruebaIcons/ViewIconOff.png"
                        : "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/PruebaIcons/ViewIcon.png"
                    })`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  },
                }}
                isChecked={isHidden}
                onChange={(e) => setIsHidden(e.target.checked)}
                colorScheme={isHidden ? "blue" : "gray"}
              />
              {/* <Icon
                as={isHidden ? ViewOffIcon : ViewIcon}
                position="absolute"
                top="50%"
                transform="translateY(-50%)"
                left={isHidden ? '0.01rem' : '50rem'}
                boxSize={4}
                color={isHidden ? "blue" : "gray"}
                pointerEvents="none"
              /> */}
            </Box>
          </div>
        </div>
        {renderGroupsData()}
      </div>
    );
  };

  const renderMain = () => {
    const isBundle = bundle?.students !== undefined;

    return (
      <div className="professor_scenario_menu_main">
        {isBundle && page === 0 && renderProgramPage()}
        {page === (isBundle ? 1 : 0) && renderPage1()}
        {page === (isBundle ? 2 : 1) && renderPage2()}
        {page === (isBundle ? 3 : 2) && renderPage3()}
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <LazyMotion features={loadFeatures}>
        <div
          className="professor_scenario_menu_bottom"
          style={{
            justifyContent: bundle !== null ? "space-between" : "center",
          }}
        >
          {bundle !== null && (
            <m.div
              className="professor_scenario_main_page_program_button_delete"
              whileHover={{
                scale: isToastDisabled ? 1 : 1.1,
                boxShadow: isToastDisabled
                  ? "#B8B8B8 0px 0px 10px 2px"
                  : "#FF8787 0px 0px 10px 1px ",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              onClick={() => deleteMenuHandler(bundle)}
            >
              <div className="text_white_small_bold">
                {t("withdraw_programming")}
              </div>
            </m.div>
          )}
          <div className="professor_scenario_menu_bottom_center">
            {renderButton(true)}
            {renderBreadcrumbs()}
            {renderButton(false)}
          </div>
          {bundle !== null && (
            <m.div
              className="professor_scenario_main_page_program_button_save"
              whileHover={{
                scale: isToastDisabled ? 1 : 1.1,
                boxShadow: isToastDisabled
                  ? "#B8B8B8 0px 0px 10px 2px"
                  : "#6EE6A7 0px 0px 10px 2px",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              onClick={() => confirmChangesHandler(bundle)}
            >
              <div className="text_white_small_bold">{t("save_changes")}</div>
            </m.div>
          )}
        </div>
      </LazyMotion>
    );
  };

  const renderGroupsData = () => {
    const activeStudents = bundle?.students?.filter(
      (student) => student.inactive === null
    );
    const activitiesDone = Object.values(scenarioPoints).filter(
      (points) => points > 0
    )?.length;
    return (
      <LazyMotion features={loadFeatures}>
        <div className="professor_scenario_menu_main_page_program_right">
          <div className="professor_scenario_menu_main_page_program_groups">
            <div className="text_black_small_bold">{t("groups")}</div>
            <Divider />

            {groups.map((group) => {
              const groupStudentIds = group.students.map(
                (student) => student.id
              );

              let bundleWithSameStudents: BundleType | undefined = undefined;
              if (allBundlesofScenario !== null) {
                bundleWithSameStudents = allBundlesofScenario.find((bun) => {
                  if (bun.id === bundle?.id) {
                    return false;
                  }
                  const bundleStudentIds = bun.students?.map(
                    (student) => student.id
                  );
                  if (!bundleStudentIds) return false;
                  return arraysEqual(bundleStudentIds, groupStudentIds);
                });
              }

              return (
                <m.div
                  key={group.id}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  onClick={
                    bundleWithSameStudents !== undefined
                      ? () => {}
                      : (e) => groupHandler(e, group)
                  }
                  className="professor_scenario_menu_main_page_program_group_item"
                  whileHover={{
                    boxShadow: "0px 0px 10px 1px #BCD1EF",
                    backgroundColor: "#BCD1EF50",
                  }}
                >
                  <div className="professor_scenario_menu_main_page_program_group_item_icon_name">
                    <GroupAddButton
                      color={group.id === "group1" ? "#6EE6A7" : "#5090F0"}
                      size={"80%"}
                    />
                    <div className="text_black_very_small">{t(group.name)}</div>
                  </div>
                  <Tooltip
                    hasArrow
                    label={
                      bundleWithSameStudents !== undefined
                        ? t("group_assigned")
                        : undefined
                    }
                    bg={"#7D7D7D"}
                    placement="top"
                    sx={{
                      fontFamily: "Causten",
                      fontSize: "calc(5px + 0.5vw)",
                      borderRadius: "5px",
                    }}
                    shouldWrapChildren
                  >
                    <Checkbox
                      isChecked={selectedGroup === group}
                      isDisabled={bundleWithSameStudents !== undefined}
                      size="lg"
                      style={{
                        cursor:
                          'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                      }}
                      onChange={
                        bundleWithSameStudents !== undefined
                          ? () => {}
                          : (e) => groupHandler(e, group)
                      }
                    />
                  </Tooltip>
                </m.div>
              );
            })}
          </div>
          <div className="professor_scenario_menu_main_page_program_groups">
            <div className="professor_scenario_menu_main_page_program_students_header">
              <div className="text_black_small_bold">{t("students")}</div>

              <div className="professor_scenario_menu_main_page_program_students_header_data">
                <div className="text_black_very_small">{t("programmed")}</div>
                <div className="text_black_small_bold">
                  {loadingBundles ? (
                    <Spinner size="sm" />
                  ) : (
                    activeStudents?.length
                  )}
                </div>
              </div>

              <div className="professor_scenario_menu_main_page_program_students_header_data">
                <div className="text_black_very_small">{t("completed")}</div>
                <div
                  className="text_black_small_bold"
                  style={{ margin: 0, padding: 0 }}
                >
                  {loadingScenario ? <Spinner size="sm" /> : activitiesDone}
                </div>
              </div>
            </div>
            <Divider />

            {students.map((student) => {
              let bundleWithSameStudents: BundleType | undefined = undefined;

              if (allBundlesofScenario !== null) {
                bundleWithSameStudents = allBundlesofScenario.find((bun) => {
                  if (bun.id === bundle?.id) {
                    return false;
                  }
                  const bundleStudentIds = bun.students?.map(
                    (student) => student.id
                  );
                  if (!bundleStudentIds) return false;
                  return arraysEqual(bundleStudentIds, selectedStudents);
                });
              }
              return (
                <m.div
                  // value={student.id}
                  key={student.id}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  onClick={
                    bundleWithSameStudents !== undefined
                      ? () => {}
                      : (e) => studentHandler(e, student)
                  }
                  className="professor_scenario_menu_main_page_program_group_item"
                  whileHover={{
                    boxShadow: "0px 0px 10px 1px #BCD1EF",
                    backgroundColor: "#BCD1EF50",
                  }}
                >
                  <div className="professor_scenario_menu_main_page_program_group_item_icon_name">
                    <img
                      src={OnePersonIcon}
                      alt="person"
                      style={{ height: "80%" }}
                    />
                    <div className="text_black_very_small">
                      {student.username}
                    </div>
                  </div>
                  <Checkbox
                    onChange={
                      bundleWithSameStudents !== undefined
                        ? () => {}
                        : (e) => studentHandler(e, student)
                    }
                    isChecked={selectedStudents.includes(student.id)}
                    isDisabled={bundleWithSameStudents !== undefined}
                    size="lg"
                    style={{
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                  />
                  <div className="text_black_small_bold center_container">
                    {scenarioPoints[student.id] !== undefined
                      ? (scenarioPoints[student.id] || 0) + "/100"
                      : "N/A"}
                  </div>
                </m.div>
              );
            })}
          </div>
        </div>
      </LazyMotion>
    );
  };

  return (
    <div className={loading ? "center_container" : "professor_scenario_menu"}>
      {loading && <Loading />}
      {!loading && renderTop()}

      {!loading && renderMain()}
      {!loading && renderBottom()}

      {!loading && popUpAddBundle && renderPopUpAddBundle()}
      {!loading && !_.isNull(duplicateMenu) && renderDuplicateScenario()}
      {!loading && !_.isNull(deleteMenu) && renderDeleteMenu(deleteMenu)}
      {!loading &&
        !_.isNull(changeBundle) &&
        renderConfirmChanges(changeBundle)}

      {!loading && alertScenarioAlreadyCopied === true && (
        <Alert
          handleAlert={() => alertScenarioCopiedHandler()}
          text={t("scenario_copied_error")}
        />
      )}
      {!loading && dateError !== null && (
        <Alert text={dateError} handleAlert={() => dateErrorHandler()} />
      )}
    </div>
  );
};

export default ScenarioMenu;
