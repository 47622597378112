import "./Theory.css";
import blueBackground from "../../../assets/exercises/bluebackgroundexercise.svg";
import { Idirection, IscreenSize } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { Arrows, ExerciseName, Professor, TextBox } from "./_components";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/hooks";

const Theory = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  maxExercise: number;
  exerciseIndex: number;
  research: boolean;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
}) => {
  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const isTeacher = useAppSelector((state) => state.login).type === "teacher";

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const text = props.exercise.text;
  const totalExercises = props.totalExercises;
  const research = props.research;
  const selectedExercise = props.exerciseIndex;
  const maxExercise = props.maxExercise;

  return (
    <div className="theory">
      <img className="background" src={blueBackground} alt="background" />
      <Professor width={screenSize.dynamicWidth} />
      <TextBox text={text} contractTextBox={false} />
      <Arrows
        selectedExercise={selectedExercise}
        maxExercise={maxExercise}
        updateLevel={props.updateLevel}
        research={isTeacher ? true : research}
        allowNext={selectedExercise < totalExercises - 1}
      />
      <ExerciseName
        type={props.exercise.type}
        smallMargin={false}
        imageExists={false}
      />
    </div>
  );
};

export default Theory;
