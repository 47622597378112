import "./Media.css";
import { useState, useEffect } from "react";
import blueBackground from "../../../assets/exercises/bluebackgroundexercise.svg";
import { Idirection, IscreenSize } from "../../../interfaces";
import ReactPlayer from "react-player";
import { ExerciseType } from "../../../_newapios/content/unit";
import { Arrows, ExerciseName } from "./_components";
import { useAppSelector } from "../../../hooks/hooks";

const Media = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  maxExercise: number;
  exerciseIndex: number;
  research: boolean;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
}) => {
  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const isTeacher = useAppSelector((state) => state.login).type === "teacher";

  const text = props.exercise.text;
  const maxExercise = props.maxExercise;
  const totalExercises = props.totalExercises;
  const selectedExercise = props.exerciseIndex;

  const [research, setResearch] = useState(props.research);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    setResearch(props.research);
  }, [props.research]);

  const renderMedia = () => {
    console.log(text);
    return (
      <div className="media_text_box">
        <div className="center_container">
          <ReactPlayer
            width={"100%"}
            height={"100%"}
            url={text}
            playing={true}
            controls={true}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="media">
      <img className="background" src={blueBackground} alt="background" />
      {renderMedia()}
      <Arrows
        selectedExercise={selectedExercise}
        maxExercise={maxExercise}
        updateLevel={props.updateLevel}
        research={isTeacher ? true : research}
        allowNext={selectedExercise < totalExercises - 1}
      />
      <ExerciseName
        type={props.exercise.type}
        smallMargin={true}
        imageExists={true}
      />
    </div>
  );
};

export default Media;
