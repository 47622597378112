import { useTranslation } from "react-i18next";
import "./TeacherTutorialScenarioUpdate.css";
import { Button } from "@chakra-ui/react";
import { LazyMotion, m } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks.ts";
import { setTeacherTutorial } from "../../../reducers/teacherSlice.ts";
import { FinishTutorialPartAPI } from "../../../_newapios/user/teacher.ts";
import { renderProgressBar } from "./TeacherTutorial1.tsx";

const TeacherTutorialScenarioUpdate2 = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);
  const teacherState = useAppSelector((state) => state.teacher);

  const handleNext = async () => {
    await FinishTutorialPartAPI("update_2");
    dispatch(setTeacherTutorial(null));
  };

  const renderTutorialUpdateEdit = () => {
    return (
      <div className="teacher_tutorial_1_welcome_background">
        <div className="center_container">
          <LazyMotion features={loadFeatures}>
            <div className="teacher_tutorial_1_welcome_container">
              <div className="text_black_medium_bold">
                {t("tutorial_update_2_title")}
              </div>
              <div className="text_black_small">{t("tutorial_update_2_1")}</div>
              <div className="text_black_small">{t("tutorial_update_2_2")}</div>
              <Button
                className="teacher_tutorial_1_welcome_button"
                as={m.button}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "0px 0px 8px 0px #5090F0",
                }}
                _hover={{ backgroundColor: "#5090F0" }}
                backgroundColor={"#5090F0"}
                borderRadius={25}
                size={"lg"}
                onClick={() => handleNext()}
              >
                <div className="text_white_small">{t("understood")}</div>
              </Button>
            </div>
          </LazyMotion>
        </div>
      </div>
    );
  };

  return <>{renderTutorialUpdateEdit()}</>;
};

export default TeacherTutorialScenarioUpdate2;
