import { getBackendHost } from "../../utils/apiconnection";
import APIFetch from "../../utils/APIFetch";
import { ExperimentType } from "./experiment";
import { ResearchType } from "./research";
import { ExerciseType } from "./unit";

export const CreateExerciseAPI = async (
  language: string,
  type: string,
  priority_index: number,
  priority_index_array: number[],
  parent_id: string,
  is_research: boolean
): Promise<ResearchType | ExperimentType> => {
  // var req = await fetch(getBackendHost() + "/exercise/", {
  //     headers: {
  //         'Content-Type': 'application/json',
  //         'Accept': 'application/json'
  //     },
  //     "method": "POST",
  //     "body": JSON.stringify({ language, type, priority_index, priority_index_array, research_id, experiment_id }),
  //     "mode": "cors",
  //     "credentials": "include"
  // });
  // var data = req.json()
  const data = APIFetch(getBackendHost() + "/exercise/", {
    method: "POST",
    body: JSON.stringify({
      language,
      type,
      priority_index,
      priority_index_array,
      parent_id,
      is_research,
    }),
  });
  return data;
};

export const UpdateExerciseAPI = async (
  exercise_id: string,
  exercise: ExerciseType
): Promise<ExerciseType> => {
  // var req = await fetch(getBackendHost() + "/exercise/" + exercise_id, {
  //     headers: {
  //         'Content-Type': 'application/json',
  //         'Accept': 'application/json'
  //     },
  //     "method": "POST",
  //     "body": JSON.stringify({ exercise }),
  //     "mode": "cors",
  //     "credentials": "include"
  // });
  // var data = req.json()
  const data = APIFetch(getBackendHost() + "/exercise/" + exercise_id, {
    method: "POST",
    body: JSON.stringify({ exercise }),
  });
  return data;
};

export const DeleteExerciseAPI = async (
  exercise_id: string,
  priority_index: number,
  parent_id: string,
  is_research: boolean
): Promise<ResearchType | ExperimentType> => {
  // var req = await fetch(getBackendHost() + "/exercise/" + exercise_id, {
  //     headers: {
  //         'Content-Type': 'application/json',
  //         'Accept': 'application/json'
  //     },
  //     "method": "DELETE",
  //     "mode": "cors",
  //     "body": JSON.stringify({ priority_index, research_id, experiment_id }),
  //     "credentials": "include"
  // });
  // var data = req.json()

  const data = APIFetch(getBackendHost() + "/exercise/" + exercise_id, {
    method: "DELETE",
    body: JSON.stringify({ priority_index, parent_id, is_research }),
  });

  return data;
};
