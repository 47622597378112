import "./exerciseSelectorPage.css";

import ChooseManyThumbnail from "../../../../../assets/exercises/thumbnails/ChooseMany.svg";
import ChooseOneThumbnail from "../../../../../assets/exercises/thumbnails/ChooseOne.svg";
import DefinitionThumbnail from "../../../../../assets/exercises/thumbnails/Definition.svg";
import TheoryThumbnail from "../../../../../assets/exercises/thumbnails/Theory.svg";
import DragDropListThumbnail from "../../../../../assets/exercises/thumbnails/DragDropList.svg";
import DragDropTwoListThumbnail from "../../../../../assets/exercises/thumbnails/DragDropTwoLists.svg";
import WriteAnswersThumbnail from "../../../../../assets/exercises/thumbnails/WriteAnswers.svg";
import TrueorFalseThumbnail from "../../../../../assets/exercises/thumbnails/TrueorFalse.svg";
import FillInTheGapsThumbnail from "../../../../../assets/exercises/thumbnails/FillInTheGaps.svg";
import DragDropOptionsThumbnail from "../../../../../assets/exercises/thumbnails/DragDropOptions.svg";
import DragDropArrowsThumbnail from "../../../../../assets/exercises/thumbnails/DragDropArrows.svg";
import MediaThumbnail from "../../../../../assets/exercises/thumbnails/Media.svg";

import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const ExerciseSelectorPage = ({ ...props }) => {
  const { t, i18n } = useTranslation();
  const renderExerciseTemplates = () => {
    // If we are in the experiment case, we don't show the definition and theory slices
    const exerciseTemplatesNames =
      props.type == "research"
        ? [
            "choosemany",
            "chooseone",
            "definition",
            "theory",
            "dragdroplist",
            "dragdroptwolists",
            "writeanswers",
            "truefalse",
            "fillinthegaps",
            "dragdropoptions",
            "dragdroparrows",
            "media",
          ].map((el) => el.toLowerCase())
        : [
            "choosemany",
            "chooseone",
            "dragdroplist",
            "dragdroptwolists",
            "writeanswers",
            "truefalse",
            "fillinthegaps",
            "dragdropoptions",
            "dragdroparrows",
          ].map((el) => el.toLowerCase());

    const exerciseTemplatesThumbnails =
      props.type == "research"
        ? [
            ChooseManyThumbnail,
            ChooseOneThumbnail,
            DefinitionThumbnail,
            TheoryThumbnail,
            DragDropListThumbnail,
            DragDropTwoListThumbnail,
            WriteAnswersThumbnail,
            TrueorFalseThumbnail,
            FillInTheGapsThumbnail,
            DragDropOptionsThumbnail,
            DragDropArrowsThumbnail,
            MediaThumbnail,
          ]
        : [
            ChooseManyThumbnail,
            ChooseOneThumbnail,
            DragDropListThumbnail,
            DragDropTwoListThumbnail,
            WriteAnswersThumbnail,
            TrueorFalseThumbnail,
            FillInTheGapsThumbnail,
            DragDropOptionsThumbnail,
            DragDropArrowsThumbnail,
          ];

    // for (let i=0; i<exerciseTemplatesNames.length; i++) {
    //     exerciseTypesJSX.push(
    //         <ExerciseTemplateThumbnail name={exerciseTemplatesNames[i]} img={exerciseTemplatesThumbnails[i]} updateScreen={(screenObj: any)=>props.updateScreen(screenObj)}/>
    //     )
    // }

    return (
      <div
        className="exercise_template_thumbnails_container"
        style={
          {
            // gridTemplateRows:
            //   "repeat(" + Math.ceil(exerciseTemplatesNames.length / 3) + ", 25%)",
          }
        }
      >
        {exerciseTemplatesNames.map((name, index) => {
          return (
            <motion.div
              className="exercise_template_thumbnail"
              onClick={() => props.updateScreen(name)}
              whileHover={{
                scale: 1.02,
                boxShadow: "0px 0px 10px 1px #5090F0",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              style={{
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
            >
              <div className="exercise_template_thumbnail_name">
                <div className="center_container">
                  <div className="text_black_very_small_bold">{t(name)}</div>
                </div>
              </div>
              <div className="exercise_template_thumbnail_image">
                <img src={exerciseTemplatesThumbnails[index]} />
              </div>
            </motion.div>
          );
        })}
      </div>
    );
  };

  // const renderExerciseTemplatesExperiment = () => {
  //   const exerciseTemplatesNames = [
  //     "choosemany",
  //     "chooseone",
  //     "dragdroplist",
  //     "dragdroptwolists",
  //     "writeanswers",
  //     "truefalse",
  //     "fillinthegaps",
  //     "dragdropoptions",
  //   ].map((el) => el.toLowerCase());
  //   const exerciseTemplatesThumbnails = [
  //     ChooseManyThumbnail,
  //     ChooseOneThumbnail,
  //     DragDropListThumbnail,
  //     DragDropTwoListThumbnail,
  //     WriteAnswersThumbnail,
  //     TrueorFalseThumbnail,
  //     FillInTheGapsThumbnail,
  //     DragDropOptionsThumbnail,
  //   ];
  //   // for (let i=0; i<exerciseTemplatesNames.length; i++) {
  //   //     exerciseTypesJSX.push(
  //   //         <ExerciseTemplateThumbnail name={exerciseTemplatesNames[i]} img={exerciseTemplatesThumbnails[i]} updateScreen={(screenObj: any)=>props.updateScreen(screenObj)}/>
  //   //     )
  //   // }
  //   return exerciseTemplatesNames.map((name, index) => {
  //     return (
  //       <motion.div
  //         className="exercise_template_thumbnail"
  //         onClick={() => props.updateScreen(name)}
  //         whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
  //         transition={{ type: "spring", stiffness: 400, damping: 10 }}
  //         style={{
  //           cursor:
  //             'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
  //         }}
  //       >
  //         <div className="exercise_template_thumbnail_name">
  //           <div className="center_container">
  //             <div className="text_black_small_bold">{t(name)}</div>
  //           </div>
  //         </div>
  //         <div className="exercise_template_thumbnail_image">
  //           <img src={exerciseTemplatesThumbnails[index]} />
  //         </div>
  //       </motion.div>
  //     );
  //   });
  // };

  return (
    <div className="main_index_container">{renderExerciseTemplates()}</div>
  );
};
export default ExerciseSelectorPage;
