import { getBackendHost } from "../../utils/apiconnection";
import APIFetch from "../../utils/APIFetch";
import { ResearchProgressType } from './student_scenario'

export const CreateResearchProgressAPI = async (scenario_id: string): Promise<ResearchProgressType> => {
    // var req = await fetch(getBackendHost() + "/student_research/" + scenario_id, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "POST",
    //     "mode": "cors",
    //     "credentials": "include"
    // });

    // var data = await req.json();
    const data = APIFetch(getBackendHost() + "/student_research/" + scenario_id, {
        method: "POST"
    });

    return data;
}