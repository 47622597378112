import "./DragDropList.css";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Arrows,
  Background,
  ConfirmButton,
  ExerciseName,
  HelpMenu,
  Professor,
  TextBox,
} from "./_components";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Idirection } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { PlayFunction } from "use-sound/dist/types";
import { useAppSelector } from "../../../hooks/hooks";

const DragDropList = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  research: boolean;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const isTeacher = useAppSelector((state) => state.login).type === "teacher";

  const text = props.exercise.text;
  const helpText = props.exercise.help_text;
  const options = props.exercise.options as string[];
  const labels = props.exercise.labels as string[];

  const [answers, setAnswers] = useState<string[]>([]);

  const [selectedExercise, setselectedExercise] = useState(props.exerciseIndex);
  const [maxExercise, setmaxExercise] = useState(props.maxExercise);

  const [research, setResearch] = useState(props.research);
  const [incorrectFeedback, setIncorrectFeedback] = useState(false);
  const [incorrectIndexes, setIncorrectIndexes] = useState<number[]>([]);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    orderOptions();
  }, [selectedExercise, maxExercise]);

  useEffect(() => {
    setselectedExercise(props.exerciseIndex);
    setmaxExercise(props.maxExercise);
  }, [props.exerciseIndex]);

  const orderOptions = () => {
    if (research) {
      if (selectedExercise < maxExercise) {
        setAnswers(options);
      } else {
        setAnswers(_.shuffle(options));
      }
    } else {
      if (isTeacher) {
        setAnswers(options);
      } else {
        setAnswers(_.shuffle(options));
      }
    }
  };

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const confirmHandler = () => {
    var correct = true;
    let wrongIndexes = [];
    for (var i = 0; i < options.length; i++) {
      if (options[i] !== answers[i]) {
        correct = false;
        wrongIndexes.push(i);
      }
    }
    if (research) {
      if (correct) {
        props.updateLevel("right", answers, true);
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        props.wrongAnswerSound();
        setIncorrectFeedback(true);
        setIncorrectIndexes(wrongIndexes);
      }
    } else {
      if (correct) {
        props.updateLevel("right", answers, true);
      } else {
        props.updateLevel("right", answers, false);
      }
    }
  };

  const updateOrder = (src: number, des: number) => {
    let newOrder = [...answers];

    //Remove item
    const [removed] = newOrder.splice(src, 1);
    //Add item to the column in the new position.
    newOrder.splice(des, 0, removed);

    setAnswers(newOrder);
  };

  const renderDragDrop = () => {
    return (
      <div className="drag_drop_list_background">
        <div className="drag_drop_list_background_components">
          <div className="drag_drop_list_top">
            <div className="center_container">
              {labels && labels[0] !== undefined && (
                <div
                  className={
                    isTeacher
                      ? labels[0].length < 20
                        ? "text_white_small_bold"
                        : "text_white_very_small_bold"
                      : labels[0].length < 20
                      ? "text_white_medium_bold"
                      : "text_white_small_bold"
                  }
                >
                  {labels[0]}
                </div>
              )}
            </div>
          </div>
          <div className="drag_drop_list_center">
            <DragDropContext
              onDragEnd={(param) => {
                const src = param.source.index;
                const des =
                  param.destination === undefined || param.destination === null
                    ? param.source.index
                    : param.destination.index;

                updateOrder(src, des);
              }}
            >
              <Droppable droppableId={"1"} key={"1"}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="drag_drop_list_droppable"
                  >
                    {answers.map((item, i) => (
                      <Draggable
                        key={"draggable-" + item + i}
                        draggableId={"draggable-" + item + i}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="drag_drop_list_draggable"
                            style={{
                              top: 7 + 5.5 * i + "%",
                              ...provided.draggableProps.style,

                              backgroundColor:
                                incorrectFeedback === true
                                  ? incorrectIndexes.includes(i)
                                    ? "#F6A495"
                                    : ""
                                  : "",
                            }}
                          >
                            <motion.div
                              className="center_container"
                              whileHover={{ scale: 1.04 }}
                              transition={{
                                type: "spring",
                                stiffness: 400,
                                damping: 10,
                              }}
                            >
                              <div
                                className={
                                  isTeacher
                                    ? item.length < 30
                                      ? "text_black_small"
                                      : "text_black_very_small"
                                    : item.length < 30
                                    ? "text_black_medium"
                                    : "text_black_small"
                                }
                              >
                                {item}
                              </div>
                            </motion.div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="drag_drop_list_bottom">
            <div className="center_container">
              {labels && labels[1] !== undefined && (
                <div
                  className={
                    isTeacher
                      ? labels[1].length < 20
                        ? "text_white_small_bold"
                        : "text_white_very_small_bold"
                      : labels[1].length < 20
                      ? "text_white_medium_bold"
                      : "text_white_small_bold"
                  }
                >
                  {labels[1]}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="drag_drop_list">
      <Background
        incorrectFeedback={incorrectFeedback}
        setIncorrectFeedback={setIncorrectFeedback}
      />
      <Professor width={screenSize.dynamicWidth} />
      <TextBox text={text} contractTextBox={true} />
      <Arrows
        selectedExercise={selectedExercise}
        maxExercise={maxExercise}
        updateLevel={props.updateLevel}
        research={isTeacher ? true : research}
      />
      <ConfirmButton confirmHandler={confirmHandler} />
      <HelpMenu helpText={helpText} tipHandler={props.tipHandler} />
      <ExerciseName
        type={props.exercise.type}
        smallMargin={true}
        imageExists={true}
      />
      {renderDragDrop()}
    </div>
  );
};

export default DragDropList;
