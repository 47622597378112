import { getBackendHost } from "../../utils/apiconnection";
import APIFetch from "../../utils/APIFetch.ts";
import { ClassType } from "./class.ts";
import { TeacherType } from "./teacher.ts";

export interface SchoolType {
  id: string;
  name: string;
  start_date: string;
  town: string | null;
  license_id: number | null;
  classes: ClassType[];
}

export interface BusinessSchoolType {
  id: string;
  name: string;
  pin: string;
  town: string | null;
  user_license: {
    license: {
      name: string;
    };
  }[];
  teachers: {
    id: string;
    back_office_logins: {
      timestamp: string;
    }[];
  }[];
}

export const GetMySchoolAPI = async (): Promise<SchoolType> => {
  var req = await fetch(getBackendHost() + "/school/mine", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
    mode: "cors",
    credentials: "include",
  });
  var data = await req.json();

  data.classes = data.classes || [];
  return data;
};

export const GetAllSchoolsAPI = async (): Promise<BusinessSchoolType[]> => {
  var req = await fetch(getBackendHost() + "/school/all", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
    mode: "cors",
    credentials: "include",
  });
  console.log(req);
  var data = await req.json();
  console.log(data);
  return data;
};

export const GetTeachersInSchoolAPI = async (): Promise<
  TeacherType[] | null
> => {
  // var req = await fetch(getBackendHost() + "/school/teachers", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "GET",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = await req.json();
  var data = APIFetch(getBackendHost() + "/school/teachers", {
    method: "GET",
  });

  data = data || null;
  return data;
};

export const CreateSchoolAPI = async (
  name: string,
  town: string
): Promise<number> => {
  var req = await fetch(getBackendHost() + "/school/", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({ name: name, town: town }),
  });
  var status = await req.status;

  return status;
};
