import "./MediaEdit.css";
import { useState, useEffect } from "react";
import { Input, Textarea } from "@chakra-ui/react";
import { motion } from "framer-motion";
import ReactPlayer from "react-player";
import videoIcon from "../../../../assets/exercises/video.svg";
import { t } from "i18next";
import { ExerciseType } from "../../../../_newapios/content/unit";
import _ from "lodash";

const MediaEdit = (props: {
  exercise: ExerciseType;
  selectedExercise: number;
  updateMedia: (newMedia: ExerciseType) => void;
}) => {
  const [text, setText] = useState(props.exercise.text);
  const [saveToDB, setSaveToDB] = useState(false);

  useEffect(() => {
    if (saveToDB) {
      var exercise = { ...props.exercise };
      exercise.text = text;
      props.updateMedia(exercise);
      setSaveToDB(false);
    }
  }, [saveToDB]);
  useEffect(() => {
    setText(props.exercise.text);
  }, [props.exercise.text, props.selectedExercise]);

  const textHandler = (newText: string) => {
    setText(newText);
  };

  const renderProfessor = () => {
    return (
      <div className="edit_media_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              window.innerWidth > 991
                ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_big.svg"
                : "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_cropped.svg"
            }
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <motion.div
        className="edit_media_text_box"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        <Input
          placeholder={t("media_content_placeholder")}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            textAlign: "center",
            fontFamily: "Causten",
            fontSize: "1em",
          }}
          value={text}
          resize={"none"}
          onChange={(e) => textHandler(e.target.value)}
          onBlur={() => setSaveToDB(true)}
        />
      </motion.div>
    );
  };

  const renderMedia = () => {
    return (
      <div className="edit_media_container">
        <div className="center_container">
          <div className="edit_media_bg">
            {text === "" ? (
              <div className="center_container">
                <img className="image" src={videoIcon} alt="video-icon" />
              </div>
            ) : (
              <ReactPlayer
                width={"100%"}
                height={"100%"}
                url={text}
                playing={true}
                controls={true}
              />
            )}
          </div>
        </div>
      </div>
    );
  };
  {
    /* <div className = "media_text_box">
        <div className = "center_container">
            <ReactPlayer
                width={'100%'}
                height={'100%'}
                url= {props.text}
                playing={true}
                controls={true}       
            />
        </div>
      </div> */
  }
  return (
    <div className="edit_media">
      {renderTextBox()}
      {renderMedia()}
    </div>
  );
};

export default MediaEdit;
