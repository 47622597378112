import "./TrueFalse.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Idirection, IscreenSize } from "../../../interfaces";
import { motion } from "framer-motion";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { PlayFunction } from "use-sound/dist/types";
import {
  Arrows,
  Background,
  ConfirmButton,
  ExerciseImage,
  HelpMenu,
  Professor,
  TextBox,
  ExerciseName,
} from "./_components";
import { useAppSelector } from "../../../hooks/hooks";

const TrueFalse = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  research: boolean;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const { t, i18n } = useTranslation();

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const isTeacher = useAppSelector((state) => state.login).type === "teacher";

  const text = props.exercise.text;
  const helpText = props.exercise.help_text;
  const image = props.exercise.image?.url;
  const animationUrl = props.exercise.animation?.url;
  const propSolutions = props.exercise.solutions as number[];
  const solution = propSolutions[0] as number;

  const [selectedExercise, setSelectedExercise] = useState(props.exerciseIndex);
  const [maxExercise, setMaxExercise] = useState(props.maxExercise);

  // const exerciseIndex = props.exerciseIndex;
  const [research, setResearch] = useState(props.research);

  const [buttonPressed, setButtonPressed] = useState<"none" | "true" | "false">(
    "none"
  );
  const [incorrectFeedback, setIncorrectFeedback] = useState(false);
  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (image !== undefined || animationUrl !== undefined) {
      setImageExists(true);
    }
  }, [image, animationUrl]);

  useEffect(() => {
    if (research) {
      if (selectedExercise < maxExercise) {
        solution === 1 ? setButtonPressed("true") : setButtonPressed("false");
      }
    } else {
      if (isTeacher) {
        solution === 1 ? setButtonPressed("true") : setButtonPressed("false");
      }
    }
  }, []);

  useEffect(() => {
    setSelectedExercise(props.exerciseIndex);
    setMaxExercise(props.maxExercise);
  }, [props.exerciseIndex]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const confirmHandler = () => {
    var solutionString = solution === 1 ? "true" : "false";
    if (research) {
      if (buttonPressed.toString() === solutionString) {
        props.updateLevel("right", [buttonPressed.toString()], true);
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        props.wrongAnswerSound();
        setIncorrectFeedback(true);
      }
    } else {
      if (buttonPressed.toString() === solutionString) {
        props.updateLevel("right", [buttonPressed.toString()], true);
      } else {
        props.updateLevel("right", [buttonPressed.toString()], false);
      }
    }
  };

  const renderTrueFalseButtons = () => {
    return (
      <div
        className="true_false_buttons"
        style={{ left: imageExists ? "50%" : "40%" }}
      >
        <motion.div
          className={
            buttonPressed === "true"
              ? "true_false_left_button_pressed"
              : "true_false_left_button"
          }
          onClick={() => setButtonPressed("true")}
          whileHover={{
            scale: 1.1,
            boxShadow:
              buttonPressed === "true"
                ? "0px 0px 10px 1px #6EE6A7"
                : "0px 0px 10px 1px #b8b8b8",
          }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <div className="center_container">
            <div
              className={
                isTeacher ? "text_white_small_bold" : "text_white_medium_bold"
              }
            >
              {t("true")}
            </div>
          </div>
        </motion.div>
        <motion.div
          className={
            buttonPressed === "false"
              ? "true_false_right_button_pressed"
              : "true_false_right_button"
          }
          onClick={() => setButtonPressed("false")}
          whileHover={{
            scale: 1.1,
            boxShadow:
              buttonPressed === "false"
                ? "0px 0px 10px 1px #F0684F"
                : "0px 0px 10px 1px #b8b8b8",
          }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <div className="center_container">
            <div
              className={
                isTeacher ? "text_white_small_bold" : "text_white_medium_bold"
              }
            >
              {t("false")}
            </div>
          </div>
        </motion.div>
      </div>
    );
  };

  return (
    <div className="true_false">
      <Background
        incorrectFeedback={incorrectFeedback}
        setIncorrectFeedback={setIncorrectFeedback}
      />
      <Professor width={screenSize.dynamicWidth} />
      <TextBox text={text} contractTextBox={imageExists} />
      <Arrows
        selectedExercise={selectedExercise}
        maxExercise={maxExercise}
        updateLevel={props.updateLevel}
        research={isTeacher ? true : research}
      />
      <ConfirmButton confirmHandler={confirmHandler} />
      <HelpMenu helpText={helpText} tipHandler={props.tipHandler} />
      <ExerciseImage
        image={image}
        animationUrl={animationUrl}
        imageExists={imageExists}
      />
      <ExerciseName
        type={props.exercise.type}
        smallMargin={imageExists}
        imageExists={imageExists}
      />
      {renderTrueFalseButtons()}
    </div>
  );
};

export default TrueFalse;
