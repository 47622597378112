import { getBackendHost } from "../../utils/apiconnection";
import APIFetch from "../../utils/APIFetch";
import { BundleType, ModifiedBundleType } from "../user/class";
import { TeacherType } from "../user/teacher";
import { CourseType } from "./course";
import { ModifiedScenarioType } from "./scenario";

export interface ImageType {
  id: string;
  url: string;
  verified: boolean;
  owner_id: string;
}

export interface AnimationType {
  id: string;
  url: string;
  verified: boolean;
}

export interface GlobalCompetenceType {
  id: string;
  acronym: string;
  number: number;
  color: string;
}

export interface SpecificCompetenceType {
  id: string;
  number: number;
  course_id: string;
  course: {
    acronym: string;
  };
  global_competences: GlobalCompetenceType[];
}

export interface EvaluationCriterionType {
  id: string;
  number: number;
  specific_competence_id: string;
  specific_competence?: SpecificCompetenceType;
}

export interface ExerciseType {
  id: string;
  language: string;
  priority_index: number;
  type:
    | "theory"
    | "truefalse"
    | "choosemany"
    | "chooseone"
    | "dragdropoptions"
    | "fillinthegaps"
    | "writeanswers"
    | "dragdroparrows"
    | "dragdroplist"
    | "dragdroptwolists"
    | "definition"
    | "media"; // This allows to validate stuff

  text?: string;
  image_id?: string;
  image?: ImageType;
  animation_id?: string;
  animation?: AnimationType;
  help_text?: string;
  solutions?: number[];
  options?: string[];
  labels?: string[];
  max_error?: number[];
  main_text?: string;
  random_lims?: number[];
}

export interface ScenarioType {
  id: string;
  name: string;
  type: string;
  description: string;
  main_know_how: string;
  other_know_hows: string[];
  language: string;
  visibility: number;
  inactive: Date | null;
  copy: boolean;
  owner_id: string;
  owner?: {
    //Teacher + school
    id: string;
    username: string;
    fullname: string;
    admin: boolean;
    school_id: string;
    school: {
      id: string;
      name: string;
      start_date: string;
      license_id: string | null;
    };
  };
  course_id: string;
  unit_id: string;
  banner: ImageType;
  image: ImageType;
  research: {
    id: string;
  };
  experiment: {
    id: string;
    _count: {
      exercises: number;
    };
  };
  course: CourseType;
  evaluation_criteria_ids: string[];
}
export interface UnitType {
  id: string;
  name: string;
  language: string;
  visibility: number;
  owner_id: string;
  course_id: string;
  scenarios: ScenarioType[];
}

export interface ModifiedUnitType {
  id: string;
  name: string;
  language: string;
  visibility: number;
  owner_id: string;
  course_id: string;
  owner?: {
    //Teacher + school
    id: string;
    username: string;
    fullname: string;
    admin: boolean;
    school_id: string;
    school: {
      id: string;
      name: string;
      start_date: string;
      license_id: string | null;
    };
  };
  banner: string;
  scenario_count: number;
  regulation_id: string;
}

export const GetAllUnitsAPI = async (): Promise<ModifiedUnitType[]> => {
  // var req = await fetch(getBackendHost() + "/unit/all", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "GET",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = await req.json();
  var data = APIFetch(getBackendHost() + "/unit/all", {
    method: "GET",
  });
  data = data || [];
  return data;
};

export const GetUnitAPI = async (id: string): Promise<ModifiedUnitType> => {
  // var req = await fetch(getBackendHost() + "/unit/" + id, {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "GET",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = await req.json();
  const data = APIFetch(getBackendHost() + "/unit/" + id, {
    method: "GET",
  });

  return data;
};

export const UpdateUnitAPI = async (
  id: string,
  unit: ModifiedUnitType
): Promise<string | void> => {
  // var req = await fetch(getBackendHost() + "/unit/" + id, {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "POST",
  //   body: JSON.stringify(unit),
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = await req.json();
  const data = APIFetch(getBackendHost() + "/unit/" + id, {
    method: "POST",
    body: JSON.stringify(unit),
  });
};

export const GetUnitScenariosAPI = async (
  id: string
): Promise<ModifiedScenarioType[]> => {
  // var req = await fetch(getBackendHost() + "/unit/" + id + "/scenarios", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "GET",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = await req.json();

  var data = APIFetch(getBackendHost() + "/unit/" + id + "/scenarios", {
    method: "GET",
  });

  data = data || [];
  return data;
};

export const GetUnitEvaluationCriteriaAPI = async (
  id: string
): Promise<EvaluationCriterionType[]> => {
  // var req = await fetch(
  //   getBackendHost() + "/unit/" + id + "/evaluation_criteria",
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     method: "GET",
  //     mode: "cors",
  //     credentials: "include",
  //   }
  // );
  // var data = await req.json();

  var data = APIFetch(
    getBackendHost() + "/unit/" + id + "/evaluation_criteria",
    {
      method: "GET",
    }
  );

  data = data || [];
  return data;
};

export const CreateUnitAPI = async (
  regulation_id: string
): Promise<ModifiedUnitType> => {
  // var req = await fetch(getBackendHost() + "/unit", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "POST",
  //   body: JSON.stringify({ regulation_id }),
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data: ModifiedUnitType = await req.json();

  var data = APIFetch(getBackendHost() + "/unit", {
    method: "POST",
    body: JSON.stringify({ regulation_id }),
  });

  return data;
};

export const CloneUnitAPI = async (
  existing_unit_id: string
): Promise<ModifiedUnitType | null> => {
  // var req = await fetch(
  //   getBackendHost() + "/unit/" + existing_unit_id + "/clone",
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     method: "POST",
  //     mode: "cors",
  //     credentials: "include",
  //   }
  // );

  // var data = await req.json();

  var data = APIFetch(
    getBackendHost() + "/unit/" + existing_unit_id + "/clone",
    {
      method: "POST",
    }
  );

  data = data || null;

  return data;
};

export const AllBundlesofUnitAPI = async (
  unit_id: string,
  class_id: string
): Promise<BundleType[] | null> => {
  // const req = await fetch(
  //   getBackendHost() + "/unit/" + unit_id + "/bundles/" + class_id,
  //   {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     method: "GET",
  //     mode: "cors",
  //     credentials: "include",
  //   }
  // );

  // var data = await req.json();

  var data = APIFetch(
    getBackendHost() + "/unit/" + unit_id + "/bundles/" + class_id,
    {
      method: "GET",
    }
  );

  data = data || null;
  return data;
};

export const DeleteUnitAPI = async (id: string): Promise<void> => {
  // var req = await fetch(getBackendHost() + "/unit/delete/" + id, {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "POST",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = req.status;
  var data = APIFetch(getBackendHost() + "/unit/delete/" + id, {
    method: "POST",
  });
};

//export const GetAllUnitsGPTAPI = async (): Promise<UnitType[]> => {
export const GetAllUnitsGPTAPI = async (): Promise<
  {
    bundle_id: string;
    start_date: Date;
    completed: number;
  }[]
> => {
  // var req = await fetch(getBackendHost() + "/unit/all/gpt", {
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   },
  //   method: "GET",
  //   mode: "cors",
  //   credentials: "include",
  // });
  // var data = await req.json();
  var data = APIFetch(getBackendHost() + "/unit/all/gpt", {
    method: "GET",
  });

  data = data || [];
  return data;
};
