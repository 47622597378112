import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./hooks/hooks";
import LandingPage from "./components/login_screen/LandingPage";
import Professor from "./components/professor/Professor";
import Student from "./components/student/Student";
import Settings from "./components/settings/Settings";
import Alert from "./components/alerts/Alert";
import settingsIcon from "./assets/settings.svg";
import _ from "lodash";
import {
  setCourses,
  setSettingsOpen,
  setRegulations,
  setGlobalCompetences,
  setPlots,
  setResearch,
  setExperiment,
  setSelectedCourseId,
  setBiomes,
  setUnauthorized,
} from "./reducers/mainSlice";
import { updateImpersonating, updateUser } from "./reducers/loginSlice";
import { LazyMotion, m } from "framer-motion";
import { GetMyUserAPI, UserType } from "./_newapios/user/user.ts";
import { ClassType, GetMyClassAPI } from "./_newapios/user/class.ts";
import { GetMyTeacherInfoAPI } from "./_newapios/user/teacher.ts";
import {
  GetAllRegulationsAPI,
  RegulationType,
} from "./_newapios/content/regulation.ts";
import { setLicense, teacherInfo } from "./reducers/teacherSlice.ts";
import { CourseType, GetAllCoursesAPI } from "./_newapios/content/course.ts";
import { GlobalCompetenceType } from "./_newapios/content/unit.ts";
import { GetAllGlobalCompetencesAPI } from "./_newapios/content/global_competence.ts";
import { GetAllPlotsAPI } from "./_newapios/gamecontent/plots.ts";
import { setStudents } from "./reducers/mainSlice.ts";
import ExercisesResearch from "./components/exercises/ExercisesResearch.tsx";
import ExercisesExperiment from "./components/exercises/ExercisesExperiment.tsx";
import {
  setTheme,
  updateMetrics,
  updateProgress,
} from "./reducers/studentSlice.ts";
import { completeCriteriaJSON } from "./utils/competences.ts";
import { GetMyLicenseAPI } from "./_newapios/user/user_license.ts";
import { useTranslation } from "react-i18next";
import { bundle_data } from "./content_Bundle_bundles.ts";
import { GetAllBiomesAPI } from "./_newapios/gamecontent/biomes.ts";
import { getCookie, setCookie } from "./utils/general.ts";
import BusinessView from "./components/professor/BusinessView/BusinessView.tsx";
import Clarity from "@microsoft/clarity";

const App = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const clarityProjectId = "lg02pfl025";

  if (process.env.NODE_ENV === "production") {
    Clarity.init(clarityProjectId);
    console.log("Clarity initialized");
  }

  var loginState = useAppSelector((state) => state.login);
  var mainState = useAppSelector((state) => state.main);

  const [hasClass, setHasClass] = useState(true);
  const [plotsLoaded, setPlotsLoaded] = useState(false);
  const [classInactive, isClassInactive] = useState(false);
  const [userInactive, isUserInactive] = useState(false);
  const [licenseError, setLicenseError] = useState(false);

  var loggedIn = loginState.loggedIn;
  var accountType = loginState.type;

  const loadFeatures = () =>
    import("./hooks/framerMotionHooks.ts").then((res) => res.default);

  useEffect(() => {
    handleLoginRequest();
    setSettingsCookies();
    // calcBundleData();
  }, [loggedIn]);

  // ------------ Retrieving processed data from the db ------------

  const calcBundleData = () => {
    let bundle = bundle_data;
    let units_used: { [key: string]: number } = {};
    bundle.map((bundle) => {
      let unit = bundle.unit_id;
      if (unit in units_used) {
        units_used[unit] += 1;
      } else {
        units_used[unit] = 1;
      }
    });

    console.log(units_used);
  };

  // ---------------------------------------------------------------

  const setSettingsCookies = () => {
    if (getCookie("shadows") === undefined) {
      setCookie("shadows", "false", 365);
    }
    if (getCookie("animations") === undefined) {
      setCookie("animations", "false", 365);
    }
    if (getCookie("performanceMode") === undefined) {
      setCookie("performanceMode", "true", 365);
    }
    if (getCookie("ultraPerformanceMode") === undefined) {
      setCookie("ultraPerformanceMode", "false", 365);
    }
    if (getCookie("volumeMusic") === undefined) {
      setCookie("volumeMusic", "0.2", 365);
    }
    if (getCookie("volumeSounds") === undefined) {
      setCookie("volumeSounds", "0.8", 365);
    }
  };

  const handleLoginRequest = async () => {
    const user = await GetMyUserAPI();
    if (user === 404) {
      console.log("NO USER");
      return;
    }
    if (user === 409) {
      console.log("USER INACTIVE");
      handleAlertUser();
      return;
    }

    switch (user.type) {
      case "student":
        loadStudent(user);
        break;
      case "teacher":
        if (
          user.verification_code !== null &&
          user.verification_code !== undefined
        ) {
          console.log("TEACHER NOT VERIFIED");
          return;
        }
        loadTeacher(user);
        break;
      case "admin":
        loadAdmin(user);
        break;
      default:
        console.error("TYPE MISMATCH");
        break;
    }
    const courses: CourseType[] = await GetAllCoursesAPI();
    dispatch(setCourses(courses));
    const globalCompetences: GlobalCompetenceType[] =
      await GetAllGlobalCompetencesAPI();
    dispatch(setGlobalCompetences(globalCompetences));
    const regulations: RegulationType[] = await GetAllRegulationsAPI();
    dispatch(setRegulations(regulations));
  };

  const handleAlertClass = () => {
    isClassInactive(!classInactive);
  };

  const handleAlertUser = () => {
    isUserInactive(!userInactive);
  };

  const handleLicenseError = () => {
    console.log("Licencia inactiva");
    setLicenseError(!licenseError);
  };

  const loadTeacher = async (user: Omit<UserType, "password">) => {
    const myLicense = await GetMyLicenseAPI();
    if (myLicense.active === false) {
      handleLicenseError();
      return;
    }
    dispatch(setLicense(myLicense));
    var promises = [
      GetAllPlotsAPI().then((plots) => {
        dispatch(setPlots(plots));
      }),
      GetMyTeacherInfoAPI().then((myTeacherInfo) => {
        dispatch(teacherInfo(myTeacherInfo));
      }),
    ];
    Promise.all(promises).then(() => {
      dispatch(updateUser(user));
    });
  };

  const loadStudent = async (user: UserType) => {
    const myLicense = await GetMyLicenseAPI();
    if (myLicense.active === false) {
      handleLicenseError();
      return;
    }
    const myclass = (await GetMyClassAPI()) as ClassType | null;
    if (myclass === null) {
      // Clase inactive pero alumno existe
      console.log("clase inactiva");
      handleAlertClass();
      return;
    }
    const plots = await GetAllPlotsAPI();
    const biomes = await GetAllBiomesAPI();
    dispatch(setBiomes(biomes));
    dispatch(setPlots(plots));
    if (myclass.theme !== null && myclass.theme !== undefined) {
      dispatch(setTheme(myclass.theme));
    }
    dispatch(setStudents(myclass?.students || []));
    setHasClass(myclass !== null);
    setPlotsLoaded(true);
    dispatch(updateUser(user));
  };

  const loadAdmin = (user: UserType) => {
    console.log(user);
    dispatch(updateUser(user));
  };

  const impersonating = loginState.impersonating;
  useEffect(() => {
    dispatch(updateImpersonating(getCookie("impersonating")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, document.cookie]);
  console.log(mainState.isUnauthorized);
  return (
    <div
      className="app"
      style={impersonating ? { border: "5px #f0684f solid" } : {}}
    >
      {loggedIn === false && (
        <LandingPage handleLogin={() => handleLoginRequest()} />
      )}
      {loggedIn === true && accountType === "teacher" && <Professor />}
      {loggedIn === true && accountType === "admin" && <BusinessView />}
      {loggedIn === true && accountType === "student" && !hasClass && (
        <LandingPage insertPin={true} />
      )}
      {plotsLoaded &&
        loggedIn === true &&
        accountType === "student" &&
        hasClass && <Student />}
      {/* {accountType !== "student" && renderSettingsButton()} */}
      {/* Right now this should only work for teachers */}
      {loggedIn === true &&
        accountType === "student" &&
        mainState.researchExercises !== null && (
          <ExercisesResearch
            exercises={mainState.researchExercises.exercises}
            progress={mainState.researchExercises.research_progress[0]}
            updateMaxLevelResearch={() => null}
            backHandler={() => {
              dispatch(updateProgress());
              dispatch(setResearch(null));
            }}
          />
        )}
      {loggedIn === true &&
        accountType === "student" &&
        mainState.experimentExercises !== null && (
          <ExercisesExperiment
            exercises={mainState.experimentExercises.exercises}
            progress={mainState.experimentExercises.experiment_progress}
            updateMaxLevelExperiment={() => null}
            backHandler={() => {
              dispatch(updateProgress());
              dispatch(updateMetrics());
              dispatch(setExperiment(null));
            }}
          />
        )}
      {classInactive === true && (
        <Alert
          handleAlert={() => handleAlertClass()}
          text={t("class_inactive_text")}
        />
      )}
      {userInactive === true && (
        <Alert
          handleAlert={() => handleAlertUser()}
          text={t("user_inactive_text")}
        />
      )}
      {licenseError === true && (
        <Alert
          handleAlert={() => handleLicenseError()}
          text={t("license_error_text")}
        />
      )}
      {mainState.isUnauthorized && (
        <Alert text={t("backend_error_text")} handleAlert={() => {}} />
      )}
    </div>
  );
};

export default App;
