import "./ExerciseEditor.css";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../hooks/hooks";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import TheoryIcon from "../../../../../assets/exercises/theory_icon";
import MediaIcon from "../../../../../assets/exercises/media_icon";
import DefinitionIcon from "../../../../../assets/exercises/definition_icon";
import { CourseType } from "../../../../../_newapios/content/course";
import { ExerciseType } from "../../../../../_newapios/content/unit";
import ok from "../../../../../assets/professor/ok.svg";
import close from "../../../../../assets/student/close_user_menu.svg";
import _ from "lodash";
import ChooseManyEdit from "../../exercise_templates/ChooseManyEdit";
import ChooseOneEdit from "../../exercise_templates/ChooseOneEdit";
import DefinitionEdit from "../../exercise_templates/DefinitionEdit";
import TheoryEdit from "../../exercise_templates/TheoryEdit";
import TrueFalseEdit from "../../exercise_templates/TrueFalseEdit";
import WriteAnswersEdit from "../../exercise_templates/WriteAnswersEdit";
import DragDropListEdit from "../../exercise_templates/DragDropListEdit";
import DragDropTwoListsEdit from "../../exercise_templates/DragDropTwoListsEdit";
import DragDropOptionsEdit from "../../exercise_templates/DragDropOptionsEdit";
import FillInTheGapsEdit from "../../exercise_templates/FillInTheGapsEdit";
import MediaEdit from "../../exercise_templates/MediaEdit";
import DragDropArrowsEdit from "../../exercise_templates/DragDropArrowsEdit";
import blueBackground from "../../../../../assets/exercises/bluebackgroundexercise.svg";
import ExerciseSelectorPage from "./exerciseSelectorPage.tsx";
import { isExerciseComplete } from "../../../../../utils/content";
import {
  CreateExerciseAPI,
  DeleteExerciseAPI,
  UpdateExerciseAPI,
} from "../../../../../_newapios/content/exercise";

const ExerciseEditor = ({ ...props }) => {
  const { t } = useTranslation();

  const mainState = useAppSelector((state) => state.main);
  const teacherState = useAppSelector((state) => state.teacher);
  const [selectedExercise, setSelectedExercise] = useState(0);
  const [course, setCourse] = useState<CourseType>(mainState.courses[0]);
  const [exercises, setExercises] = useState<(ExerciseType | undefined)[]>([]);
  const [openExerciseSelector, setOpenExerciseSelector] = useState(false);
  const [isResearch, setIsResearch] = useState(true);
  const [deleteMenu, setDeleteMenu] = useState<ExerciseType | null>(null);
  const [researchLength, setResearchLength] = useState(0);

  const research = mainState.researchExercises;
  const experiment = mainState.experimentExercises;

  useEffect(() => {
    if (research && experiment) {
      const researchExercises =
        research.exercises.length > 0 ? research.exercises : [undefined];
      const experimentExercises = fillExperimentExercises(experiment.exercises);
      setExercises([...researchExercises, ...experimentExercises]);
    }
  }, [research, experiment]);
  console.log("exercises", exercises);

  useEffect(() => {
    if (research === null) {
      return;
    }
    setResearchLength(
      research.exercises.length > 0 ? research.exercises.length : 1
    );
  }, [research]);

  useEffect(() => {
    if (teacherState.selectedScenario === null) {
      return;
    }
    setCourse(teacherState.selectedScenario?.course);
  }, [teacherState.selectedScenario?.course]);

  const levelButtonHandler = (level: number) => {
    setSelectedExercise(level);
    if (research === null) {
      return;
    }
    if (level < researchLength) {
      setIsResearch(true);
    } else {
      setIsResearch(false);
    }
  };

  useEffect(() => {
    if (exercises[selectedExercise] === undefined) {
      setOpenExerciseSelector(true);
    } else {
      setOpenExerciseSelector(false);
    }
  }, [exercises, selectedExercise]);

  const normalizePriorityIndexResearch = (
    researchExercises: ExerciseType[],
    new_exercise_index: number
  ) => {
    const newExerciseIndex = new_exercise_index + 0.5;
    const priorityIndexArray =
      researchExercises.length === 1 && researchExercises[0] === undefined
        ? []
        : researchExercises.map((exercise, index) => {
            return exercise.priority_index;
          });
    priorityIndexArray.push(newExerciseIndex);
    priorityIndexArray.sort();

    const newPriorityIndexArray = priorityIndexArray.map(
      (priorityIndex, index) => {
        return index;
      }
    );
    return newPriorityIndexArray;
  };

  const normalizePriorityIndexExperiment = (
    experimentExercises: (ExerciseType | undefined)[],
    new_exercise_index: number
  ) => {
    const newExerciseIndex = new_exercise_index + 0.5;
    const priorityIndexArray = experimentExercises.map((_exercise, index) => {
      return index;
    });

    if (exercises[selectedExercise] === undefined) {
      return experimentExercises
        .map((exercise) => {
          return exercise === undefined ? -1 : exercise.priority_index;
        })
        .filter((priorityIndex) => priorityIndex !== -1);
    }

    priorityIndexArray.push(newExerciseIndex);
    priorityIndexArray.sort();

    console.log("priorityIndexArray", priorityIndexArray);

    const newPriorityIndexArray = priorityIndexArray.map(
      (priorityIndex, index) => {
        return index;
      }
    );
    return newPriorityIndexArray;
  };

  const deleteMenuHandler = (exerc: ExerciseType | null = null) => {
    setDeleteMenu(exerc);
  };

  const fillExperimentExercises = (exercises: ExerciseType[]) => {
    if (exercises.length >= 4) {
      return exercises;
    }
    const tempExercises: (ExerciseType | undefined)[] = new Array(4);
    const priorityIndexArray = exercises.map((_exercise, index) => {
      return index;
    });
    for (let i = 0; i < tempExercises.length; i++) {
      if (priorityIndexArray.includes(i)) {
        tempExercises[i] = exercises[i];
      } else {
        tempExercises[i] = undefined;
      }
    }
    return tempExercises;
  };

  const createExerciseHandler = async (
    exerciseType:
      | "theory"
      | "truefalse"
      | "choosemany"
      | "chooseone"
      | "dragdropoptions"
      | "fillinthegaps"
      | "writeanswers"
      | "dragdroparrows"
      | "dragdroplist"
      | "dragdroptwolists"
      | "definition"
      | "media"
  ) => {
    var tempExercises = [...exercises];

    if (research === null || experiment === null) {
      return;
    }

    const researchExercises = tempExercises.slice(
      0,
      researchLength
    ) as ExerciseType[];

    const experimentExercises = tempExercises.slice(researchLength);

    const priorityIndexArray = isResearch
      ? normalizePriorityIndexResearch(researchExercises, selectedExercise)
      : normalizePriorityIndexExperiment(
          experimentExercises,
          selectedExercise - researchLength
        );

    console.log(
      "priorityIndexArray",
      priorityIndexArray,
      selectedExercise - researchLength
    );
    const selectedExerciseResearch =
      tempExercises.length === 0 ? selectedExercise : selectedExercise + 1;
    const notUndefinedExercises = experimentExercises.filter(
      (exercise) => exercise !== undefined
    ).length;
    const selectedExerciseExperiment =
      notUndefinedExercises >= 4
        ? selectedExercise - researchLength + 1
        : notUndefinedExercises;

    CreateExerciseAPI(
      props.language,
      exerciseType,
      isResearch ? selectedExerciseResearch : selectedExerciseExperiment,
      priorityIndexArray,
      isResearch ? research.id : experiment.id,
      isResearch
    ).then((data) => {
      props.reloadExercises();
      if (isResearch) {
        setSelectedExercise(
          tempExercises[selectedExercise] === undefined
            ? selectedExercise
            : selectedExercise + 1
        );
      } else {
        setSelectedExercise(selectedExerciseExperiment + researchLength);
      }
      if (selectedExercise + 1 > tempExercises.length) {
        setSelectedExercise(0);
      }
      setOpenExerciseSelector(false);
    });
  };

  const deleteExerciseHandler = (e: any) => {
    e.stopPropagation();

    if (research === null || experiment === null) {
      return;
    }

    const selectedExerciseData = exercises[selectedExercise];
    if (!selectedExerciseData || !selectedExerciseData.id) {
      return;
    }

    DeleteExerciseAPI(
      selectedExerciseData.id,
      isResearch ? selectedExercise : selectedExercise - researchLength,
      isResearch ? research.id : experiment.id,
      isResearch
    ).then((data) => {
      props.reloadExercises();
      setSelectedExercise(isResearch ? 0 : research.exercises.length);
    });
    setDeleteMenu(null);
  };

  const updateExerciseHandler = async (new_exercise_data: ExerciseType) => {
    const newExercise = await UpdateExerciseAPI(
      new_exercise_data.id,
      new_exercise_data
    );
    props.reloadExercises();
  };

  if (research === null || experiment === null) {
    return <div />;
  }

  const renderTheoryIcon = (exerciseIndex: number) => {
    return (
      <div
        className="professor_exercise_editor_navbar_list_element"
        style={{
          marginTop: exerciseIndex === 0 ? "0px" : "10px",
          backgroundColor:
            selectedExercise === exerciseIndex
              ? course.color + "80"
              : course.color + "40",
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          zIndex: "9",
        }}
        onClick={() => levelButtonHandler(exerciseIndex)}
      >
        <motion.div
          className="icon"
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <TheoryIcon
            color={selectedExercise === exerciseIndex ? "white" : "black"}
            size={25}
          />
        </motion.div>
      </div>
    );
  };

  const renderMediaIcon = (exerciseIndex: number) => {
    return (
      <div
        className="professor_exercise_editor_navbar_list_element"
        style={{
          marginTop: exerciseIndex === 0 ? "0px" : "10px",
          backgroundColor:
            selectedExercise === exerciseIndex
              ? course.color + "80"
              : course.color + "40",
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          zIndex: "9",
        }}
        onClick={() => levelButtonHandler(exerciseIndex)}
      >
        <motion.div
          className="icon"
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <MediaIcon
            color={selectedExercise === exerciseIndex ? "white" : "black"}
            size={25}
          />
        </motion.div>
      </div>
    );
  };

  const renderNumberButton = (level: number, exerciseIndex: number) => {
    return (
      <div
        key={level}
        className="professor_exercise_editor_navbar_list_element"
        onClick={() => levelButtonHandler(exerciseIndex)}
        style={{
          marginTop: exerciseIndex === 0 ? "0px" : "10px",
          backgroundColor:
            selectedExercise === exerciseIndex
              ? course.color + "80"
              : course.color + "40",
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          zIndex: "9",
        }}
      >
        <div className="center_container">
          <motion.div
            className={
              selectedExercise === exerciseIndex
                ? "text_white_medium_bold"
                : "text_black_medium_bold"
            }
            whileHover={{
              scale: 1.2,
              textShadow: "rgba(0,0,0, 0.50) 1px 0px 10px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            {level}
          </motion.div>
        </div>
      </div>
    );
  };

  const renderDefinitionIcon = (exerciseIndex: number) => {
    return (
      <div
        className="professor_exercise_editor_navbar_list_element"
        style={{
          marginTop: exerciseIndex === 0 ? "0px" : "10px",
          backgroundColor:
            selectedExercise === exerciseIndex
              ? course.color + "80"
              : course.color + "40",
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          zIndex: "9",
        }}
        onClick={() => levelButtonHandler(exerciseIndex)}
      >
        <motion.div
          className="icon"
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <DefinitionIcon
            color={selectedExercise === exerciseIndex ? "white" : "black"}
            size={25}
          />
        </motion.div>
      </div>
    );
  };

  const renderResearchLevels = () => {
    var levelIndex = 1;
    const researchExercisesLength = researchLength;
    const researchExercises = exercises.slice(
      0,
      researchExercisesLength > 0 ? researchExercisesLength : 1
    );
    return (
      <div className="professor_exercise_editor_navbar_list">
        {researchExercises.map((exercise, exerciseIndex) => {
          if (exercise === undefined) {
            return renderNumberButton(levelIndex, exerciseIndex);
          } else if (exercise.type === "theory") {
            return renderTheoryIcon(exerciseIndex);
          } else if (exercise.type === "definition") {
            return renderDefinitionIcon(exerciseIndex);
          } else if (exercise.type === "media") {
            return renderMediaIcon(exerciseIndex);
          } else {
            var copyLevelIndex = levelIndex;
            levelIndex++;
            return renderNumberButton(copyLevelIndex, exerciseIndex);
          }
        })}
      </div>
    );
  };

  const renderExperimentLevels = () => {
    // Need to add the length of the research exercises to the index
    const researchExercisesLength = researchLength;
    const experimentExercises = exercises.slice(
      researchExercisesLength > 0 ? researchExercisesLength : 1
    );
    return (
      <div className="professor_exercise_editor_navbar_list">
        {experimentExercises.map((exercise, exerciseIndex) => {
          return renderExperimentLevelButton(
            exercise,
            exerciseIndex + researchLength
          );
        })}
      </div>
    );
  };

  const renderExperimentLevelButton = (
    exercise: ExerciseType | undefined,
    exerciseIndex: number
  ) => {
    const isExerciseCompleteBool = isExerciseComplete(exercise) === "true"; //Make a function that checks if the exercise is complete
    return (
      <div
        key={exerciseIndex}
        className="professor_exercise_editor_navbar_list_element"
        onClick={() => levelButtonHandler(exerciseIndex)}
        style={{
          marginTop: exerciseIndex === researchLength ? "0px" : "10px",
          backgroundColor:
            selectedExercise === exerciseIndex
              ? course.color + "80"
              : course.color + "40",
        }}
      >
        <div className="center_container">
          {isExerciseCompleteBool && (
            <motion.div
              className="professor_exercise_editor_navbar_list_outer_dot"
              style={{
                backgroundColor:
                  selectedExercise === exerciseIndex
                    ? course.color
                    : course.color + "80",
              }}
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <img src={ok} alt="ok" />
            </motion.div>
          )}

          {!isExerciseCompleteBool && (
            <motion.div
              className="professor_exercise_editor_navbar_list_outer_dot"
              style={{
                backgroundColor:
                  selectedExercise === exerciseIndex
                    ? course.color
                    : course.color + "80",
              }}
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <div className="professor_exercise_editor_navbar_list_inner_dot_background">
                <div
                  className="professor_exercise_editor_navbar_list_inner_dot"
                  style={{
                    backgroundColor:
                      selectedExercise === exerciseIndex
                        ? course.color + "80"
                        : course.color + "40",
                  }}
                />
              </div>
            </motion.div>
          )}
        </div>
      </div>
    );
  };

  const renderNavbar = () => {
    return (
      <div className="professor_exercise_editor_navbar">
        <div className="professor_exercise_editor_navbar_list_container">
          <div className="text_black_medium_bold">{t("research")}</div>
          {renderResearchLevels()}
        </div>
        <div className="professor_exercise_editor_navbar_list_container">
          <div className="text_black_medium_bold">{t("experiment")}</div>
          {renderExperimentLevels()}
        </div>
      </div>
    );
  };

  const renderDeleteMenu = () => {
    return (
      <div className="professor_sidebar_delete_menu_background">
        <div className="center_container">
          <div className="professor_sidebar_delete_menu_container">
            <div className="professor_sidebar_delete_menu">
              <div className="professor_sidebar_delete_menu_text">
                <div className="center_container">
                  <div className="text_black_medium">
                    {t("delete_exercise_confirm")}
                  </div>
                </div>
              </div>
              <div
                className="professor_sidebar_delete_menu_close"
                onClick={() => deleteMenuHandler()}
              >
                <motion.img
                  className="icon"
                  src={close}
                  alt="close"
                  whileHover={{ scale: 1.1, rotate: 180 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                />
              </div>

              <div className="professor_sidebar_delete_menu_buttons">
                <motion.div
                  className="professor_sidebar_delete_menu_button_yes"
                  whileHover={{ scale: 1.1 }}
                  onClick={(e) => deleteExerciseHandler(e)}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                  <div className="center_container">
                    <div className="text_white_small_bold">{t("yes")}</div>
                  </div>
                </motion.div>

                <motion.div
                  className="professor_sidebar_delete_menu_button_no"
                  whileHover={{ scale: 1.1 }}
                  onClick={() => deleteMenuHandler()}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                  <div className="center_container">
                    <div className="text_white_small_bold">{t("no")}</div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderExercise = () => {
    const exercise = exercises[selectedExercise];
    if (exercise == null || exercise.text == null) return;
    switch (exercise.type.toLowerCase()) {
      case "choosemany":
        return (
          <ChooseManyEdit
            exercise={exercise}
            updateChooseMany={(newChooseMany: ExerciseType) =>
              updateExerciseHandler(newChooseMany)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "chooseone":
        return (
          <ChooseOneEdit
            exercise={exercise}
            updateChooseOne={(newChooseOne: ExerciseType) =>
              updateExerciseHandler(newChooseOne)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "definition":
        return (
          <DefinitionEdit
            exercise={exercise}
            updateDefinition={(newDefinition: ExerciseType) =>
              updateExerciseHandler(newDefinition)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "theory":
        return (
          <TheoryEdit
            exercise={exercise}
            updateTheory={(newTheory: ExerciseType) =>
              updateExerciseHandler(newTheory)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "truefalse":
        return (
          <TrueFalseEdit
            exercise={exercise}
            selectedExercise={selectedExercise}
            updateTrueFalse={(newTrueFalse: ExerciseType) =>
              updateExerciseHandler(newTrueFalse)
            }
          />
        );

      case "writeanswers":
        return (
          <WriteAnswersEdit
            type={isResearch ? "research" : "experiment"}
            exercise={exercise}
            x={Math.random()}
            updateWriteAnswers={(newWriteAnswers: ExerciseType) =>
              updateExerciseHandler(newWriteAnswers)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "dragdroplist":
        return (
          <DragDropListEdit
            exercise={exercise}
            updateDragDropList={(newDragDropList: ExerciseType) =>
              updateExerciseHandler(newDragDropList)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "dragdroptwolists":
        return (
          <DragDropTwoListsEdit
            exercise={exercise}
            updateDragDropTwoLists={(newDragDropTwoLists: ExerciseType) =>
              updateExerciseHandler(newDragDropTwoLists)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "dragdroparrows":
        return (
          <DragDropArrowsEdit
            exercise={exercise}
            updateDragDropArrows={(newDragDropArrows: ExerciseType) =>
              updateExerciseHandler(newDragDropArrows)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "dragdropoptions":
        return (
          <DragDropOptionsEdit
            exercise={exercise}
            updateDragDropOptions={(newDragDropOptions: ExerciseType) =>
              updateExerciseHandler(newDragDropOptions)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "fillinthegaps":
        return (
          <FillInTheGapsEdit
            exercise={exercise}
            updateFillInTheGaps={(newFillInTheGaps: ExerciseType) =>
              updateExerciseHandler(newFillInTheGaps)
            }
            selectedExercise={selectedExercise}
          />
        );

      case "media":
        return (
          <MediaEdit
            exercise={exercise}
            updateMedia={(newMedia: ExerciseType) =>
              updateExerciseHandler(newMedia)
            }
            selectedExercise={selectedExercise}
          />
        );

      default:
        console.log("Error?");
        return <></>;
    }
  };

  return (
    <div className="professor_exercise_editor">
      {renderNavbar()}
      <div />
      <div className="professor_exercise_editor_exercise">
        {!openExerciseSelector && (
          <img
            className="professor_exercise_editor_background"
            src={blueBackground}
            alt="background"
          />
        )}
        {openExerciseSelector && (
          <ExerciseSelectorPage
            updateScreen={(
              exercise_type:
                | "theory"
                | "truefalse"
                | "choosemany"
                | "chooseone"
                | "dragdropoptions"
                | "fillinthegaps"
                | "writeanswers"
                | "dragdroparrows"
                | "dragdroplist"
                | "dragdroptwolists"
                | "definition"
                | "media"
            ) => createExerciseHandler(exercise_type)}
            type={isResearch ? "research" : "experiment"}
          />
        )}
        {!openExerciseSelector && renderExercise()}
        {!openExerciseSelector && (
          <motion.div
            className="professor_exercise_editor_add_level_button"
            onClick={() => {
              setOpenExerciseSelector(true);
            }}
            whileHover={{
              scale: 1.08,
              boxShadow: "0px 0px 10px 1px #5090F0",
              borderRadius: "5 px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          >
            <div className="center_container">
              <div className="text_white_small_bold">{t("add_exercise")}</div>
            </div>
          </motion.div>
        )}

        {!openExerciseSelector && (
          <motion.div
            className="professor_exercise_editor_delete_button"
            onClick={() => deleteMenuHandler(exercises[selectedExercise])}
            whileHover={{
              scale: 1.08,
              boxShadow: "0px 0px 10px 1px #F0684F",
              borderRadius: "5px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          >
            <div className="center_container">
              <div className="text_white_small_bold">
                {t("delete_exercise")}
              </div>
            </div>
          </motion.div>
        )}
      </div>

      {!_.isNull(deleteMenu) && renderDeleteMenu()}
    </div>
  );
};

export default ExerciseEditor;
