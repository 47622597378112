import { getBackendHost } from "../../utils/apiconnection";
import APIFetch from "../../utils/APIFetch";
import { AnimationType, ImageType } from "../content/unit";
import { BiomeType } from "./biomes";
import { AssetType } from "./house_upgrades";

export interface ExplorerType {
    id: string,
    name: string,
    description: string,
    destruction: number,
    passive: string,
    image_id: string,
    image: ImageType,
    asset_id: string,
    asset: AssetType,
}

export const GetAllExplorersAPI = async (): Promise<ExplorerType[]> => {
    // var req = await fetch(getBackendHost() + "/explorer/all", {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();

    var data = APIFetch(getBackendHost() + "/explorer/all", {
        method: "GET"
    });

    data = data || null
    return data;
}
