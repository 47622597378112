import "./FillInTheGapsEdit.css";
import { useState, useEffect } from "react";
import { Textarea } from "@chakra-ui/react";
import help from "../../../../assets/exercises/help.svg";
import plus from "../../../../assets/exercises/plus_grey.svg";
import bin from "../../../../assets/professor/bin.svg";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { ExerciseType } from "../../../../_newapios/content/unit";
import _ from "lodash";

const FillInTheGapsEdit = (props: {
  exercise: ExerciseType;
  selectedExercise: number;
  updateFillInTheGaps: (newFillInTheGaps: ExerciseType) => void;
}) => {
  const { t, i18n } = useTranslation();

  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const [text, setText] = useState(props.exercise.text as string);
  const [helpText, setHelpText] = useState(props.exercise.help_text as string);
  const [mainText, setMainText] = useState(props.exercise.main_text as string);
  const [options, setOptions] = useState(props.exercise.options as string[]);
  const [saveToDB, setSaveToDB] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<number>(-1);

  useEffect(() => {
    if (saveToDB) {
      var exercise = { ...props.exercise };
      exercise.text = text;
      exercise.help_text = helpText;
      exercise.main_text = mainText;
      exercise.options = options;
      props.updateFillInTheGaps(exercise);
      setSaveToDB(false);
    }
  }, [saveToDB]);

  useEffect(() => {
    setText(props.exercise.text as string);
    setHelpText(props.exercise.help_text as string);
    setMainText(props.exercise.main_text as string);
    setOptions(props.exercise.options as string[]);
  }, [props.exercise.text, props.selectedExercise]);

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
  };

  const textHandler = (newText: string) => {
    setText(newText);
  };

  const helpTextHandler = (newHelpText: string) => {
    setHelpText(newHelpText);
  };

  const mainTextHandler = (newMainText: string) => {
    setMainText(newMainText);
  };

  const addTextBoxHandler = () => {
    var updatedOptions = [...options];
    updatedOptions.push("");
    setOptions(updatedOptions);
  };

  const editOptionsTextHandler = (
    updatedOption: string,
    optionIndex: number
  ) => {
    var currentOptions = [...options];
    currentOptions[optionIndex] = updatedOption;
    setOptions(currentOptions);
  };

  const deleteTextBoxHandler = (index: number) => {
    var currentOptions = [...options];
    currentOptions.splice(index, 1);
    setOptions(currentOptions);
    setSaveToDB(true);
  };

  const renderProfessor = () => {
    return (
      <div className="edit_fill_in_the_gaps_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            style={{ maxHeight: "35vh" }}
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <motion.div
        className="edit_fill_in_the_gaps_text_box"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        {!_.isUndefined(text) && (
          <div
            className={
              text.length < 200 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "justify",
                fontFamily: "Causten",
                fontSize: "1em",
                textJustify: "inter-word",
              }}
              value={text}
              resize={"none"}
              onChange={(e) => textHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  const renderHelpButton = () => {
    return (
      <div className="edit_fill_in_the_gaps_help_button">
        <div className="center_container">
          <motion.div
            className="write_help_text_button"
            whileHover={{
              scale: 1.02,
              boxShadow: "0px 0px 10px 1px #5090F0",
              borderRadius: "5 px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            onClick={() => helpButtonHandler()}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          >
            <div className="center_container" style={{ fontFamily: "Causten" }}>
              {t("write_your_clue")}
            </div>
          </motion.div>
        </div>
        <div
          className="center_container"
          onClick={() => helpButtonHandler()}
          style={{ cursor: "pointer" }}
        >
          <img
            className="icon"
            style={{ maxHeight: "7vh" }}
            src={help}
            alt="help"
          />
        </div>
      </div>
    );
  };

  const renderTextBoxMain = () => {
    return (
      <motion.div
        className="edit_fill_in_the_gaps_text_box_main"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        {!_.isUndefined(mainText) && (
          <div
            className={
              mainText.length < 265 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={mainText}
              size={"sm"}
              resize={"none"}
              onChange={(e) => mainTextHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  const renderAddButton = (currentAnswers: string[]) => {
    if (currentAnswers.length < 8) {
      return (
        <motion.div
          className="edit_fill_in_the_gaps_add_option"
          onClick={() => addTextBoxHandler()}
          whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          layout
          style={{
            marginTop: currentAnswers.length < 4 ? undefined : "5%",
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <div className="center_container">
            <img src={plus} style={{ maxHeight: "4vh" }} />
          </div>
        </motion.div>
      );
    }
  };

  const renderWriteBoxes = () => {
    return (
      <motion.div layoutRoot className="edit_fill_in_the_gaps_write_boxes">
        {options.map((option, optionIndex) => {
          return (
            <motion.div
              key={optionIndex}
              className={
                false
                  ? "edit_fill_in_the_gaps_write_box_wrong"
                  : "edit_fill_in_the_gaps_write_box"
              }
              style={optionIndex > 3 ? { marginTop: "5%" } : undefined}
              whileHover={{
                scale: 1.02,
                // boxShadow: "0px 0px 10px 1px #5090F0",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              layout
              onMouseEnter={() => setIsHovered(optionIndex)}
              onMouseLeave={() => setIsHovered(-1)}
            >
              <div className="center_container" style={{ height: "65%" }}>
                <div className="text_black_medium">{optionIndex + 1} =</div>
              </div>
              <div className="center_container">
                <Textarea
                  variant="unstyled"
                  placeholder="Solución"
                  style={{
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    fontFamily: "Causten",
                    fontSize: "1em",
                  }}
                  value={option}
                  resize={"none"}
                  justifyContent={"center"}
                  onChange={(e) =>
                    editOptionsTextHandler(e.target.value, optionIndex)
                  }
                  onBlur={() => setSaveToDB(true)}
                />
              </div>
              {isHovered === optionIndex && (
                <motion.div
                  className="edit_fill_in_the_gaps_option_delete"
                  onClick={() => deleteTextBoxHandler(optionIndex)}
                  whileHover={{
                    scale: 1.1,
                    boxShadow: "0px 0px 10px 1px #F0684F",
                  }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                >
                  <div className="center_container">
                    <img src={bin} style={{ height: "3vh" }} />
                  </div>
                </motion.div>
              )}
            </motion.div>
          );
        })}
        {renderAddButton(options)}
      </motion.div>
    );
  };

  const renderHelpMenu = () => {
    return (
      <motion.div
        className="edit_fill_in_the_gaps_help_menu"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        {!_.isUndefined(helpText) && (
          <div
            className={
              helpText.length < 265 ? "text_black_medium" : "text_black_small"
            }
          >
            <Textarea
              placeholder={t("click_here_to_edit")}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                textAlign: "center",
                fontFamily: "Causten",
                fontSize: "1em",
              }}
              value={helpText}
              resize={"none"}
              onChange={(e) => helpTextHandler(e.target.value)}
              onBlur={() => setSaveToDB(true)}
            />
          </div>
        )}
      </motion.div>
    );
  };

  return (
    <div className="edit_fill_in_the_gaps">
      {renderProfessor()}
      {renderTextBox()}
      {renderHelpButton()}
      {helpOpen && renderHelpMenu()}
      {renderTextBoxMain()}
      {renderWriteBoxes()}
    </div>
  );
};

export default FillInTheGapsEdit;
