import { getBackendHost } from "../../utils/apiconnection";
import APIFetch from "../../utils/APIFetch";

export interface ScenarioProgressType {
    // Fields
    id: string,
    // FKs
    scenario_id: string,
    student_id: string,
    research_progress: ResearchProgressType,
    experiment_progress: ExperimentProgressType[],
}

export interface ResearchProgressType {
    id: string,
    scenario_progress_id: string,
    research_id: string,

    exercise_progress: ExerciseProgress[],
    started_on: string,
    completed_on: string,
}

export interface ExperimentProgressType {
    id: string,
    scenario_progress_id: string,
    experiment_id: string,
    priority_index: number,

    exercise_progress: ExerciseProgress[],
    completed_on: string,
}

export interface ExerciseProgress {
    id: string,
    exercise_id: string,

    answer: string[],
    correct: boolean,

    time_spent: number,
    used_hint: boolean,
    attempts: number,

    research_progress_id?: string,
    experiment_progress_id?: string,
}

export const GetMyStudentProgressAPI = async (student_id: string): Promise<ScenarioProgressType[]> => {
    // var req = await fetch(getBackendHost() + "/" + student_id + "/scenario_progress/all", {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();
    const data = APIFetch(getBackendHost() + "/" + student_id + "/scenario_progress/all", {
        method: "GET"
    });

    return data;
}

export const GetMyStudentScenarioProgressAPI = async (student_id: string, scenario_id: string): Promise<ScenarioProgressType> => {
    // var req = await fetch(getBackendHost() + "/" + student_id + "/scenario/" + scenario_id, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();
    const data = APIFetch(getBackendHost() + "/" + student_id + "/scenario/" + scenario_id, {
        method: "GET"
    });

    return data;
}