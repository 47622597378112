import "./Definition.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import blueBackground from "../../../assets/exercises/bluebackgroundexercise.svg";
import { Idirection, IscreenSize } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { ResearchProgressType } from "../../../_newapios/progress/student_scenario";
import {
  Arrows,
  ExerciseName,
  MainTextBox,
  Professor,
  TextBox,
} from "./_components";
import { useAppSelector } from "../../../hooks/hooks";

const Definition = (props: {
  exercise: ExerciseType;
  researchProgress: ResearchProgressType | null;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  research: boolean;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  backHandler: () => void;
}) => {
  const { t } = useTranslation();

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const isTeacher = useAppSelector((state) => state.login).type === "teacher";

  const text = props.exercise.text;
  const definition = props.exercise.main_text;
  const researchProgress = props.researchProgress;
  const totalExercises = props.totalExercises;
  const selectedExercise = props.exerciseIndex;
  const maxExercise = props.maxExercise;
  const research = props.research;

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const exitButtonHandler = () => {
    if (researchProgress !== null && researchProgress?.completed_on !== null) {
      props.backHandler();
    } else {
      if (selectedExercise < maxExercise) {
        props.updateLevel("right", null, null);
      } else {
        props.updateLevel("right", [""], true);
      }
    }
  };

  const renderExitButton = () => {
    return (
      <motion.div
        className="definition_exit_button"
        onClick={() => exitButtonHandler()}
        whileHover={{
          scale: 1.02,
          boxShadow: "0px 0px 10px 1px #5090F0",
          borderRadius: "5 px",
        }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        <div className="center_container">
          <div className="text_white_medium_bold">{t("exit_inv")}</div>
        </div>
      </motion.div>
    );
  };

  return (
    <div className="definition">
      <img className="background" src={blueBackground} alt="bluebackground" />

      <Professor width={screenSize.dynamicWidth} />
      <TextBox text={text} contractTextBox={true} />
      <Arrows
        selectedExercise={selectedExercise}
        maxExercise={maxExercise}
        updateLevel={props.updateLevel}
        research={isTeacher ? true : research}
        allowNext={selectedExercise < totalExercises - 1}
      />
      <MainTextBox definition={true} mainText={definition} />
      <ExerciseName
        type={props.exercise.type}
        smallMargin={true}
        imageExists={true}
      />
      {selectedExercise === totalExercises - 1 &&
        !isTeacher &&
        renderExitButton()}
    </div>
  );
};

export default Definition;
