import { useTranslation } from "react-i18next";
import "./TeacherTutorial2.css";
import { Button } from "@chakra-ui/react";
import { LazyMotion, m } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks.ts";
import { setTeacherTutorialStep } from "../../../reducers/teacherSlice.ts";
import { FinishTutorialPartAPI } from "../../../_newapios/user/teacher.ts";
import { renderProgressBar } from "./TeacherTutorial1.tsx";


const TeacherTutorial2 = ({ ...props }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const loadFeatures = () =>
        import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);
    const teacherState = useAppSelector((state) => state.teacher);
    const tutorialStep = teacherState.teacherTutorialStep;
    const tutorialPart = teacherState.teacherTutorial;
    const licenseName = teacherState.userLicense?.license.name;
    const isAdmin = teacherState.isAdmin;
    console.log(tutorialPart);

    const handleNext = async () => {
        if (tutorialStep >= 0 && tutorialPart === "2_1") {
            await FinishTutorialPartAPI("2_1");
        }
        if (tutorialStep >= 3) {
            dispatch(setTeacherTutorialStep(0));
            await FinishTutorialPartAPI("2_2");
        }
        dispatch(setTeacherTutorialStep(tutorialStep + 1));

    };
    console.log(tutorialStep);

    const renderTutorialWelcome = () => {
        return (
            <div className="teacher_tutorial_1_welcome_background">
                <div className="center_container">
                    <LazyMotion features={loadFeatures}>
                        <div className="teacher_tutorial_1_welcome_container">
                            <div className="text_black_medium_bold">
                                {t("tutorial_2_1_title")}
                            </div>
                            <div className="text_black_small">
                                {t("tutorial_2_1_subtext")}
                            </div>
                            <Button
                                className="teacher_tutorial_1_welcome_button"
                                as={m.button}
                                whileHover={{
                                    scale: 1.01,
                                    boxShadow: "0px 0px 8px 0px #6ee6a7",
                                }}
                                _hover={{ backgroundColor: "#6ee6a7" }}
                                backgroundColor={"#6ee6a7"}
                                borderRadius={25}
                                size={"lg"}
                                onClick={() => handleNext()}
                            >
                                <div className="text_white_small">{t("lets_go!")}</div>
                            </Button>
                        </div>
                    </LazyMotion>
                </div>
            </div>
        );
    };

    const renderButton = () => {
        const text =
            tutorialStep === 3
                ? t("understood")
                : (tutorialStep === 2 ?
                    t("next") :
                    t("explore"))
        return (
            <LazyMotion features={loadFeatures}>
                <div className="teacher_tutorial_1_container_bottom">
                    <div className="text_black_very_small">{tutorialStep + "/3"}</div>
                    <Button
                        className="teacher_tutorial_1_welcome_button"
                        as={m.button}
                        whileHover={{
                            scale: 1.01,
                            boxShadow: "0px 0px 8px 0px #5090F0",
                        }}
                        _hover={{ backgroundColor: "#5090F0" }}
                        backgroundColor={"#5090F0"}
                        borderRadius={25}
                        size={"lg"}
                        onClick={() => handleNext()}
                    >
                        <div className="text_white_small">{text}</div>
                    </Button>
                </div>
            </LazyMotion>
        );
    };

    const renderTutorial2Text = () => {
        switch (tutorialStep) {
            case 1:
                return (
                    <div className="text_black_small">
                        <b>{t("tutorial_2_2_title")}</b>
                        <br /> {t("tutorial_2_2_point_1")}
                        <br /> {t("tutorial_2_2_point_2")}
                        <br /> {t("tutorial_2_2_point_3")}

                    </div>
                );
            case 2:
                return (
                    <div className="text_black_small">
                        <b>{t("tutorial_2_2_step_2")}</b>
                    </div>
                );
            case 3:
                return (
                    <div className="text_black_small">
                        <b>{t("tutorial_2_2_step_3")}</b>
                    </div>
                );
            default:
                return null;
        }
    };

    const renderTutorial2_2 = () => {
        return (
            <div className="teacher_tutorial_1_background">
                {(tutorialStep === 2 || tutorialStep === 3) && (
                    <>
                        <div
                            className={tutorialStep === 2 ? "teacher_tutorial_2_2_background_black_bot" : "teacher_tutorial_2_3_background_black_bot"}
                        />
                        <div
                            className={tutorialStep === 2 ? "teacher_tutorial_2_2_background_black_top" : "teacher_tutorial_2_3_background_black_top"}
                        />
                        <div
                            className={tutorialStep === 2 ? "teacher_tutorial_2_2_background_black_variable_left" : "teacher_tutorial_2_3_background_black_variable_left"}
                            style={
                                tutorialStep === 2
                                    ? { width: "40%" }
                                    : { width: "91.5%" }
                            }
                        />
                        <div
                            className={tutorialStep === 2 ? "teacher_tutorial_2_2_background_black_variable_right" : "teacher_tutorial_2_3_background_black_variable_right"}
                            style={
                                tutorialStep === 2
                                    ? { width: "25%" }
                                    : { width: "5%" }
                            }
                        />
                    </>)}
                <div className="center_container">
                    <div className={tutorialStep === 3 ? "teacher_tutorial_2_3_container" : (tutorialStep === 2 ? "teacher_tutorial_2_2_container" : "teacher_tutorial_2_1_container")}>
                        <div className="teacher_tutorial_1_container_text">
                            {renderTutorial2Text()}
                            {renderButton()}
                        </div>
                        {renderProgressBar(tutorialStep, 3)}
                    </div>
                </div>
            </div>
        );
    };


    return (
        <>
            {tutorialStep === 0 &&
                teacherState.submenu === "marketplace" &&
                renderTutorialWelcome()}
            {(tutorialStep === 1 || tutorialStep === 2 || tutorialStep === 3) && teacherState.submenu === "scenario" &&
                renderTutorial2_2()}
        </>
    );
};

export default TeacherTutorial2;
