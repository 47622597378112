import "./TrueFalseEdit.css";
import help from "../../../../assets/exercises/help.svg";
import plus from "../../../../assets/exercises/plus_grey.svg";
import bin from "../../../../assets/professor/bin.svg";
import ImageSelector from "./ImageSelector/ImageSelector";
import { useState, useEffect } from "react";
import { Textarea } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import axios from "axios";
import { useAppSelector } from "../../../../hooks/hooks";
import { ExerciseType } from "../../../../_newapios/content/unit";
import _ from "lodash";

const pako = require("pako");

const TrueFalseEdit = (props: {
  exercise: ExerciseType;
  selectedExercise: number;
  updateTrueFalse: (newTrueFalse: ExerciseType) => void;
}) => {
  const { t, i18n } = useTranslation();
  const animations = useAppSelector((state) => state.main).animations;
  const propSolutions = props.exercise.solutions as number[];

  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const [text, setText] = useState(props.exercise.text as string);
  const [helpText, setHelpText] = useState(props.exercise.help_text as string);
  const image = props.exercise.image?.url;
  const [solution, setSolution] = useState(propSolutions[0] as number);
  const [saveToDB, setSaveToDB] = useState(false);

  const animationUrl = props.exercise.animation?.url;

  useEffect(() => {
    if (saveToDB) {
      var exercise = { ...props.exercise };
      exercise.text = text;
      exercise.help_text = helpText;
      exercise.solutions = [solution];
      // exercise.image = image;
      props.updateTrueFalse(exercise);
      setSaveToDB(false);
    }
  }, [saveToDB]);

  useEffect(() => {
    const newSolutions = props.exercise.solutions as number[];
    const newImage = props.exercise.image?.url;
    const newAnimation = props.exercise.animation?.url;
    setText(props.exercise.text as string);
    setHelpText(props.exercise.help_text as string);
    setSolution(newSolutions[0] as number);
  }, [props.selectedExercise]);

  const helpButtonHandler = () => {
    setHelpOpen(!helpOpen);
  };

  const textHandler = (newText: string) => {
    setText(newText);
  };

  const helpTextHandler = (newHelpText: string) => {
    setHelpText(newHelpText);
  };

  const solutionHandler = (newSolution: number) => {
    setSolution(newSolution);
    setSaveToDB(true);
  };

  const imageHandler = (newExercise: ExerciseType) => {
    props.updateTrueFalse(newExercise);
  };

  const renderProfessor = () => {
    return (
      <div className="edit_true_false_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            style={{ maxHeight: "35vh" }}
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderTextBox = () => {
    return (
      <motion.div
        className="edit_true_false_text_box"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
      >
        <div
          className={
            text.length < 200 ? "text_black_medium" : "text_black_small"
          }
        >
          <Textarea
            placeholder={t("click_here_to_edit")}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              textAlign: "justify",
              fontFamily: "Causten",
              fontSize: "1em",
              textJustify: "inter-word",
            }}
            value={text}
            resize={"none"}
            onChange={(e) => textHandler(e.target.value)}
            onBlur={() => setSaveToDB(true)}
          />
        </div>
      </motion.div>
    );
  };

  const renderHelpButton = () => {
    return (
      <div className="edit_true_false_help_button">
        <div className="center_container">
          <motion.div
            className="write_help_text_button"
            onClick={() => helpButtonHandler()}
            whileHover={{
              scale: 1.02,
              boxShadow: "0px 0px 10px 1px #5090F0",
              borderRadius: "5 px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            style={{
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          >
            <div className="center_container" style={{ fontFamily: "Causten" }}>
              {t("write_your_clue")}
            </div>
          </motion.div>
        </div>
        <div
          className="center_container"
          onClick={() => helpButtonHandler()}
          style={{ cursor: "pointer" }}
        >
          <img
            className="icon"
            style={{ maxHeight: "7vh" }}
            src={help}
            alt="help"
          />
        </div>
      </div>
    );
  };

  const renderImage = () => {
    return (
      <div className="edit_true_false_image">
        <div className="center_container">
          <ImageSelector
            imageHandler={(exercise: ExerciseType) => imageHandler(exercise)}
            animation={animationUrl}
            image={image}
            exerciseId={props.exercise.id}
          />
        </div>
      </div>
    );
  };

  const renderTrueFalseButtons = () => {
    return (
      <div className="edit_true_false_buttons">
        <motion.div
          className={
            solution === 1
              ? "edit_true_false_left_button_pressed"
              : "edit_true_false_left_button"
          }
          onClick={() => solutionHandler(1)}
          whileHover={{ scale: 1.1, boxShadow: "0px 0px 10px 1px #6EE6A7" }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <div className="center_container">
            <div className="text_white_medium_bold">{t("true")}</div>
          </div>
        </motion.div>
        <motion.div
          className={
            solution === 1
              ? "edit_true_false_right_button"
              : "edit_true_false_right_button_pressed"
          }
          onClick={() => solutionHandler(0)}
          whileHover={{ scale: 1.1, boxShadow: "0px 0px 10px 1px #F0684F" }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <div className="center_container">
            <div className="text_white_medium_bold">{t("false")}</div>
          </div>
        </motion.div>
      </div>
    );
  };

  const renderHelpMenu = () => {
    return (
      <motion.div
        className="edit_true_false_help_menu"
        whileHover={{ scale: 1.02, boxShadow: "0px 0px 10px 1px #5090F0" }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
      >
        <div
          className={
            helpText.length < 265 ? "text_black_medium" : "text_black_small"
          }
        >
          <Textarea
            placeholder={t("click_here_to_edit")}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              textAlign: "center",
              fontFamily: "Causten",
              fontSize: "1em",
            }}
            value={helpText}
            resize={"none"}
            onChange={(e) => helpTextHandler(e.target.value)}
            onBlur={() => setSaveToDB(true)}
          />
        </div>
      </motion.div>
    );
  };

  return (
    <div className="edit_true_false">
      {renderProfessor()}
      {renderTextBox()}
      {renderHelpButton()}
      {helpOpen && renderHelpMenu()}
      {renderImage()}
      {renderTrueFalseButtons()}
    </div>
  );
};

export default TrueFalseEdit;
