import "./ExerciseVisualizer.css";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../hooks/hooks";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import TheoryIcon from "../../../../../assets/exercises/theory_icon";
import MediaIcon from "../../../../../assets/exercises/media_icon";
import DefinitionIcon from "../../../../../assets/exercises/definition_icon";
import { CourseType } from "../../../../../_newapios/content/course";
import { ExerciseType } from "../../../../../_newapios/content/unit";
import ok from "../../../../../assets/professor/ok.svg";
import blueBackground from "../../../../../assets/exercises/bluebackgroundexercise.svg";
import Theory from "../../../../exercises/Templates/Theory";
import DragDropList from "../../../../exercises/Templates/DragDropList";
import DragDropTwoLists from "../../../../exercises/Templates/DragDropTwoLists";
import DragDropArrows from "../../../../exercises/Templates/DragDropArrows";
import TrueFalse from "../../../../exercises/Templates/TrueFalse";
import DragDropOptions from "../../../../exercises/Templates/DragDropOptions";
import Definition from "../../../../exercises/Templates/Definition";
import ChooseOne from "../../../../exercises/Templates/ChooseOne";
import ChooseMany from "../../../../exercises/Templates/ChooseMany";
import WriteAnswers from "../../../../exercises/Templates/WriteAnswers";
import FillInTheGaps from "../../../../exercises/Templates/FillInTheGaps";
import Media from "../../../../exercises/Templates/Media";
import useSound from "use-sound";
import { Idirection } from "../../../../../interfaces";
import { isExerciseComplete } from "../../../../../utils/content";

const ExerciseVisualizer = ({ ...props }) => {
  const { t } = useTranslation();

  const mainState = useAppSelector((state) => state.main);
  const [selectedExercise, setSelectedExercise] = useState(0);
  const [course, setCourse] = useState<CourseType>(mainState.courses[0]);
  const [exercises, setExercises] = useState<ExerciseType[]>([]);
  const [maxExercise, setMaxExercise] = useState(0); //Kept only for using the same code in exercises

  const [rightAnswer] = useSound("./Correct+-+01.wav", {
    volume: mainState.volumeSounds,
  });
  const [wrongAnswer] = useSound("./wrong+-+01.wav", {
    volume: mainState.volumeSounds,
  });

  const research = mainState.researchExercises;
  const experiment = mainState.experimentExercises;

  useEffect(() => {
    if (research && experiment) {
      setExercises([...research.exercises, ...experiment.exercises]);
      setMaxExercise(
        research.exercises.length + experiment.exercises.length - 1
      );
    }
  }, [research, experiment]);

  useEffect(() => {
    const temp_course = mainState.courses.find(
      (course) => course.id === mainState.selectedCourseId
    );
    if (temp_course) setCourse(temp_course);
  }, [mainState.selectedCourseId]);

  const levelButtonHandler = (level: number) => {
    setSelectedExercise(level);
  };

  const updateLevel = (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => {
    if (direction === "left" && selectedExercise > 0) {
      setSelectedExercise(selectedExercise - 1);
      return;
    }

    if (direction === "right" && selectedExercise < exercises.length - 1) {
      setSelectedExercise(selectedExercise + 1);
      return;
    }
  };

  if (research === null || experiment === null) {
    return <div />;
  }

  const renderTheoryIcon = (exerciseIndex: number) => {
    return (
      <div
        className="professor_exercise_visualizer_navbar_list_element"
        style={{
          marginTop: exerciseIndex === 0 ? "0px" : "10px",
          backgroundColor:
            selectedExercise === exerciseIndex
              ? course.color + "80"
              : course.color + "40",
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          zIndex: "9",
        }}
        onClick={() => levelButtonHandler(exerciseIndex)}
      >
        <motion.div
          className="icon"
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <TheoryIcon
            color={selectedExercise === exerciseIndex ? "white" : "black"}
            size={25}
          />
        </motion.div>
      </div>
    );
  };

  const renderMediaIcon = (exerciseIndex: number) => {
    return (
      <div
        className="professor_exercise_visualizer_navbar_list_element"
        style={{
          marginTop: exerciseIndex === 0 ? "0px" : "10px",
          backgroundColor:
            selectedExercise === exerciseIndex
              ? course.color + "80"
              : course.color + "40",
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          zIndex: "9",
        }}
        onClick={() => levelButtonHandler(exerciseIndex)}
      >
        <motion.div
          className="icon"
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <MediaIcon
            color={selectedExercise === exerciseIndex ? "white" : "black"}
            size={25}
          />
        </motion.div>
      </div>
    );
  };

  const renderNumberButton = (level: number, exerciseIndex: number) => {
    return (
      <div
        key={level}
        className="professor_exercise_visualizer_navbar_list_element"
        onClick={() => levelButtonHandler(exerciseIndex)}
        style={{
          marginTop: exerciseIndex === 0 ? "0px" : "10px",
          backgroundColor:
            selectedExercise === exerciseIndex
              ? course.color + "80"
              : course.color + "40",
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          zIndex: "9",
        }}
      >
        <div className="center_container">
          <motion.div
            className={
              selectedExercise === exerciseIndex
                ? "text_white_medium_bold"
                : "text_black_medium_bold"
            }
            whileHover={{
              scale: 1.2,
              textShadow: "rgba(0,0,0, 0.50) 1px 0px 10px",
            }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            {level}
          </motion.div>
        </div>
      </div>
    );
  };

  const renderDefinitionIcon = (exerciseIndex: number) => {
    return (
      <div
        className="professor_exercise_visualizer_navbar_list_element"
        style={{
          marginTop: exerciseIndex === 0 ? "0px" : "10px",
          backgroundColor:
            selectedExercise === exerciseIndex
              ? course.color + "80"
              : course.color + "40",
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          zIndex: "9",
        }}
        onClick={() => levelButtonHandler(exerciseIndex)}
      >
        <motion.div
          className="icon"
          whileHover={{ scale: 1.1 }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <DefinitionIcon
            color={selectedExercise === exerciseIndex ? "white" : "black"}
            size={25}
          />
        </motion.div>
      </div>
    );
  };

  const renderResearchLevels = () => {
    var levelIndex = 1;
    return (
      <div className="professor_exercise_visualizer_navbar_list">
        {research.exercises.map((exercise, exerciseIndex) => {
          if (exercise.type === "theory") {
            return renderTheoryIcon(exerciseIndex);
          } else if (exercise.type === "definition") {
            return renderDefinitionIcon(exerciseIndex);
          } else if (exercise.type === "media") {
            return renderMediaIcon(exerciseIndex);
          } else {
            var copyLevelIndex = levelIndex;
            levelIndex++;
            return renderNumberButton(copyLevelIndex, exerciseIndex);
          }
        })}
      </div>
    );
  };

  const renderExperimentLevels = () => {
    // Need to add the length of the research exercises to the index
    return (
      <div className="professor_exercise_visualizer_navbar_list">
        {experiment.exercises.map((exercise, exerciseIndex) => {
          return renderExperimentLevelButton(
            exercise,
            exerciseIndex + research.exercises.length
          );
        })}
      </div>
    );
  };

  const renderExperimentLevelButton = (
    exercise: ExerciseType,
    exerciseIndex: number
  ) => {
    const isExerciseCompleteBool = isExerciseComplete(exercise) === "true"; //Make a function that checks if the exercise is complete
    return (
      <div
        key={exerciseIndex}
        className="professor_exercise_visualizer_navbar_list_element"
        onClick={() => levelButtonHandler(exerciseIndex)}
        style={{
          marginTop:
            exerciseIndex === research.exercises.length ? "0px" : "10px",
          backgroundColor:
            selectedExercise === exerciseIndex
              ? course.color + "80"
              : course.color + "40",
        }}
      >
        <div className="center_container">
          {isExerciseCompleteBool && (
            <motion.div
              className="professor_exercise_visualizer_navbar_list_outer_dot"
              style={{
                backgroundColor:
                  selectedExercise === exerciseIndex
                    ? course.color
                    : course.color + "80",
              }}
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <img src={ok} alt="ok" />
            </motion.div>
          )}

          {!isExerciseCompleteBool && (
            <motion.div
              className="professor_exercise_visualizer_navbar_list_outer_dot"
              style={{
                backgroundColor:
                  selectedExercise === exerciseIndex
                    ? course.color
                    : course.color + "80",
              }}
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <div className="professor_exercise_visualizer_navbar_list_inner_dot_background">
                <div
                  className="professor_exercise_visualizer_navbar_list_inner_dot"
                  style={{
                    backgroundColor:
                      selectedExercise === exerciseIndex
                        ? course.color + "80"
                        : course.color + "40",
                  }}
                />
              </div>
            </motion.div>
          )}
        </div>
      </div>
    );
  };

  const renderNavbar = () => {
    return (
      <div className="professor_exercise_visualizer_navbar">
        <div className="professor_exercise_visualizer_navbar_list_container">
          <div className="text_black_medium_bold">{t("research")}</div>
          {renderResearchLevels()}
        </div>
        <div className="professor_exercise_visualizer_navbar_list_container">
          <div className="text_black_medium_bold">{t("experiment")}</div>
          {renderExperimentLevels()}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="professor_exercise_visualizer_content">
        {(exercises.length === 0 || exercises[selectedExercise] == null) && (
          <img
            className="background"
            src={blueBackground}
            alt="background"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "relative",
              zIndex: -1,
            }}
          />
        )}

        {exercises.length > 0 &&
          exercises[selectedExercise].type.toLowerCase() === "theory" && (
            <Theory
              exercise={exercises[selectedExercise]}
              totalExercises={exercises.length}
              maxExercise={maxExercise}
              exerciseIndex={selectedExercise}
              research={selectedExercise < research.exercises.length}
              updateLevel={updateLevel}
            />
          )}

        {exercises.length > 0 &&
          exercises[selectedExercise].type.toLowerCase() === "dragdroplist" && (
            <DragDropList
              exercise={exercises[selectedExercise]}
              totalExercises={exercises.length}
              exerciseIndex={selectedExercise}
              maxExercise={maxExercise}
              rightAnswerSound={rightAnswer}
              wrongAnswerSound={wrongAnswer}
              updateLevel={updateLevel}
              research={selectedExercise < research.exercises.length}
              incorrectHandler={() => {}}
              tipHandler={() => {}}
            />
          )}

        {exercises.length > 0 &&
          exercises[selectedExercise].type.toLowerCase() ===
            "dragdroptwolists" && (
            <DragDropTwoLists
              exercise={exercises[selectedExercise]}
              totalExercises={exercises.length}
              exerciseIndex={selectedExercise}
              maxExercise={maxExercise}
              research={selectedExercise < research.exercises.length}
              rightAnswerSound={rightAnswer}
              wrongAnswerSound={wrongAnswer}
              updateLevel={updateLevel}
              incorrectHandler={() => {}}
              tipHandler={() => {}}
            />
          )}

        {exercises.length > 0 &&
          exercises[selectedExercise].type.toLowerCase() ===
            "dragdroparrows" && (
            <DragDropArrows
              exercise={exercises[selectedExercise]}
              totalExercises={exercises.length}
              exerciseIndex={selectedExercise}
              maxExercise={maxExercise}
              rightAnswerSound={rightAnswer}
              wrongAnswerSound={wrongAnswer}
              research={selectedExercise < research.exercises.length}
              updateLevel={updateLevel}
              incorrectHandler={() => {}}
              tipHandler={() => {}}
            />
          )}

        {exercises.length > 0 &&
          exercises[selectedExercise].type.toLowerCase() === "truefalse" && (
            <TrueFalse
              exercise={exercises[selectedExercise]}
              totalExercises={exercises.length}
              exerciseIndex={selectedExercise}
              maxExercise={maxExercise}
              research={selectedExercise < research.exercises.length}
              rightAnswerSound={rightAnswer}
              wrongAnswerSound={wrongAnswer}
              updateLevel={updateLevel}
              incorrectHandler={() => {}}
              tipHandler={() => {}}
            />
          )}

        {exercises.length > 0 &&
          exercises[selectedExercise].type.toLowerCase() ===
            "dragdropoptions" && (
            <DragDropOptions
              exercise={exercises[selectedExercise]}
              totalExercises={exercises.length}
              exerciseIndex={selectedExercise}
              maxExercise={maxExercise}
              research={selectedExercise < research.exercises.length}
              rightAnswerSound={rightAnswer}
              wrongAnswerSound={wrongAnswer}
              updateLevel={updateLevel}
              incorrectHandler={() => {}}
              tipHandler={() => {}}
            />
          )}

        {exercises.length > 0 &&
          exercises[selectedExercise].type.toLowerCase() === "definition" && (
            <Definition
              exercise={exercises[selectedExercise]}
              researchProgress={props.progress}
              totalExercises={exercises.length}
              maxExercise={maxExercise}
              exerciseIndex={selectedExercise}
              research={selectedExercise < research.exercises.length}
              updateLevel={updateLevel}
              backHandler={() => props.backHandler()}
            />
          )}

        {exercises.length > 0 &&
          exercises[selectedExercise].type.toLowerCase() === "chooseone" && (
            <ChooseOne
              exercise={exercises[selectedExercise]}
              totalExercises={exercises.length}
              exerciseIndex={selectedExercise}
              maxExercise={maxExercise}
              research={selectedExercise < research.exercises.length}
              rightAnswerSound={rightAnswer}
              wrongAnswerSound={wrongAnswer}
              updateLevel={updateLevel}
              incorrectHandler={() => {}}
              tipHandler={() => {}}
            />
          )}

        {exercises.length > 0 &&
          exercises[selectedExercise].type.toLowerCase() === "choosemany" && (
            <ChooseMany
              exercise={exercises[selectedExercise]}
              totalExercises={exercises.length}
              exerciseIndex={selectedExercise}
              maxExercise={maxExercise}
              research={selectedExercise < research.exercises.length}
              rightAnswerSound={rightAnswer}
              wrongAnswerSound={wrongAnswer}
              updateLevel={updateLevel}
              incorrectHandler={() => {}}
              tipHandler={() => {}}
            />
          )}

        {exercises.length > 0 &&
          exercises[selectedExercise].type.toLowerCase() === "writeanswers" && (
            <WriteAnswers
              exercise={exercises[selectedExercise]}
              totalExercises={exercises.length}
              exerciseIndex={selectedExercise}
              maxExercise={maxExercise}
              rightAnswerSound={rightAnswer}
              wrongAnswerSound={wrongAnswer}
              research={selectedExercise < research.exercises.length}
              updateLevel={updateLevel}
              incorrectHandler={() => {}}
              tipHandler={() => {}}
            />
          )}

        {exercises.length > 0 &&
          exercises[selectedExercise].type.toLowerCase() ===
            "fillinthegaps" && (
            <FillInTheGaps
              exercise={exercises[selectedExercise]}
              totalExercises={exercises.length}
              exerciseIndex={selectedExercise}
              maxExercise={maxExercise}
              rightAnswerSound={rightAnswer}
              wrongAnswerSound={wrongAnswer}
              research={selectedExercise < research.exercises.length}
              updateLevel={updateLevel}
              incorrectHandler={() => {}}
              tipHandler={() => {}}
            />
          )}
        {exercises.length > 0 &&
          exercises[selectedExercise].type.toLowerCase() === "media" && (
            <Media
              exercise={exercises[selectedExercise]}
              totalExercises={exercises.length}
              maxExercise={maxExercise}
              exerciseIndex={selectedExercise}
              research={selectedExercise < research.exercises.length}
              updateLevel={updateLevel}
            />
          )}
      </div>
    );
  };

  return (
    <div className="professor_exercise_visualizer">
      {renderNavbar()}
      <div />
      {renderContent()}
    </div>
  );
};

export default ExerciseVisualizer;
