import "./UserMenu.css";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks.ts";
import { useTranslation } from "react-i18next";
import LogButton from "../../../assets/professor/log_button.tsx";
import close from "../../../assets/professor/close_user_menu.svg";
import { LazyMotion, m } from "framer-motion";
import { LogoutAPI } from "../../../_newapios/auth/local.ts";
import settingsIcon from "../../../assets/settings.svg";
import Settings from "../../settings/Settings.tsx";
import { setSettingsOpen } from "../../../reducers/mainSlice.ts";
import { eraseCookie } from "../../../utils/general.ts";

const UserMenu = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [user, setUser] = useState(false);

  const teacherSlice = useAppSelector((state) => state.teacher);
  var mainState = useAppSelector((state) => state.main);
  var loginState = useAppSelector((state) => state.login);

  var accountType = loginState.type;

  const [openSettings, setOpenSettings] = useState(mainState.settingsOpen);

  useEffect(() => {
    dispatch(setSettingsOpen(openSettings));
  }, [openSettings]);

  const userHandler = () => {
    setUser(!user);
  };

  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const handleSettings = () => {
    console.log(openSettings);
    setOpenSettings(!openSettings);
  };

  const renderUserMenu = () => {
    return (
      <div className="user_menu_background">
        <LazyMotion features={loadFeatures}>
          <div className="professor_user_menu">
            <div className="user_menu_close" onClick={() => userHandler()}>
              <div className="right_container">
                <m.img
                  className="image"
                  src={close}
                  alt="close"
                  whileHover={{ scale: 1.2, rotate: 180 }}
                  transition={{
                    type: "spring",
                    stiffness: 400,
                    damping: 10,
                    duration: 1,
                  }}
                />
              </div>
            </div>
            <div className="center_container">
              <div className="user_icon" style={{ top: "10%" }}>
                <div className="center_container">
                  <div className="text_white_big_bold">
                    {teacherSlice.fullName.charAt(0).toUpperCase()}
                  </div>
                </div>
              </div>
            </div>
            <div className="user_fullname">
              <div className="center_container">
                <div className="text_black_medium_bold">
                  {teacherSlice.fullName}
                </div>
              </div>
            </div>
            <m.div
              className="user_menu_settings_button"
              onClick={() => {
                handleSettings();
                userHandler();
              }}
              whileHover={{ scale: 1.1, boxShadow: "0px 0px 10px 0px #D9D9D9" }}
              transition={{
                type: "spring",
                stiffness: 400,
                damping: 10,
                duration: 1,
              }}
            >
              <div className="center_container">
                <img
                  className="image"
                  src={settingsIcon}
                  alt="settingsButton"
                />
                <div
                  className="text_white_small_bold"
                  style={{ marginLeft: "3%" }}
                >
                  {t("settings").charAt(0).toUpperCase() +
                    t("settings").slice(1).toLowerCase()}
                </div>
              </div>
            </m.div>
            {loginState.impersonating && loginState.impersonating !== "" ? (
              <m.div
                className="logout_button"
                onClick={async () => {
                  eraseCookie("impersonating");
                  window.location.reload();
                }}
                whileHover={{
                  scale: 1.1,
                  boxShadow: "0px 0px 10px 0px #F06D50",
                }}
                transition={{
                  type: "spring",
                  stiffness: 400,
                  damping: 10,
                  duration: 1,
                }}
              >
                <div className="center_container">
                  <div className="text_white_small_bold">
                    {"Cerrar sesión de este profesor"}
                  </div>
                </div>
              </m.div>
            ) : (
              <m.div
                className="logout_button"
                onClick={async () => {
                  LogoutAPI().then(
                    (logout) => window.location.reload()
                    //getBackendHost() + "/logout?path=" + window.location.href
                  );
                }}
                whileHover={{
                  scale: 1.1,
                  boxShadow: "0px 0px 10px 0px #F06D50",
                }}
                transition={{
                  type: "spring",
                  stiffness: 400,
                  damping: 10,
                  duration: 1,
                }}
              >
                <div className="center_container">
                  <div className="text_white_small_bold">{t("log_out")}</div>
                </div>
              </m.div>
            )}
          </div>
        </LazyMotion>
      </div>
    );
  };
  console.log(accountType);

  return (
    <>
      {accountType !== "student" && openSettings && (
        <Settings handleSettings={() => handleSettings()} />
      )}
      <div>
        {user != false && renderUserMenu()}
        <LazyMotion features={loadFeatures}>
          <m.div
            whileHover={{ scale: 1.3 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            className="professor_user_button"
          >
            <div
              className="center_container"
              style={{
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
              onClick={userHandler}
            >
              {/* <img
              className="image"
              src={userButton}
              alt="UserIcon"
              style={{
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
              onClick={() => userHandler()}
            /> */}
              <LogButton color={props.color} size={36} />
            </div>
          </m.div>
        </LazyMotion>
      </div>
    </>
  );
};

export default UserMenu;
