import { ExerciseType } from "../_newapios/content/unit";

export const chooseLanguageFromDB = (
  language: string | null | undefined
): string => {
  const languages = [
    { value: "ES", label: "spanish" },
    { value: "es", label: "spanish" },
    { value: "EN", label: "english" },
    { value: "en", label: "english" },
    { value: "DE", label: "deutsch" },
    { value: "de", label: "deutsch" },
    { value: "eus", label: "basque" },
    { value: "EUS", label: "basque" },
    { value: "FR", label: "french" },
    { value: "fr", label: "french" },
    { value: "CAT", label: "catalonian" },
    { value: "cat", label: "catalonian" },
    { value: "VAL", label: "valencian" },
    { value: "val", label: "valencian" },
    { value: "GAL", label: "galician" },
    { value: "gal", label: "galician" },
  ];
  if (language === null || language === undefined) return "N/A";

  const lang = languages.find((l) => l.value === language);
  if (lang === undefined) return "N/A";
  return lang.label;
};

export const isExerciseComplete = (
  exercise: ExerciseType | undefined
): string => {
  if (exercise === undefined) {
    return "false";
  }

  const isEmpty = (str: string | undefined | null) => {
    if (str === undefined) return true;
    if (str === null) return true;
    if (str === "") return true;
    return false;
  };

  const isTrueFalseComplete = (exercise: ExerciseType): string => {
    if (exercise.solutions?.length !== 1) {
      return "solutions";
    }
    return "true";
  };

  const isChooseManyComplete = (exercise: ExerciseType): string => {
    if (exercise.solutions?.length === 0) {
      return "solutions";
    }
    if (exercise.options?.length === 0) {
      return "options";
    }
    if (exercise.solutions?.length !== exercise.options?.length) {
      return "solutions/options";
    }
    if (exercise.solutions?.some((solution) => solution === 1) === false) {
      return "solutions";
    }
    return "true";
  };

  const isChooseOneComplete = (exercise: ExerciseType): string => {
    if (exercise.solutions?.length !== 1) {
      return "solutions";
    }
    if (exercise.options?.length === 0) {
      return "options";
    }
    const solutionText = exercise.options?.[exercise.solutions[0]];
    if (solutionText === undefined) {
      return "solutions";
    }
    return "true";
  };

  const isDragDropOptionsComplete = (exercise: ExerciseType): string => {
    if (exercise.options?.length === 0) {
      return "options";
    }
    if (isEmpty(exercise.main_text)) {
      return "main_text";
    }

    return "true";
  };

  const isFillInTheGapsComplete = (exercise: ExerciseType): string => {
    if (isEmpty(exercise.main_text)) {
      return "main_text";
    }
    if (exercise.options?.length === 0) {
      return "options";
    }
    return "true";
  };

  const isWriteAnswersComplete = (exercise: ExerciseType): string => {
    if (exercise.options?.length === 0) {
      return "options";
    }
    if (exercise.labels?.length === 0) {
      return "labels";
    }
    if (exercise.max_error?.length === 0) {
      return "max_error";
    }
    if (
      exercise.options?.length !== exercise.labels?.length ||
      exercise.options?.length !== exercise.max_error?.length
    ) {
      return "options/labels/max_error";
    }
    return "true";
  };

  const isDragDropArrowsComplete = (exercise: ExerciseType): string => {
    if (exercise.options?.length === 0) {
      return "options";
    }

    if (exercise.solutions?.length === 0) {
      return "solutions";
    }

    if (exercise.labels?.length !== 2) {
      return "labels";
    }

    if (exercise.options?.length !== exercise.solutions?.length) {
      return "options/solutions";
    }

    return "true";
  };

  const isDragDropListComplete = (exercise: ExerciseType): string => {
    if (exercise.options?.length === 0) {
      return "options";
    }

    if (exercise.labels?.length !== 2) {
      return "labels";
    }
    return "true";
  };

  const isDragDropTwoListsComplete = (exercise: ExerciseType): string => {
    if (exercise.options?.length === 0) {
      return "options";
    }

    if (exercise.solutions?.length === 0) {
      return "solutions";
    }

    if (exercise.labels?.length !== 2) {
      return "labels";
    }

    if (exercise.options?.length !== exercise.solutions?.length) {
      return "options/solutions";
    }

    return "true";
  };

  const isDefinitionComplete = (exercise: ExerciseType): string => {
    if (isEmpty(exercise.main_text)) {
      return "main_text";
    }
    return "true";
  };

  var complete = "true";

  if (isEmpty(exercise.text)) {
    return "text";
  }

  switch (exercise.type) {
    case "theory":
      break;
    case "truefalse":
      complete = isTrueFalseComplete(exercise);
      break;
    case "choosemany":
      complete = isChooseManyComplete(exercise);
      break;
    case "chooseone":
      complete = isChooseOneComplete(exercise);
      break;
    case "dragdropoptions":
      complete = isDragDropOptionsComplete(exercise);
      break;
    case "fillinthegaps":
      complete = isFillInTheGapsComplete(exercise);
      break;
    case "writeanswers":
      complete = isWriteAnswersComplete(exercise);
      break;
    case "dragdroparrows":
      complete = isDragDropArrowsComplete(exercise);
      break;
    case "dragdroplist":
      complete = isDragDropListComplete(exercise);
      break;
    case "dragdroptwolists":
      complete = isDragDropTwoListsComplete(exercise);
      break;
    case "definition":
      complete = isDefinitionComplete(exercise);
      break;
    case "media":
      break;
    default:
      complete = "false";
      break;
  }

  return complete;
};
