import "./TutorialLocalMap.css";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { useTranslation, withTranslation } from "react-i18next";
import closeButton from "../../../assets/student/tutorial/close.svg";
import confirmButton from "../../../assets/student/tutorial/ok.svg";
import flechaLeft from "../../../assets/student/tutorial/FlechaTutLeft.svg";
import flechaRight from "../../../assets/student/tutorial/FlechaTutRight.svg";
import { LazyMotion, m } from "framer-motion";
import { increaseTutorialStep } from "../../../reducers/studentSlice.ts";

const TutorialLocalMap = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const studentState = useAppSelector((state) => state.student);
  const step = studentState.tutorialStep;

  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const advanceStepHandler = () => {
    dispatch(increaseTutorialStep());
  };

  const teacherIsRendered = () => {
    if (step >= 3 && step <= 6 && props.part === "campus") {
      return true;
    }
    if (props.part === "repeat_campus") {
      return true;
    }
    if (props.part === "close_campus") {
      return true;
    }
    if (step >= 7 && step <= 8 && props.part === "energy_lab") {
      return true;
    }
    if (step >= 9 && step <= 10 && props.part === "workshop") {
      return true;
    }
    if (step >= 11 && step <= 12 && props.part === "end") {
      return true;
    }
    return false;
  };

  const renderProfessor = () => {
    return (
      <div className="tutorial_local_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            style={{ maxHeight: "50vh" }}
            src={
              window.innerWidth > 991
                ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
                : "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_small.svg"
            }
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderCampusBubble = () => {
    return (
      <div className="tutorial_local_bubble">
        <div className="center_container">
          <div className="text_black_big_bold">
            {step === 3 ? (
              <div>
                <p>{t("tutorial_text_5")}</p>
              </div>
            ) : null}
            {step === 4 ? (
              <div>
                <p>{t("tutorial_text_6")}</p>
              </div>
            ) : null}
            {step === 5 ? (
              <div>
                <p>{t("tutorial_text_7")}</p>
              </div>
            ) : null}
            {step === 6 ? (
              <div>
                <p>{t("tutorial_text_8")}</p>
              </div>
            ) : null}
          </div>
        </div>
        {step !== 6 && (
          <m.div
            className="tutorial_local_button"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            transition={{ type: "spring", stiffness: 300, damping: 10 }}
            onClick={() => advanceStepHandler()}
          >
            <div className="text_white_big_bold">{t("confirm")}</div>
          </m.div>
        )}
      </div>
    );
  };

  const renderRepeatCampusBubble = () => {
    return (
      <div className="tutorial_local_bubble">
        <div className="center_container">
          <div className="text_black_big_bold">
            <div>
              <p>{t("tutorial_text_15")}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCloseCampusBubble = () => {
    return (
      <div
        className="tutorial_local_bubble"
        style={{ boxShadow: "0px 0px 15px 1px #5090F0" }}
      >
        <div className="center_container">
          <div className="text_black_big_bold">
            <div>
              <p>{t("tutorial_text_16")}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLabBubble = () => {
    return (
      <div className="tutorial_local_bubble upper_right_corner">
        <div className="center_container">
          <div className="text_black_big_bold">
            {step === 7 ? (
              <div>
                <p>{t("tutorial_text_9")}</p>
              </div>
            ) : null}
            {step === 8 ? (
              <div>
                <p>{t("tutorial_text_10")}</p>
              </div>
            ) : null}
          </div>
        </div>
        {step !== 7 && (
          <m.div
            className="tutorial_local_button"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            transition={{ type: "spring", stiffness: 300, damping: 10 }}
            onClick={() => advanceStepHandler()}
          >
            <div className="text_white_big_bold">{t("confirm")}</div>
          </m.div>
        )}
      </div>
    );
  };

  const renderWorkshopBubble = () => {
    return (
      <div className="tutorial_local_bubble">
        <div className="center_container">
          <div className="text_black_big_bold">
            {step === 9 ? (
              <div>
                <p>{t("tutorial_text_11")}</p>
              </div>
            ) : null}
            {step === 10 ? (
              <div>
                <p>{t("tutorial_text_12")}</p>
              </div>
            ) : null}
          </div>
        </div>
        {step !== 9 && step !== 10 && (
          <m.div
            className="tutorial_local_button"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            transition={{ type: "spring", stiffness: 300, damping: 10 }}
            onClick={() => advanceStepHandler()}
          >
            <div className="text_white_big_bold">{t("confirm")}</div>
          </m.div>
        )}
      </div>
    );
  };

  const renderEndBubble = () => {
    return (
      <div className="tutorial_local_bubble">
        <div className="center_container">
          <div className="text_black_big_bold">
            {step === 11 ? (
              <div>
                <p>{t("tutorial_text_13")}</p>
              </div>
            ) : null}
            {step === 12 ? (
              <div>
                <p>{t("tutorial_text_14")}</p>
              </div>
            ) : null}
          </div>
        </div>
        <m.div
          className="tutorial_local_button"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          transition={{ type: "spring", stiffness: 300, damping: 10 }}
          onClick={() => advanceStepHandler()}
        >
          <div className="text_white_big_bold">{t("confirm")}</div>
        </m.div>
      </div>
    );
  };

  console.log(props.part);

  return (
    <div>
      <LazyMotion features={loadFeatures}>
        {teacherIsRendered() && renderProfessor()}
        {step >= 3 &&
          step <= 6 &&
          props.part === "campus" &&
          renderCampusBubble()}
        {props.part === "repeat_campus" &&
          studentState.menu === "globalMap" &&
          renderRepeatCampusBubble()}
        {props.part === "close_campus" && renderCloseCampusBubble()}
        {step >= 7 &&
          step <= 8 &&
          props.part === "energy_lab" &&
          renderLabBubble()}
        {step >= 9 &&
          step <= 10 &&
          props.part === "workshop" &&
          renderWorkshopBubble()}
        {step >= 11 && step <= 12 && props.part === "end" && renderEndBubble()}
      </LazyMotion>
    </div>
  );
};

export default TutorialLocalMap;
