import { getBackendHost } from "../../utils/apiconnection";
import APIFetch from "../../utils/APIFetch";

export interface RegulationType {
    id: string,
    country: string
    name: string,
    age: number,
    color: string,
}

export const GetAllRegulationsAPI = async (): Promise<RegulationType[]> => {
    // var req = await fetch(getBackendHost() + "/regulation/all", {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     },
    //     "method": "GET",
    //     "mode": "cors",
    //     "credentials": "include"
    // });
    // var data = await req.json();

    var data = APIFetch(getBackendHost() + "/regulation/all", {
        method: "GET"
    });
    data = data || []
    return data;
}

