import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Input,
  Select,
  InputGroup,
  InputLeftElement,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import { PhoneIcon, ChevronDownIcon } from "@chakra-ui/icons";
import Flag from "react-world-flags";

const PhoneNumberInput = ({
  size = undefined,
  value = undefined,
  country,
  onChange,
  placeholder = undefined,
}: {
  size: string | undefined;
  value: string | undefined;
  country: string;
  onChange: (value: string) => void;
  placeholder: string | undefined;
}) => {
  const countries = require("country-data").countries;
  const getCountryTelCode = (ctry: string) => {
    let code: string = countries[ctry].countryCallingCodes[0];
    return code;
  };
  let [number, setNumber] = useState("");
  let [selectedCountry, setSelectedCountry] = useState(country);
  let [countryCode, setCountryCode] = useState("");
  const uniqueCountries = Object.keys(countries)
    .map((key) => countries[key])
    .filter(
      (ctry, index, self) =>
        index === self.findIndex((c) => c.name === ctry.name)
    );

  useEffect(() => {
    if (value) {
      const propsNumber = value;
      if (propsNumber.substring(0, 1) === "+") {
        let code = propsNumber.substring(0, 3);
        let number = propsNumber.substring(3);
        const codeCountry: any | undefined = uniqueCountries
          .slice(1)
          .find((ctry: any) => ctry.countryCallingCodes[0] === code);
        if (codeCountry === undefined) {
          code = propsNumber.substring(0, 4);
          number = propsNumber.substring(4);
        }
        console.log(codeCountry);
        console.log(code);
        console.log(number);
        setCountryCode(code);
        setSelectedCountry(codeCountry?.alpha2 || country);
        setNumber(number);
      }
    } else {
      setCountryCode(getCountryTelCode(country));
    }
  }, []);

  const options: {
    value: string;
    label: string;
  }[] = Object.values(uniqueCountries).map((ctry: any) => ({
    value: ctry.alpha2,
    label: ctry.name,
  }));

  const onCountryChange = (value: string) => {
    let code = getCountryTelCode(value);
    setCountryCode(code);
    setSelectedCountry(value);
    onChange(`${code}${number}`);
  };

  const onPhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    setNumber(value);
    console.log(`${countryCode}${value}`);
    onChange(`${countryCode}${value}`);
  };

  return (
    <InputGroup size={size} mb={2}>
      <InputLeftAddon width="4rem" padding={"3px"}>
        {/* <Select
          top="0"
          left="0"
          zIndex={1}
          bottom="0"
          width="100%"
          opacity={"0%"}
          position="relative"
          value={selectedCountry}
          onChange={(event) =>
            onCountryChange(event as any as ChangeEvent<HTMLInputElement>)
          }
        >
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </Select> */}
        {/* <Flex width="100%" height="100%" alignItems="center">
          {selectedCountry ? (
            <Box mr="4px" width="50%" flex={1}>
              <Flag height="1rem" code={selectedCountry || ""} />
            </Box>
          ) : (
            <PhoneIcon />
          )}
          <ChevronDownIcon />
        </Flex> */}
        <Menu placement="top-start">
          <MenuButton
            as={Button}
            style={{
              width: "100%",
              fontFamily: "Causten",
              padding: "5px",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
          >
            <Flex width="100%" height="100%" alignItems="center">
              {selectedCountry ? (
                <Box width="50%" flex={1}>
                  <Flag
                    style={{ borderRadius: "5px" }}
                    height="1rem"
                    code={selectedCountry || ""}
                  />
                </Box>
              ) : (
                <PhoneIcon />
              )}
            </Flex>
          </MenuButton>
          <MenuList
            style={{
              maxHeight: "40vh",
              minWidth: "15vw",
              overflowY: "scroll",
            }}
          >
            {options.map((option) => (
              <MenuItem
                onClick={() => onCountryChange(option.value)}
                style={{
                  fontFamily: "Causten",
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </InputLeftAddon>
      <Input
        type="tel"
        style={{ fontFamily: "Causten" }}
        value={number}
        onChange={onPhoneNumberChange}
        placeholder={placeholder}
      />
    </InputGroup>
  );
};

export default PhoneNumberInput;
